@charset "UTF-8";
/*----------------------------------------------------------------------
    Template Name: Akpager - Multipurpose Landing page HTML Template
    Template URI: https://webtend.net/demo/html/akpager/
    Author: WebTend
    Author URI:  https://webtend.net/
    Version: 1.0

    Note: This is Main Style CSS File. */
/*----------------------------------------------------------------------
	CSS INDEX
	----------------------

    ## Default Style
    ## Common Classes
    ## Repeat Style
    ## Padding Margin
    ## Custom Animation */
/* -------------------------------------------------------------- */
/*******************************************************/
/******************* ## Default Style ******************/
/*******************************************************/
* {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none; }

body {
  color: var(--base-color);
  font-weight: 400;
  line-height: 30px;
  font-size: 16px;
  font-family: var(--base-font);
  --base-font: 'Inter', sans-serif;
  --heading-font: 'Work Sans', sans-serif;
  --primary-color: #0055FF;
  --secondary-color: #FEC458;
  --heading-color: #001726;
  --base-color: #696969;
  --lighter-color: #F1F5FF;
  --navyblue-color: #00194C;
  --border-color: rgba(1, 13, 9, 0.1); }

a {
  color: var(--base-color);
  cursor: pointer;
  outline: none;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  text-decoration: none; }
  a:hover, a:focus, a:visited {
    text-decoration: none;
    outline: none; }
  a:hover {
    color: var(--heading-color); }

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  color: var(--heading-color); }

.text-white h1, .text-white h1 a,
.text-white h2, .text-white h2 a,
.text-white h3, .text-white h3 a,
.text-white h4, .text-white h4 a,
.text-white h5, .text-white h5 a,
.text-white h6, .text-white h6 a,
.text-white .h1, .text-white .h1 a,
.text-white .h2, .text-white .h2 a,
.text-white .h3, .text-white .h3 a,
.text-white .h4, .text-white .h4 a,
.text-white .h5, .text-white .h5 a,
.text-white .h6, .text-white .h6 a {
  color: white; }

ul, li {
  list-style: none;
  padding: 0;
  margin: 0; }

img {
  max-width: 100%;
  display: inline-block; }

mark {
  color: var(--primary-color);
  background: transparent;
  text-decoration: underline; }

header:after, section:after, footer:after {
  display: block;
  clear: both;
  content: ""; }

/*======= Input Styles =======*/
input,
select,
textarea,
.nice-select,
.form-control {
  width: 100%;
  height: auto;
  font-weight: 500;
  /* padding: 20px 30px; */
  /* Note: commenting this out because it is causing css issue in a lot of places */
  border-radius: 15px;
  font-family: var(--heading-font);
  background: var(--lighter-color);
  border: 1px solid var(--lighter-color); }
  input:focus,
  select:focus,
  textarea:focus,
  .nice-select:focus,
  .form-control:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    background: transparent;
    border-color: var(--border-color); }

textarea {
  display: inherit;
  padding-top: 20px; }

label {
  cursor: pointer;
  font-weight: 500;
  margin-bottom: 5px;
  color: var(--base-color); }

.form-group {
  position: relative;
  margin-bottom: 15px; }

input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0; }

input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0; }

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  display: none; }

input[type=checkbox], input[type=radio] {
  height: auto;
  width: auto; }

/*******************************************************/
/******************  ## Common Classes *****************/
/*******************************************************/
.page-wrapper {
  position: relative;
  z-index: 9;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  min-width: 300px; }

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1320px;
    padding-left: 15px;
    padding-right: 15px; }
    .container.container-1695 {
      max-width: 1725px; }
    .container.container-1660 {
      max-width: 1690px; }
    .container.container-1520 {
      max-width: 1550px; }
    .container.container-1500 {
      max-width: 1530px; }
    .container.container-1450 {
      max-width: 1480px; }
    .container.container-1370 {
      max-width: 1400px; }
    .container.container-1070 {
      max-width: 1200px; } }

@media only screen and (min-width: 576px) {
  .container-fluid {
    padding-left: 25px;
    padding-right: 25px; } }

@media only screen and (min-width: 1400px) {
  .container-fluid {
    padding-left: 70px;
    padding-right: 70px; } }

.row {
  --bs-gutter-x: 30px; }

.no-gap {
  --bs-gutter-x: 0; }

@media only screen and (min-width: 1400px) {
  .gap-5 {
    --bs-gutter-x: 5px; } }

@media only screen and (min-width: 1400px) {
  .gap-10 {
    --bs-gutter-x: 10px; } }

@media only screen and (min-width: 1400px) {
  .gap-15 {
    --bs-gutter-x: 15px; } }

@media only screen and (min-width: 1400px) {
  .gap-20 {
    --bs-gutter-x: 20px; } }

@media only screen and (min-width: 1400px) {
  .gap-25 {
    --bs-gutter-x: 25px; } }

@media only screen and (min-width: 1400px) {
  .gap-30 {
    --bs-gutter-x: 30px; } }

@media only screen and (min-width: 1400px) {
  .gap-35 {
    --bs-gutter-x: 35px; } }

@media only screen and (min-width: 1400px) {
  .gap-40 {
    --bs-gutter-x: 40px; } }

@media only screen and (min-width: 1400px) {
  .gap-45 {
    --bs-gutter-x: 45px; } }

@media only screen and (min-width: 1400px) {
  .gap-50 {
    --bs-gutter-x: 50px; } }

@media only screen and (min-width: 1400px) {
  .gap-55 {
    --bs-gutter-x: 55px; } }

@media only screen and (min-width: 1400px) {
  .gap-60 {
    --bs-gutter-x: 60px; } }

@media only screen and (min-width: 1400px) {
  .gap-65 {
    --bs-gutter-x: 65px; } }

@media only screen and (min-width: 1400px) {
  .gap-70 {
    --bs-gutter-x: 70px; } }

@media only screen and (min-width: 1400px) {
  .gap-75 {
    --bs-gutter-x: 75px; } }

@media only screen and (min-width: 1400px) {
  .gap-80 {
    --bs-gutter-x: 80px; } }

@media only screen and (min-width: 1400px) {
  .gap-85 {
    --bs-gutter-x: 85px; } }

@media only screen and (min-width: 1400px) {
  .gap-90 {
    --bs-gutter-x: 90px; } }

@media only screen and (min-width: 1400px) {
  .gap-95 {
    --bs-gutter-x: 95px; } }

@media only screen and (min-width: 1400px) {
  .gap-100 {
    --bs-gutter-x: 100px; } }

@media only screen and (min-width: 1400px) {
  .gap-105 {
    --bs-gutter-x: 105px; } }

@media only screen and (min-width: 1400px) {
  .gap-110 {
    --bs-gutter-x: 110px; } }

@media only screen and (min-width: 1400px) {
  .gap-115 {
    --bs-gutter-x: 115px; } }

@media only screen and (min-width: 1400px) {
  .gap-120 {
    --bs-gutter-x: 120px; } }

@media only screen and (min-width: 1400px) {
  .gap-125 {
    --bs-gutter-x: 125px; } }

@media only screen and (min-width: 1400px) {
  .gap-130 {
    --bs-gutter-x: 130px; } }

@media only screen and (min-width: 1400px) {
  .gap-135 {
    --bs-gutter-x: 135px; } }

@media only screen and (min-width: 1400px) {
  .gap-140 {
    --bs-gutter-x: 140px; } }

@media only screen and (min-width: 1400px) {
  .gap-145 {
    --bs-gutter-x: 145px; } }

@media only screen and (min-width: 1400px) {
  .gap-150 {
    --bs-gutter-x: 150px; } }

@media only screen and (min-width: 1200px) {
  .row-cols-xl-7 > * {
    width: 14.2857%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto; } }

@media only screen and (max-width: 375px) {
  .col-small {
    width: 100%; } }

.marquee-slider-left .slick-slide {
  direction: ltr; }

/*** Preloader style ** */
.preloader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #010101;
  background-repeat: no-repeat;
  background-position: center center; }
  .preloader .custom-loader {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    background-color: transparent;
    border: 2px solid var(--heading-color);
    border-top: 2px solid var(--primary-color);
    border-bottom: 2px solid var(--primary-color);
    -webkit-animation: 1s preloader linear infinite;
    animation: 1s preloader linear infinite; }

.half-size {
  transform: scale(0.3);
}
/* Pagination */
/* .pagination {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: -7px;
  margin-right: -7px; }
  .pagination li {
    margin: 14px 7px 0; }
    .pagination li a,
    .pagination li .page-link {
      padding: 0;
      width: 45px;
      height: 45px;
      font-size: 20px;
      -webkit-box-shadow: none;
      box-shadow: none;
      font-weight: 500;
      line-height: 43px;
      border-radius: 50%;
      text-align: center;
      color: var(--base-color);
      background: transparent;
      border: 1px solid var(--border-color); }
      @media only screen and (max-width: 575px) {
        .pagination li a,
        .pagination li .page-link {
          width: 45px;
          height: 45px;
          line-height: 43px; } }
    .pagination li.disabled .page-link, .pagination li:last-child .page-link {
      border-radius: 50%;
      background: transparent;
      border: 1px solid var(--border-color); }
    .pagination li.active .page-link, .pagination li:hover:not(.disabled) .page-link {
      color: white;
      background: var(--primary-color);
      border-color: var(--primary-color); } */

/* Social Style One */
.social-style-one {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex; }
  .social-style-one a {
    color: #7E91C3;
    margin: 10px 5px 0;
    width: 40px;
    height: 40px;
    background: #273557;
    line-height: 40px;
    border-radius: 50%;
    text-align: center; }
    .social-style-one a:hover {
      color: white;
      background: var(--primary-color); }

/* Rating */
.ratting {
  line-height: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex; }
  .ratting i {
    margin: 5px;
    font-size: 18px;
    color: var(--primary-color); }

.slider-arrow .slick-arrow {
  border: 1px solid var(--border-color); }
  .slider-arrow .slick-arrow:not(:last-child) {
    margin-right: 5px; }
  .slider-arrow .slick-arrow:focus, .slider-arrow .slick-arrow:hover {
    color: white;
    background: var(--primary-color);
    border-color: var(--primary-color); }

/*** Slick Dots ***/
.slick-dots {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }
  .slick-dots li {
    position: relative;
    cursor: pointer;
    width: 5px;
    height: 5px;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    margin: 0 10px;
    border-radius: 50%;
    background: var(--heading-color); }
    .slick-dots li button {
      opacity: 0; }
    .slick-dots li:after {
      content: '';
      position: absolute;
      left: -5px;
      top: -5px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      border: 1px solid transparent;
      -webkit-transition: .5s;
      -o-transition: .5s;
      transition: .5s;
      -webkit-transform: scale(0);
      -ms-transform: scale(0);
      transform: scale(0); }
    .slick-dots li.slick-active:after {
      border-color: var(--heading-color);
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1); }

.text-white .slick-dots li {
  opacity: 0.3;
  background: white; }
  .text-white .slick-dots li.slick-active {
    opacity: 1; }
    .text-white .slick-dots li.slick-active:after {
      border-color: white; }

/*** Scroll Top style ***/
.scroll-top {
  position: fixed;
  bottom: 25px;
  right: 0;
  width: 40px;
  height: 40px;
  z-index: 99;
  display: none;
  font-size: 16px;
  cursor: pointer;
  line-height: 44px;
  background: var(--primary-color);
  -webkit-animation: pulse 2s infinite;
  animation: pulse 2s infinite; }

/* BG Lines */
.bg-lines span {
  top: 0;
  z-index: -1;
  width: 1px;
  height: 100%;
  position: absolute;
  -webkit-animation: leftRightOne 18s infinite;
  animation: leftRightOne 18s infinite;
  background: rgba(16, 24, 40, 0.07); }
  .bg-lines span:nth-child(1) {
    left: 20%; }
  .bg-lines span:nth-child(2) {
    left: 40%; }
  .bg-lines span:nth-child(3) {
    left: 60%; }
  .bg-lines span:nth-child(4) {
    left: 80%; }
  .bg-lines span:nth-child(5) {
    left: 100%; }
  .bg-lines span:nth-child(6) {
    left: 120%; }
  .bg-lines span:nth-child(7) {
    left: 140%; }
  .bg-lines span:nth-child(8) {
    left: 160%; }
  .bg-lines span:nth-child(9) {
    left: 180%; }

.bg-lines.line-white span {
  background: rgba(255, 255, 255, 0.07); }

/* Position */
.rel {
  position: relative; }

.z-0 {
  z-index: 0; }

.z-1 {
  z-index: 1; }

.z-2 {
  z-index: 2; }

.z-3 {
  z-index: 3; }

.z-4 {
  z-index: 4; }

.z-5 {
  z-index: 5; }

.overlay {
  z-index: 1;
  position: relative; }
  .overlay::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    z-index: -1;
    top: 0;
    left: 0;
    opacity: 0.8;
    background-color: black; }

/* Backgruond Size */
.bgs-cover {
  background-size: cover;
  background-position: center; }

/* Backgruond Position */
.bgp-bottom {
  background-position: bottom;
  background-repeat: no-repeat; }

.bgp-center {
  background-position: center;
  background-repeat: no-repeat; }

/* Color + Background */
.bg-black {
  background-color: black; }

.bgc-black {
  background-color: var(--heading-color); }

.bgc-primary {
  background-color: var(--primary-color); }

.bgc-lighter {
  background-color: var(--lighter-color); }

.bgc-navyblue {
  background-color: var(--navyblue-color); }

.color-one {
  --primary-color: #396154; }

.color-two {
  --primary-color: #FEC458; }

.color-three {
  --primary-color: #8F00FF; }

.color-four {
  --primary-color: #FF008A; }

.color-five {
  --primary-color: #03B67C; }

.color-six {
  --primary-color: #FF4D12; }

.color-seven {
  --primary-color: #5652CC; }

.color-eight {
  --primary-color: #3157EF; }

.color-nine {
  --primary-color: #362E94; }

.color-ten {
  --primary-color: #2196F3; }

/* Border Radius */
.br-5 {
  border-radius: 5px; }

.br-10 {
  border-radius: 10px; }

.br-15 {
  border-radius: 15px; }

.br-20 {
  border-radius: 20px; }

.br-25 {
  border-radius: 25px; }

.br-30 {
  border-radius: 30px; }

/* For Home Two */
.home-two {
  --primary-color: #0095FF; }
  .home-two .section-title p {
    font-weight: 500; }
    @media only screen and (min-width: 576px) {
      .home-two .section-title p {
        font-size: 20px; } }

/* For Home Three */
.home-three {
  --primary-color: #F85C3A;
  --navyblue-color: #324438;
  --lighter-color: #F4F1E7; }
  .home-three .subtitle-one {
    -webkit-box-shadow: none;
    box-shadow: none;
    background: rgba(248, 92, 58, 0.16); }
  .home-three .text-white .subtitle-one {
    color: white;
    background: rgba(255, 255, 255, 0.15); }

/* For Home Four */
.home-four {
  --primary-color: #B966E7;
  --secondary-color: #3157EF; }
  .home-four .theme-btn.style-two {
    background: -webkit-linear-gradient(356deg, var(--primary-color) 13.66%, var(--secondary-color) 81.89%);
    background: -o-linear-gradient(356deg, var(--primary-color) 13.66%, var(--secondary-color) 81.89%);
    background: linear-gradient(94deg, var(--primary-color) 13.66%, var(--secondary-color) 81.89%); }
  .home-four .footer-bottom .scroll-top {
    color: white;
    background: var(--primary-color); }
  .home-four .newsletter-form.style-two button {
    background: -webkit-linear-gradient(356deg, #B065E7 13.66%, #3157EF 81.89%);
    background: -o-linear-gradient(356deg, #B065E7 13.66%, #3157EF 81.89%);
    background: linear-gradient(94deg, #B065E7 13.66%, #3157EF 81.89%); }

/* For Home Five */
.home-five {
  --primary-color: #08C16A;
  --lighter-color: #E7F9F1; }
  .home-five .section-title h4 span {
    color: white;
    padding: 0 5px;
    border-radius: 5px;
    background: var(--primary-color); }
  .home-five .subtitle-one.style-two {
    -webkit-box-shadow: none;
    box-shadow: none;
    background: rgba(8, 193, 106, 0.1); }
  .home-five .subtitle-one.style-three {
    color: white;
    background: var(--primary-color); }

/* For Home Six */
.home-six {
  --lighter-color: #FFFCEF;
  --primary-color: #464EE2;
  --navyblue-color: #151F39; }
  .home-six .subtitle-one {
    border: 1px solid var(--border-color); }
  .home-six .scroll-top {
    color: white;
    border: 1px solid;
    line-height: 38px; }

/* For Home Seven */
.home-seven {
  --lighter-color: #FFFCEF;
  --primary-color: #15CF92; }
  .home-seven .theme-btn:not(:hover) {
    color: var(--heading-color); }
  .home-seven .theme-btn.style-three {
    color: rgba(255, 255, 255, 0.65); }
    .home-seven .theme-btn.style-three:hover {
      color: var(--heading-color); }
  .home-seven .bgc-black {
    background: #010D09; }

/*******************************************************/
/******************* ## Repeat Style ******************/
/*******************************************************/
.heading,
h1,
.h1,
h2,
.h2,
.counter-item-two .count-text,
.pricing-item .price,
h3,
.h3,
h4,
.h4,
h5,
.h5,
.numbered-box .number,
.coming-soon-wrap li,
h6,
.h6,
.blockquote-two .blockquote-footer,
.read-more,
.icon-list li,
.course-nav li,
.advanced-tab li a,
.advanced-tab li button,
.accordion-item .accordion-button,
.blockquote-two .text,
.blockquote-three .text,
.main-menu .navbar-collapse li a,
.menu-btns .light-btn,
.contact-info-item.style-two .content .text,
.contact-form .form-group label,
.shop-shorter .sort-text {
  font-weight: 600;
  color: var(--heading-color);
  font-family: var(--heading-font); }

h1, .h1 {
  line-height: 1.1;
  font-size: 75px; }

h2, .h2, .counter-item-two .count-text, .pricing-item .price {
  line-height: 1.15;
  font-size: 48px; }

h3, .h3 {
  font-size: 30px; }

h4, .h4 {
  line-height: 1.4;
  font-size: 24px; }

h5, .h5, .numbered-box .number, .coming-soon-wrap li {
  line-height: 1.45;
  font-size: 22px; }

h6, .h6, .blockquote-two .blockquote-footer {
  line-height: 1.4;
  font-size: 16px; }

/*******************************************************/
/************** ## Padding Margin Spacing *************/
/*******************************************************/
/* Padding Around */
.p-5 {
  padding: 5px !important; }

.p-10 {
  padding: 10px; }

.p-15 {
  padding: 15px; }

.p-20 {
  padding: 20px; }

.p-25 {
  padding: 25px; }

.p-30 {
  padding: 30px; }

.p-35 {
  padding: 35px; }

.p-40 {
  padding: 40px; }

.p-45 {
  padding: 45px; }

.p-50 {
  padding: 50px; }

.p-55 {
  padding: 55px; }

.p-60 {
  padding: 60px; }

.p-65 {
  padding: 65px; }

.p-70 {
  padding: 70px; }

.p-75 {
  padding: 75px; }

.p-80 {
  padding: 80px; }

.p-85 {
  padding: 85px; }

.p-90 {
  padding: 90px; }

.p-95 {
  padding: 95px; }

.p-100 {
  padding: 100px; }

.p-105 {
  padding: 105px; }

.p-110 {
  padding: 110px; }

.p-115 {
  padding: 115px; }

.p-120 {
  padding: 120px; }

.p-125 {
  padding: 125px; }

.p-130 {
  padding: 130px; }

.p-135 {
  padding: 135px; }

.p-140 {
  padding: 140px; }

.p-145 {
  padding: 145px; }

.p-150 {
  padding: 150px; }

.p-155 {
  padding: 155px; }

.p-160 {
  padding: 160px; }

.p-165 {
  padding: 165px; }

.p-170 {
  padding: 170px; }

.p-175 {
  padding: 175px; }

.p-180 {
  padding: 180px; }

.p-185 {
  padding: 185px; }

.p-190 {
  padding: 190px; }

.p-195 {
  padding: 195px; }

.p-200 {
  padding: 200px; }

.p-205 {
  padding: 205px; }

.p-210 {
  padding: 210px; }

.p-215 {
  padding: 215px; }

.p-220 {
  padding: 220px; }

.p-225 {
  padding: 225px; }

.p-230 {
  padding: 230px; }

.p-235 {
  padding: 235px; }

.p-240 {
  padding: 240px; }

.p-245 {
  padding: 245px; }

.p-250 {
  padding: 250px; }

/* Padding Top */
.pt-5,
.py-5 {
  padding-top: 5px !important; }

.pt-10,
.py-10 {
  padding-top: 10px; }

.pt-15,
.py-15 {
  padding-top: 15px; }

.pt-20,
.py-20 {
  padding-top: 20px; }

.pt-25,
.py-25 {
  padding-top: 25px; }

.pt-30,
.py-30 {
  padding-top: 30px; }

.pt-35,
.py-35 {
  padding-top: 35px; }

.pt-40,
.py-40 {
  padding-top: 40px; }

.pt-45,
.py-45 {
  padding-top: 45px; }

.pt-50,
.py-50 {
  padding-top: 50px; }

.pt-55,
.py-55 {
  padding-top: 55px; }

.pt-60,
.py-60 {
  padding-top: 60px; }

.pt-65,
.py-65 {
  padding-top: 65px; }

.pt-70,
.py-70 {
  padding-top: 70px; }

.pt-75,
.py-75 {
  padding-top: 75px; }

.pt-80,
.py-80 {
  padding-top: 80px; }

.pt-85,
.py-85 {
  padding-top: 85px; }

.pt-90,
.py-90 {
  padding-top: 90px; }

.pt-95,
.py-95 {
  padding-top: 95px; }

.pt-100,
.py-100 {
  padding-top: 100px; }

.pt-105,
.py-105 {
  padding-top: 105px; }

.pt-110,
.py-110 {
  padding-top: 110px; }

.pt-115,
.py-115 {
  padding-top: 115px; }

.pt-120,
.py-120 {
  padding-top: 120px; }

.pt-125,
.py-125 {
  padding-top: 125px; }

.pt-130,
.py-130 {
  padding-top: 130px; }

.pt-135,
.py-135 {
  padding-top: 135px; }

.pt-140,
.py-140 {
  padding-top: 140px; }

.pt-145,
.py-145 {
  padding-top: 145px; }

.pt-150,
.py-150 {
  padding-top: 150px; }

.pt-155,
.py-155 {
  padding-top: 155px; }

.pt-160,
.py-160 {
  padding-top: 160px; }

.pt-165,
.py-165 {
  padding-top: 165px; }

.pt-170,
.py-170 {
  padding-top: 170px; }

.pt-175,
.py-175 {
  padding-top: 175px; }

.pt-180,
.py-180 {
  padding-top: 180px; }

.pt-185,
.py-185 {
  padding-top: 185px; }

.pt-190,
.py-190 {
  padding-top: 190px; }

.pt-195,
.py-195 {
  padding-top: 195px; }

.pt-200,
.py-200 {
  padding-top: 200px; }

.pt-205,
.py-205 {
  padding-top: 205px; }

.pt-210,
.py-210 {
  padding-top: 210px; }

.pt-215,
.py-215 {
  padding-top: 215px; }

.pt-220,
.py-220 {
  padding-top: 220px; }

.pt-225,
.py-225 {
  padding-top: 225px; }

.pt-230,
.py-230 {
  padding-top: 230px; }

.pt-235,
.py-235 {
  padding-top: 235px; }

.pt-240,
.py-240 {
  padding-top: 240px; }

.pt-245,
.py-245 {
  padding-top: 245px; }

.pt-250,
.py-250 {
  padding-top: 250px; }

/* Padding Bottom */
.pb-5,
.py-5 {
  padding-bottom: 5px !important; }

.pb-10,
.py-10 {
  padding-bottom: 10px; }

.pb-15,
.py-15 {
  padding-bottom: 15px; }

.pb-20,
.py-20 {
  padding-bottom: 20px; }

.pb-25,
.py-25 {
  padding-bottom: 25px; }

.pb-30,
.py-30 {
  padding-bottom: 30px; }

.pb-35,
.py-35 {
  padding-bottom: 35px; }

.pb-40,
.py-40 {
  padding-bottom: 40px; }

.pb-45,
.py-45 {
  padding-bottom: 45px; }

.pb-50,
.py-50 {
  padding-bottom: 50px; }

.pb-55,
.py-55 {
  padding-bottom: 55px; }

.pb-60,
.py-60 {
  padding-bottom: 60px; }

.pb-65,
.py-65 {
  padding-bottom: 65px; }

.pb-70,
.py-70 {
  padding-bottom: 70px; }

.pb-75,
.py-75 {
  padding-bottom: 75px; }

.pb-80,
.py-80 {
  padding-bottom: 80px; }

.pb-85,
.py-85 {
  padding-bottom: 85px; }

.pb-90,
.py-90 {
  padding-bottom: 90px; }

.pb-95,
.py-95 {
  padding-bottom: 95px; }

.pb-100,
.py-100 {
  padding-bottom: 100px; }

.pb-105,
.py-105 {
  padding-bottom: 105px; }

.pb-110,
.py-110 {
  padding-bottom: 110px; }

.pb-115,
.py-115 {
  padding-bottom: 115px; }

.pb-120,
.py-120 {
  padding-bottom: 120px; }

.pb-125,
.py-125 {
  padding-bottom: 125px; }

.pb-130,
.py-130 {
  padding-bottom: 130px; }

.pb-135,
.py-135 {
  padding-bottom: 135px; }

.pb-140,
.py-140 {
  padding-bottom: 140px; }

.pb-145,
.py-145 {
  padding-bottom: 145px; }

.pb-150,
.py-150 {
  padding-bottom: 150px; }

.pb-155,
.py-155 {
  padding-bottom: 155px; }

.pb-160,
.py-160 {
  padding-bottom: 160px; }

.pb-165,
.py-165 {
  padding-bottom: 165px; }

.pb-170,
.py-170 {
  padding-bottom: 170px; }

.pb-175,
.py-175 {
  padding-bottom: 175px; }

.pb-180,
.py-180 {
  padding-bottom: 180px; }

.pb-185,
.py-185 {
  padding-bottom: 185px; }

.pb-190,
.py-190 {
  padding-bottom: 190px; }

.pb-195,
.py-195 {
  padding-bottom: 195px; }

.pb-200,
.py-200 {
  padding-bottom: 200px; }

.pb-205,
.py-205 {
  padding-bottom: 205px; }

.pb-210,
.py-210 {
  padding-bottom: 210px; }

.pb-215,
.py-215 {
  padding-bottom: 215px; }

.pb-220,
.py-220 {
  padding-bottom: 220px; }

.pb-225,
.py-225 {
  padding-bottom: 225px; }

.pb-230,
.py-230 {
  padding-bottom: 230px; }

.pb-235,
.py-235 {
  padding-bottom: 235px; }

.pb-240,
.py-240 {
  padding-bottom: 240px; }

.pb-245,
.py-245 {
  padding-bottom: 245px; }

.pb-250,
.py-250 {
  padding-bottom: 250px; }

/* Margin Around */
.m-5 {
  margin: 5px !important; }

.m-10 {
  margin: 10px; }

.m-15 {
  margin: 15px; }

.m-20 {
  margin: 20px; }

.m-25 {
  margin: 25px; }

.m-30 {
  margin: 30px; }

.m-35 {
  margin: 35px; }

.m-40 {
  margin: 40px; }

.m-45 {
  margin: 45px; }

.m-50 {
  margin: 50px; }

.m-55 {
  margin: 55px; }

.m-60 {
  margin: 60px; }

.m-65 {
  margin: 65px; }

.m-70 {
  margin: 70px; }

.m-75 {
  margin: 75px; }

.m-80 {
  margin: 80px; }

.m-85 {
  margin: 85px; }

.m-90 {
  margin: 90px; }

.m-95 {
  margin: 95px; }

.m-100 {
  margin: 100px; }

.m-105 {
  margin: 105px; }

.m-110 {
  margin: 110px; }

.m-115 {
  margin: 115px; }

.m-120 {
  margin: 120px; }

.m-125 {
  margin: 125px; }

.m-130 {
  margin: 130px; }

.m-135 {
  margin: 135px; }

.m-140 {
  margin: 140px; }

.m-145 {
  margin: 145px; }

.m-150 {
  margin: 150px; }

.m-155 {
  margin: 155px; }

.m-160 {
  margin: 160px; }

.m-165 {
  margin: 165px; }

.m-170 {
  margin: 170px; }

.m-175 {
  margin: 175px; }

.m-180 {
  margin: 180px; }

.m-185 {
  margin: 185px; }

.m-190 {
  margin: 190px; }

.m-195 {
  margin: 195px; }

.m-200 {
  margin: 200px; }

.m-205 {
  margin: 205px; }

.m-210 {
  margin: 210px; }

.m-215 {
  margin: 215px; }

.m-220 {
  margin: 220px; }

.m-225 {
  margin: 225px; }

.m-230 {
  margin: 230px; }

.m-235 {
  margin: 235px; }

.m-240 {
  margin: 240px; }

.m-245 {
  margin: 245px; }

.m-250 {
  margin: 250px; }

/* Margin Top */
.mt-5,
.my-5 {
  margin-top: 5px !important; }

.mt-10,
.my-10 {
  margin-top: 10px; }

.mt-15,
.my-15 {
  margin-top: 15px; }

.mt-20,
.my-20 {
  margin-top: 20px; }

.mt-25,
.my-25 {
  margin-top: 25px; }

.mt-30,
.my-30 {
  margin-top: 30px; }

.mt-35,
.my-35 {
  margin-top: 35px; }

.mt-40,
.my-40 {
  margin-top: 40px; }

.mt-45,
.my-45 {
  margin-top: 45px; }

.mt-50,
.my-50 {
  margin-top: 50px; }

.mt-55,
.my-55 {
  margin-top: 55px; }

.mt-60,
.my-60 {
  margin-top: 60px; }

.mt-65,
.my-65 {
  margin-top: 65px; }

.mt-70,
.my-70 {
  margin-top: 70px; }

.mt-75,
.my-75 {
  margin-top: 75px; }

.mt-80,
.my-80 {
  margin-top: 80px; }

.mt-85,
.my-85 {
  margin-top: 85px; }

.mt-90,
.my-90 {
  margin-top: 90px; }

.mt-95,
.my-95 {
  margin-top: 95px; }

.mt-100,
.my-100 {
  margin-top: 100px; }

.mt-105,
.my-105 {
  margin-top: 105px; }

.mt-110,
.my-110 {
  margin-top: 110px; }

.mt-115,
.my-115 {
  margin-top: 115px; }

.mt-120,
.my-120 {
  margin-top: 120px; }

.mt-125,
.my-125 {
  margin-top: 125px; }

.mt-130,
.my-130 {
  margin-top: 130px; }

.mt-135,
.my-135 {
  margin-top: 135px; }

.mt-140,
.my-140 {
  margin-top: 140px; }

.mt-145,
.my-145 {
  margin-top: 145px; }

.mt-150,
.my-150 {
  margin-top: 150px; }

.mt-155,
.my-155 {
  margin-top: 155px; }

.mt-160,
.my-160 {
  margin-top: 160px; }

.mt-165,
.my-165 {
  margin-top: 165px; }

.mt-170,
.my-170 {
  margin-top: 170px; }

.mt-175,
.my-175 {
  margin-top: 175px; }

.mt-180,
.my-180 {
  margin-top: 180px; }

.mt-185,
.my-185 {
  margin-top: 185px; }

.mt-190,
.my-190 {
  margin-top: 190px; }

.mt-195,
.my-195 {
  margin-top: 195px; }

.mt-200,
.my-200 {
  margin-top: 200px; }

.mt-205,
.my-205 {
  margin-top: 205px; }

.mt-210,
.my-210 {
  margin-top: 210px; }

.mt-215,
.my-215 {
  margin-top: 215px; }

.mt-220,
.my-220 {
  margin-top: 220px; }

.mt-225,
.my-225 {
  margin-top: 225px; }

.mt-230,
.my-230 {
  margin-top: 230px; }

.mt-235,
.my-235 {
  margin-top: 235px; }

.mt-240,
.my-240 {
  margin-top: 240px; }

.mt-245,
.my-245 {
  margin-top: 245px; }

.mt-250,
.my-250 {
  margin-top: 250px; }

/* Margin Bottom */
.mb-5,
.my-5 {
  margin-bottom: 5px !important; }

.mb-10,
.my-10 {
  margin-bottom: 10px; }

.mb-15,
.my-15 {
  margin-bottom: 15px; }

.mb-20,
.my-20 {
  margin-bottom: 20px; }

.mb-25,
.my-25 {
  margin-bottom: 25px; }

.mb-30,
.my-30 {
  margin-bottom: 30px; }

.mb-35,
.my-35 {
  margin-bottom: 35px; }

.mb-40,
.my-40 {
  margin-bottom: 40px; }

.mb-45,
.my-45 {
  margin-bottom: 45px; }

.mb-50,
.my-50 {
  margin-bottom: 50px; }

.mb-55,
.my-55 {
  margin-bottom: 55px; }

.mb-60,
.my-60 {
  margin-bottom: 60px; }

.mb-65,
.my-65 {
  margin-bottom: 65px; }

.mb-70,
.my-70 {
  margin-bottom: 70px; }

.mb-75,
.my-75 {
  margin-bottom: 75px; }

.mb-80,
.my-80 {
  margin-bottom: 80px; }

.mb-85,
.my-85 {
  margin-bottom: 85px; }

.mb-90,
.my-90 {
  margin-bottom: 90px; }

.mb-95,
.my-95 {
  margin-bottom: 95px; }

.mb-100,
.my-100 {
  margin-bottom: 100px; }

.mb-105,
.my-105 {
  margin-bottom: 105px; }

.mb-110,
.my-110 {
  margin-bottom: 110px; }

.mb-115,
.my-115 {
  margin-bottom: 115px; }

.mb-120,
.my-120 {
  margin-bottom: 120px; }

.mb-125,
.my-125 {
  margin-bottom: 125px; }

.mb-130,
.my-130 {
  margin-bottom: 130px; }

.mb-135,
.my-135 {
  margin-bottom: 135px; }

.mb-140,
.my-140 {
  margin-bottom: 140px; }

.mb-145,
.my-145 {
  margin-bottom: 145px; }

.mb-150,
.my-150 {
  margin-bottom: 150px; }

.mb-155,
.my-155 {
  margin-bottom: 155px; }

.mb-160,
.my-160 {
  margin-bottom: 160px; }

.mb-165,
.my-165 {
  margin-bottom: 165px; }

.mb-170,
.my-170 {
  margin-bottom: 170px; }

.mb-175,
.my-175 {
  margin-bottom: 175px; }

.mb-180,
.my-180 {
  margin-bottom: 180px; }

.mb-185,
.my-185 {
  margin-bottom: 185px; }

.mb-190,
.my-190 {
  margin-bottom: 190px; }

.mb-195,
.my-195 {
  margin-bottom: 195px; }

.mb-200,
.my-200 {
  margin-bottom: 200px; }

.mb-205,
.my-205 {
  margin-bottom: 205px; }

.mb-210,
.my-210 {
  margin-bottom: 210px; }

.mb-215,
.my-215 {
  margin-bottom: 215px; }

.mb-220,
.my-220 {
  margin-bottom: 220px; }

.mb-225,
.my-225 {
  margin-bottom: 225px; }

.mb-230,
.my-230 {
  margin-bottom: 230px; }

.mb-235,
.my-235 {
  margin-bottom: 235px; }

.mb-240,
.my-240 {
  margin-bottom: 240px; }

.mb-245,
.my-245 {
  margin-bottom: 245px; }

.mb-250,
.my-250 {
  margin-bottom: 250px; }

/* Responsive Padding Margin */
@media only screen and (max-width: 991px) {
  /* Padding Around */
  .rp-0 {
    padding: 0px !important; }
  .rp-5 {
    padding: 5px !important; }
  .rp-10 {
    padding: 10px; }
  .rp-15 {
    padding: 15px; }
  .rp-20 {
    padding: 20px; }
  .rp-25 {
    padding: 25px; }
  .rp-30 {
    padding: 30px; }
  .rp-35 {
    padding: 35px; }
  .rp-40 {
    padding: 40px; }
  .rp-45 {
    padding: 45px; }
  .rp-50 {
    padding: 50px; }
  .rp-55 {
    padding: 55px; }
  .rp-60 {
    padding: 60px; }
  .rp-65 {
    padding: 65px; }
  .rp-70 {
    padding: 70px; }
  .rp-75 {
    padding: 75px; }
  .rp-80 {
    padding: 80px; }
  .rp-85 {
    padding: 85px; }
  .rp-90 {
    padding: 90px; }
  .rp-95 {
    padding: 95px; }
  .rp-100 {
    padding: 100px; }
  .rp-105 {
    padding: 105px; }
  .rp-110 {
    padding: 110px; }
  .rp-115 {
    padding: 115px; }
  .rp-120 {
    padding: 120px; }
  .rp-125 {
    padding: 125px; }
  .rp-130 {
    padding: 130px; }
  .rp-135 {
    padding: 135px; }
  .rp-140 {
    padding: 140px; }
  .rp-145 {
    padding: 145px; }
  .rp-150 {
    padding: 150px; }
  /* Padding Top */
  .rpt-0,
  .rpy-0 {
    padding-top: 0px !important; }
  .rpt-5,
  .rpy-5 {
    padding-top: 5px !important; }
  .rpt-10,
  .rpy-10 {
    padding-top: 10px; }
  .rpt-15,
  .rpy-15 {
    padding-top: 15px; }
  .rpt-20,
  .rpy-20 {
    padding-top: 20px; }
  .rpt-25,
  .rpy-25 {
    padding-top: 25px; }
  .rpt-30,
  .rpy-30 {
    padding-top: 30px; }
  .rpt-35,
  .rpy-35 {
    padding-top: 35px; }
  .rpt-40,
  .rpy-40 {
    padding-top: 40px; }
  .rpt-45,
  .rpy-45 {
    padding-top: 45px; }
  .rpt-50,
  .rpy-50 {
    padding-top: 50px; }
  .rpt-55,
  .rpy-55 {
    padding-top: 55px; }
  .rpt-60,
  .rpy-60 {
    padding-top: 60px; }
  .rpt-65,
  .rpy-65 {
    padding-top: 65px; }
  .rpt-70,
  .rpy-70 {
    padding-top: 70px; }
  .rpt-75,
  .rpy-75 {
    padding-top: 75px; }
  .rpt-80,
  .rpy-80 {
    padding-top: 80px; }
  .rpt-85,
  .rpy-85 {
    padding-top: 85px; }
  .rpt-90,
  .rpy-90 {
    padding-top: 90px; }
  .rpt-95,
  .rpy-95 {
    padding-top: 95px; }
  .rpt-100,
  .rpy-100 {
    padding-top: 100px; }
  .rpt-105,
  .rpy-105 {
    padding-top: 105px; }
  .rpt-110,
  .rpy-110 {
    padding-top: 110px; }
  .rpt-115,
  .rpy-115 {
    padding-top: 115px; }
  .rpt-120,
  .rpy-120 {
    padding-top: 120px; }
  .rpt-125,
  .rpy-125 {
    padding-top: 125px; }
  .rpt-130,
  .rpy-130 {
    padding-top: 130px; }
  .rpt-135,
  .rpy-135 {
    padding-top: 135px; }
  .rpt-140,
  .rpy-140 {
    padding-top: 140px; }
  .rpt-145,
  .rpy-145 {
    padding-top: 145px; }
  .rpt-150,
  .rpy-150 {
    padding-top: 150px; }
  /* Padding Bottom */
  .rpb-0,
  .rpy-0 {
    padding-bottom: 0px !important; }
  .rpb-5,
  .rpy-5 {
    padding-bottom: 5px !important; }
  .rpb-10,
  .rpy-10 {
    padding-bottom: 10px; }
  .rpb-15,
  .rpy-15 {
    padding-bottom: 15px; }
  .rpb-20,
  .rpy-20 {
    padding-bottom: 20px; }
  .rpb-25,
  .rpy-25 {
    padding-bottom: 25px; }
  .rpb-30,
  .rpy-30 {
    padding-bottom: 30px; }
  .rpb-35,
  .rpy-35 {
    padding-bottom: 35px; }
  .rpb-40,
  .rpy-40 {
    padding-bottom: 40px; }
  .rpb-45,
  .rpy-45 {
    padding-bottom: 45px; }
  .rpb-50,
  .rpy-50 {
    padding-bottom: 50px; }
  .rpb-55,
  .rpy-55 {
    padding-bottom: 55px; }
  .rpb-60,
  .rpy-60 {
    padding-bottom: 60px; }
  .rpb-65,
  .rpy-65 {
    padding-bottom: 65px; }
  .rpb-70,
  .rpy-70 {
    padding-bottom: 70px; }
  .rpb-75,
  .rpy-75 {
    padding-bottom: 75px; }
  .rpb-80,
  .rpy-80 {
    padding-bottom: 80px; }
  .rpb-85,
  .rpy-85 {
    padding-bottom: 85px; }
  .rpb-90,
  .rpy-90 {
    padding-bottom: 90px; }
  .rpb-95,
  .rpy-95 {
    padding-bottom: 95px; }
  .rpb-100,
  .rpy-100 {
    padding-bottom: 100px; }
  .rpb-105,
  .rpy-105 {
    padding-bottom: 105px; }
  .rpb-110,
  .rpy-110 {
    padding-bottom: 110px; }
  .rpb-115,
  .rpy-115 {
    padding-bottom: 115px; }
  .rpb-120,
  .rpy-120 {
    padding-bottom: 120px; }
  .rpb-125,
  .rpy-125 {
    padding-bottom: 125px; }
  .rpb-130,
  .rpy-130 {
    padding-bottom: 130px; }
  .rpb-135,
  .rpy-135 {
    padding-bottom: 135px; }
  .rpb-140,
  .rpy-140 {
    padding-bottom: 140px; }
  .rpb-145,
  .rpy-145 {
    padding-bottom: 145px; }
  .rpb-150,
  .rpy-150 {
    padding-bottom: 150px; }
  /* Margin Around */
  .rm-0 {
    margin: 0px !important; }
  .rm-5 {
    margin: 5px !important; }
  .rm-10 {
    margin: 10px; }
  .rm-15 {
    margin: 15px; }
  .rm-20 {
    margin: 20px; }
  .rm-25 {
    margin: 25px; }
  .rm-30 {
    margin: 30px; }
  .rm-35 {
    margin: 35px; }
  .rm-40 {
    margin: 40px; }
  .rm-45 {
    margin: 45px; }
  .rm-50 {
    margin: 50px; }
  .rm-55 {
    margin: 55px; }
  .rm-60 {
    margin: 60px; }
  .rm-65 {
    margin: 65px; }
  .rm-70 {
    margin: 70px; }
  .rm-75 {
    margin: 75px; }
  .rm-80 {
    margin: 80px; }
  .rm-85 {
    margin: 85px; }
  .rm-90 {
    margin: 90px; }
  .rm-95 {
    margin: 95px; }
  .rm-100 {
    margin: 100px; }
  .rm-105 {
    margin: 105px; }
  .rm-110 {
    margin: 110px; }
  .rm-115 {
    margin: 115px; }
  .rm-120 {
    margin: 120px; }
  .rm-125 {
    margin: 125px; }
  .rm-130 {
    margin: 130px; }
  .rm-135 {
    margin: 135px; }
  .rm-140 {
    margin: 140px; }
  .rm-145 {
    margin: 145px; }
  .rm-150 {
    margin: 150px; }
  /* Margin Top */
  .rmt-0,
  .rmy-0 {
    margin-top: 0px !important; }
  .rmt-5,
  .rmy-5 {
    margin-top: 5px !important; }
  .rmt-10,
  .rmy-10 {
    margin-top: 10px; }
  .rmt-15,
  .rmy-15 {
    margin-top: 15px; }
  .rmt-20,
  .rmy-20 {
    margin-top: 20px; }
  .rmt-25,
  .rmy-25 {
    margin-top: 25px; }
  .rmt-30,
  .rmy-30 {
    margin-top: 30px; }
  .rmt-35,
  .rmy-35 {
    margin-top: 35px; }
  .rmt-40,
  .rmy-40 {
    margin-top: 40px; }
  .rmt-45,
  .rmy-45 {
    margin-top: 45px; }
  .rmt-50,
  .rmy-50 {
    margin-top: 50px; }
  .rmt-55,
  .rmy-55 {
    margin-top: 55px; }
  .rmt-60,
  .rmy-60 {
    margin-top: 60px; }
  .rmt-65,
  .rmy-65 {
    margin-top: 65px; }
  .rmt-70,
  .rmy-70 {
    margin-top: 70px; }
  .rmt-75,
  .rmy-75 {
    margin-top: 75px; }
  .rmt-80,
  .rmy-80 {
    margin-top: 80px; }
  .rmt-85,
  .rmy-85 {
    margin-top: 85px; }
  .rmt-90,
  .rmy-90 {
    margin-top: 90px; }
  .rmt-95,
  .rmy-95 {
    margin-top: 95px; }
  .rmt-100,
  .rmy-100 {
    margin-top: 100px; }
  .rmt-105,
  .rmy-105 {
    margin-top: 105px; }
  .rmt-110,
  .rmy-110 {
    margin-top: 110px; }
  .rmt-115,
  .rmy-115 {
    margin-top: 115px; }
  .rmt-120,
  .rmy-120 {
    margin-top: 120px; }
  .rmt-125,
  .rmy-125 {
    margin-top: 125px; }
  .rmt-130,
  .rmy-130 {
    margin-top: 130px; }
  .rmt-135,
  .rmy-135 {
    margin-top: 135px; }
  .rmt-140,
  .rmy-140 {
    margin-top: 140px; }
  .rmt-145,
  .rmy-145 {
    margin-top: 145px; }
  .rmt-150,
  .rmy-150 {
    margin-top: 150px; }
  /* Margin Bottom */
  .rmb-0,
  .rmy-0 {
    margin-bottom: 0px !important; }
  .rmb-5,
  .rmy-5 {
    margin-bottom: 5px !important; }
  .rmb-10,
  .rmy-10 {
    margin-bottom: 10px; }
  .rmb-15,
  .rmy-15 {
    margin-bottom: 15px; }
  .rmb-20,
  .rmy-20 {
    margin-bottom: 20px; }
  .rmb-25,
  .rmy-25 {
    margin-bottom: 25px; }
  .rmb-30,
  .rmy-30 {
    margin-bottom: 30px; }
  .rmb-35,
  .rmy-35 {
    margin-bottom: 35px; }
  .rmb-40,
  .rmy-40 {
    margin-bottom: 40px; }
  .rmb-45,
  .rmy-45 {
    margin-bottom: 45px; }
  .rmb-50,
  .rmy-50 {
    margin-bottom: 50px; }
  .rmb-55,
  .rmy-55 {
    margin-bottom: 55px; }
  .rmb-60,
  .rmy-60 {
    margin-bottom: 60px; }
  .rmb-65,
  .rmy-65 {
    margin-bottom: 65px; }
  .rmb-70,
  .rmy-70 {
    margin-bottom: 70px; }
  .rmb-75,
  .rmy-75 {
    margin-bottom: 75px; }
  .rmb-80,
  .rmy-80 {
    margin-bottom: 80px; }
  .rmb-85,
  .rmy-85 {
    margin-bottom: 85px; }
  .rmb-90,
  .rmy-90 {
    margin-bottom: 90px; }
  .rmb-95,
  .rmy-95 {
    margin-bottom: 95px; }
  .rmb-100,
  .rmy-100 {
    margin-bottom: 100px; }
  .rmb-105,
  .rmy-105 {
    margin-bottom: 105px; }
  .rmb-110,
  .rmy-110 {
    margin-bottom: 110px; }
  .rmb-115,
  .rmy-115 {
    margin-bottom: 115px; }
  .rmb-120,
  .rmy-120 {
    margin-bottom: 120px; }
  .rmb-125,
  .rmy-125 {
    margin-bottom: 125px; }
  .rmb-130,
  .rmy-130 {
    margin-bottom: 130px; }
  .rmb-135,
  .rmy-135 {
    margin-bottom: 135px; }
  .rmb-140,
  .rmy-140 {
    margin-bottom: 140px; }
  .rmb-145,
  .rmy-145 {
    margin-bottom: 145px; }
  .rmb-150,
  .rmy-150 {
    margin-bottom: 150px; } }

/*******************************************************/
/***************** ## Custom Animation ****************/
/*******************************************************/
/* Animation Delay */
.delay-1-0s {
  -webkit-animation-delay: 1s;
  animation-delay: 1s; }

.delay-2-0s {
  -webkit-animation-delay: 2s;
  animation-delay: 2s; }

.delay-0-1s {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s; }

.delay-0-2s {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s; }

.delay-0-3s {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s; }

.delay-0-4s {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s; }

.delay-0-5s {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s; }

.delay-0-6s {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s; }

.delay-0-7s {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s; }

.delay-0-8s {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s; }

.delay-0-9s {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s; }

.delay-1-1s {
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s; }

.delay-1-2s {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s; }

.delay-1-3s {
  -webkit-animation-delay: 1.3s;
  animation-delay: 1.3s; }

.delay-1-4s {
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s; }

.delay-1-5s {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s; }

.delay-1-6s {
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s; }

.delay-1-7s {
  -webkit-animation-delay: 1.7s;
  animation-delay: 1.7s; }

.delay-1-8s {
  -webkit-animation-delay: 1.8s;
  animation-delay: 1.8s; }

.delay-1-9s {
  -webkit-animation-delay: 1.9s;
  animation-delay: 1.9s; }

@-webkit-keyframes toggler {
  0%,
  10% {
    background-position: 0 0, 0 calc(100%/3); }
  50% {
    background-position: 0 0, calc(100%/3) calc(100%/3); }
  90%,
  100% {
    background-position: 0 0, calc(100%/3) 0; } }

@keyframes toggler {
  0%,
  10% {
    background-position: 0 0, 0 calc(100%/3); }
  50% {
    background-position: 0 0, calc(100%/3) calc(100%/3); }
  90%,
  100% {
    background-position: 0 0, calc(100%/3) 0; } }

/* Menu Sticky */
@-webkit-keyframes sticky {
  0% {
    top: -100px; }
  100% {
    top: 0; } }

@keyframes sticky {
  0% {
    top: -100px; }
  100% {
    top: 0; } }

/* Rotated Circle */
@-webkit-keyframes rotated_circle {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg); } }
@keyframes rotated_circle {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg); } }

/* Rotated Circle reverse */
@-webkit-keyframes rotated_circle_reverse {
  0% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg); }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); } }
@keyframes rotated_circle_reverse {
  0% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg); }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); } }

/* Rotated Man */
@-webkit-keyframes semi_rotated {
  0%,
  100% {
    -webkit-transform: rotate(8deg);
    transform: rotate(8deg); }
  50% {
    -webkit-transform: rotate(-8deg);
    transform: rotate(-8deg); } }
@keyframes semi_rotated {
  0%,
  100% {
    -webkit-transform: rotate(8deg);
    transform: rotate(8deg); }
  50% {
    -webkit-transform: rotate(-8deg);
    transform: rotate(-8deg); } }

/* BG Shape Rotated */
@-webkit-keyframes semi_rotated_two {
  0%,
  100% {
    -webkit-transform: rotate(-30deg);
    transform: rotate(-30deg); }
  50% {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg); } }
@keyframes semi_rotated_two {
  0%,
  100% {
    -webkit-transform: rotate(-30deg);
    transform: rotate(-30deg); }
  50% {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg); } }

@-webkit-keyframes move_arround {
  0% {
    top: 20px;
    left: 20px; }
  25% {
    top: 20px;
    left: -20px; }
  50% {
    top: -20px;
    left: -20px; }
  75% {
    top: -20px;
    left: 20px; }
  100% {
    top: 20px;
    left: 20px; } }

@keyframes move_arround {
  0% {
    top: 20px;
    left: 20px; }
  25% {
    top: 20px;
    left: -20px; }
  50% {
    top: -20px;
    left: -20px; }
  75% {
    top: -20px;
    left: 20px; }
  100% {
    top: 20px;
    left: 20px; } }

/* Hero Circle */
@-webkit-keyframes upDownLeft {
  0%,
  100% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px); }
  25%,
  75% {
    -webkit-transform: translate(0px, 50px);
    transform: translate(0px, 50px); }
  50% {
    -webkit-transform: translate(-50px, 50px);
    transform: translate(-50px, 50px); } }
@keyframes upDownLeft {
  0%,
  100% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px); }
  25%,
  75% {
    -webkit-transform: translate(0px, 50px);
    transform: translate(0px, 50px); }
  50% {
    -webkit-transform: translate(-50px, 50px);
    transform: translate(-50px, 50px); } }

@-webkit-keyframes shapeAnimationOne {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg); }
  25% {
    -webkit-transform: translate(0px, 150px) rotate(90deg);
    transform: translate(0px, 150px) rotate(90deg); }
  50% {
    -webkit-transform: translate(150px, 150px) rotate(180deg);
    transform: translate(150px, 150px) rotate(180deg); }
  75% {
    -webkit-transform: translate(150px, 0px) rotate(270deg);
    transform: translate(150px, 0px) rotate(270deg); }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg); } }

@keyframes shapeAnimationOne {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg); }
  25% {
    -webkit-transform: translate(0px, 150px) rotate(90deg);
    transform: translate(0px, 150px) rotate(90deg); }
  50% {
    -webkit-transform: translate(150px, 150px) rotate(180deg);
    transform: translate(150px, 150px) rotate(180deg); }
  75% {
    -webkit-transform: translate(150px, 0px) rotate(270deg);
    transform: translate(150px, 0px) rotate(270deg); }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg); } }

@-webkit-keyframes shapeAnimationTwo {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg); }
  25% {
    -webkit-transform: translate(-150px, 0px) rotate(270deg);
    transform: translate(-150px, 0px) rotate(270deg); }
  50% {
    -webkit-transform: translate(-150px, 150px) rotate(180deg);
    transform: translate(-150px, 150px) rotate(180deg); }
  75% {
    -webkit-transform: translate(0px, 150px) rotate(90deg);
    transform: translate(0px, 150px) rotate(90deg); }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg); } }

@keyframes shapeAnimationTwo {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg); }
  25% {
    -webkit-transform: translate(-150px, 0px) rotate(270deg);
    transform: translate(-150px, 0px) rotate(270deg); }
  50% {
    -webkit-transform: translate(-150px, 150px) rotate(180deg);
    transform: translate(-150px, 150px) rotate(180deg); }
  75% {
    -webkit-transform: translate(0px, 150px) rotate(90deg);
    transform: translate(0px, 150px) rotate(90deg); }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg); } }

@-webkit-keyframes shapeAnimationThree {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg); }
  25% {
    -webkit-transform: translate(50px, 150px) rotate(90deg);
    transform: translate(50px, 150px) rotate(90deg); }
  50% {
    -webkit-transform: translate(150px, 150px) rotate(180deg);
    transform: translate(150px, 150px) rotate(180deg); }
  75% {
    -webkit-transform: translate(150px, 50px) rotate(270deg);
    transform: translate(150px, 50px) rotate(270deg); }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg); } }

@keyframes shapeAnimationThree {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg); }
  25% {
    -webkit-transform: translate(50px, 150px) rotate(90deg);
    transform: translate(50px, 150px) rotate(90deg); }
  50% {
    -webkit-transform: translate(150px, 150px) rotate(180deg);
    transform: translate(150px, 150px) rotate(180deg); }
  75% {
    -webkit-transform: translate(150px, 50px) rotate(270deg);
    transform: translate(150px, 50px) rotate(270deg); }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg); } }

@-webkit-keyframes shapeAnimationFour {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg); }
  25% {
    -webkit-transform: translate(-150px -50px) rotate(90deg);
    transform: translate(-150px -50px) rotate(90deg); }
  50% {
    -webkit-transform: translate(-150px, -150px) rotate(180deg);
    transform: translate(-150px, -150px) rotate(180deg); }
  75% {
    -webkit-transform: translate(-50px, -150px) rotate(270deg);
    transform: translate(-50px, -150px) rotate(270deg); }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg); } }

@keyframes shapeAnimationFour {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg); }
  25% {
    -webkit-transform: translate(-150px -50px) rotate(90deg);
    transform: translate(-150px -50px) rotate(90deg); }
  50% {
    -webkit-transform: translate(-150px, -150px) rotate(180deg);
    transform: translate(-150px, -150px) rotate(180deg); }
  75% {
    -webkit-transform: translate(-50px, -150px) rotate(270deg);
    transform: translate(-50px, -150px) rotate(270deg); }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg); } }

@-webkit-keyframes shapeAnimationFive {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg); }
  25% {
    -webkit-transform: translate(-100px -100px) rotate(90deg);
    transform: translate(-100px -100px) rotate(90deg); }
  50% {
    -webkit-transform: translate(100px, 50px) rotate(180deg);
    transform: translate(100px, 50px) rotate(180deg); }
  75% {
    -webkit-transform: translate(-100px, 150px) rotate(270deg);
    transform: translate(-100px, 150px) rotate(270deg); }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg); } }

@keyframes shapeAnimationFive {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg); }
  25% {
    -webkit-transform: translate(-100px -100px) rotate(90deg);
    transform: translate(-100px -100px) rotate(90deg); }
  50% {
    -webkit-transform: translate(100px, 50px) rotate(180deg);
    transform: translate(100px, 50px) rotate(180deg); }
  75% {
    -webkit-transform: translate(-100px, 150px) rotate(270deg);
    transform: translate(-100px, 150px) rotate(270deg); }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg); } }

@-webkit-keyframes down-up-one {
  0% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px); }
  50% {
    -webkit-transform: rotateX(0deg) translateY(25px);
    transform: rotateX(0deg) translateY(25px); }
  100% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px); } }

@keyframes down-up-one {
  0% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px); }
  50% {
    -webkit-transform: rotateX(0deg) translateY(25px);
    transform: rotateX(0deg) translateY(25px); }
  100% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px); } }

@-webkit-keyframes down-up-two {
  0% {
    -webkit-transform: rotateX(0deg) translate(0px);
    transform: rotateX(0deg) translate(0px); }
  50% {
    -webkit-transform: rotateX(0deg) translate(0, -25px);
    transform: rotateX(0deg) translate(0, -25px); }
  100% {
    -webkit-transform: rotateX(0deg) translate(0px);
    transform: rotateX(0deg) translate(0px); } }

@keyframes down-up-two {
  0% {
    -webkit-transform: rotateX(0deg) translate(0px);
    transform: rotateX(0deg) translate(0px); }
  50% {
    -webkit-transform: rotateX(0deg) translate(0, -25px);
    transform: rotateX(0deg) translate(0, -25px); }
  100% {
    -webkit-transform: rotateX(0deg) translate(0px);
    transform: rotateX(0deg) translate(0px); } }

@-webkit-keyframes leftRightOne {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  50% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px); } }

@keyframes leftRightOne {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  50% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px); } }

@-webkit-keyframes leftRightTwo {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  50% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px); } }

@keyframes leftRightTwo {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  50% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px); } }

@-webkit-keyframes zoomInOut {
  0%,
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  50% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5); } }

@keyframes zoomInOut {
  0%,
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  50% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5); } }

/* Preloader */
@-webkit-keyframes preloader {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }
@keyframes preloader {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

/* Headline */
@-webkit-keyframes marquee {
  100% {
    -webkit-transform: translate(-100%, 0);
    transform: translate(-100%, 0); } }
@keyframes marquee {
  100% {
    -webkit-transform: translate(-100%, 0);
    transform: translate(-100%, 0); } }

@-webkit-keyframes marquee_right {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes marquee_right {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@-webkit-keyframes marquee_left {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes marquee_left {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@-webkit-keyframes pulse {
  to {
    -webkit-box-shadow: 0 0 0 35px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 35px rgba(255, 255, 255, 0); } }

@keyframes pulse {
  to {
    -webkit-box-shadow: 0 0 0 35px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 35px rgba(255, 255, 255, 0); } }

/*******************************************************/
/********************  ## Buttons  *******************/
/*******************************************************/
.theme-btn,
a.theme-btn {
  color: white;
  cursor: pointer;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  padding: 13px 45px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 20px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-family: var(--heading-font);
  text-transform: capitalize;
  background-size: 202% 202%;
  background-position: 100% 100%;
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(50%, var(--primary-color)), color-stop(50%, var(--heading-color)));
  background-image: -webkit-linear-gradient(bottom, var(--primary-color) 50%, var(--heading-color) 50%);
  background-image: -o-linear-gradient(bottom, var(--primary-color) 50%, var(--heading-color) 50%);
  background-image: linear-gradient(to top, var(--primary-color) 50%, var(--heading-color) 50%); }
  .theme-btn i,
  a.theme-btn i {
    margin-left: 9px;
    font-size: 0.8em;
    -webkit-transform: rotate(-30deg);
    -ms-transform: rotate(-30deg);
    transform: rotate(-30deg); }
  .theme-btn:hover,
  a.theme-btn:hover {
    background-position: 0 0; }
  @media only screen and (max-width: 575px) {
    .theme-btn,
    a.theme-btn {
      padding: 10px 30px; } }
  .theme-btn.style-two,
  a.theme-btn.style-two {
    background-image: -webkit-gradient(linear, right top, left top, color-stop(50%, var(--navyblue-color)), color-stop(50%, var(--primary-color)));
    background-image: -webkit-linear-gradient(right, var(--navyblue-color) 50%, var(--primary-color) 50%);
    background-image: -o-linear-gradient(right, var(--navyblue-color) 50%, var(--primary-color) 50%);
    background-image: linear-gradient(to left, var(--navyblue-color) 50%, var(--primary-color) 50%); }
  .theme-btn.style-three,
  a.theme-btn.style-three {
    background-size: 205% 205%;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background-image: -webkit-gradient(linear, right top, left top, color-stop(50%, transparent), color-stop(50%, var(--primary-color)));
    background-image: -webkit-linear-gradient(right, transparent 50%, var(--primary-color) 50%);
    background-image: -o-linear-gradient(right, transparent 50%, var(--primary-color) 50%);
    background-image: linear-gradient(to left, transparent 50%, var(--primary-color) 50%); }
    .theme-btn.style-three:not(:hover),
    a.theme-btn.style-three:not(:hover) {
      background-position: 99% 99%; }
  .theme-btn.style-four,
  a.theme-btn.style-four {
    color: var(--base-color);
    border: 1px solid var(--border-color);
    background-image: -webkit-gradient(linear, right top, left top, color-stop(50%, white), color-stop(50%, var(--primary-color)));
    background-image: -webkit-linear-gradient(right, white 50%, var(--primary-color) 50%);
    background-image: -o-linear-gradient(right, white 50%, var(--primary-color) 50%);
    background-image: linear-gradient(to left, white 50%, var(--primary-color) 50%); }
    .theme-btn.style-four:hover,
    a.theme-btn.style-four:hover {
      color: white; }

/* Details Btn */
.details-btn {
  width: 65px;
  height: 65px;
  font-size: 20px;
  line-height: 65px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  border: 1px solid var(--border-color); }
  .details-btn:hover {
    color: white;
    background: var(--heading-color); }

/* Read More */
.read-more {
  font-size: 18px;
  font-weight: 600;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  text-transform: capitalize; }
  .read-more i {
    float: right;
    font-size: 15px;
    font-weight: 400;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    margin-left: 8px;
    margin-bottom: -2px;
    -webkit-transform: rotate(-30deg);
    -ms-transform: rotate(-30deg);
    transform: rotate(-30deg); }
  .read-more:hover {
    color: var(--primary-color); }

/* Read More Two */
.read-more-two {
  color: white;
  font-weight: 500;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  letter-spacing: 2.5px;
  text-transform: uppercase; }
  .read-more-two img {
    margin-left: 22px; }

/*******************************************************/
/******************  ## Section Title *****************/
/*******************************************************/
.subtitle-one {
  background: white;
  padding: 7px 25px;
  font-weight: 600;
  border-radius: 12px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: var(--primary-color);
  text-transform: capitalize;
  font-family: var(--heading-font);
  -webkit-box-shadow: 0px 5px 30px rgba(21, 207, 146, 0.1);
  box-shadow: 0px 5px 30px rgba(21, 207, 146, 0.1); }
  @media only screen and (min-width: 480px) {
    .subtitle-one {
      font-size: 18px; } }
  .subtitle-one i {
    margin-right: 12px; }
    @media only screen and (max-width: 375px) {
      .subtitle-one i {
        display: none; } }

.subtitle-two {
  font-weight: 700;
  display: inline-block;
  background: -webkit-gradient(linear, left top, right top, color-stop(38.73%, #AF65E8), to(#3157EF));
  background: -webkit-linear-gradient(left, #AF65E8 38.73%, #3157EF 100%);
  background: -o-linear-gradient(left, #AF65E8 38.73%, #3157EF 100%);
  background: linear-gradient(90deg, #AF65E8 38.73%, #3157EF 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text; }
  @media only screen and (min-width: 576px) {
    .subtitle-two {
      font-size: 22px; } }
  .subtitle-two i {
    margin-right: 5px; }

.subtitle-three {
  font-size: 18px;
  font-weight: 600;
  display: inline-block;
  color: var(--primary-color); }

.section-title {
  margin-top: -8px;
  position: relative; }
  .section-title h2 {
    margin-bottom: 20px;
    letter-spacing: -1.44px; }
    .section-title h2 span {
      color: var(--primary-color);
      background-image: url(../images/shapes/title-shape.png);
      background-position: center bottom;
      background-repeat: no-repeat;
      background-size: 100%;
      padding: 0 15px 15px;
      margin: 0 -15px; }
    @media only screen and (max-width: 767px) {
      .section-title h2 {
        font-size: 40px; } }
    @media only screen and (max-width: 575px) {
      .section-title h2 {
        font-size: 35px; } }
    @media only screen and (max-width: 375px) {
      .section-title h2 {
        font-size: 30px; } }
  @media only screen and (max-width: 575px) {
    .section-title h3 {
      font-size: 25px; } }
  .section-title h4 span {
    color: var(--primary-color); }
  .section-title.text-white h2 span {
    color: var(--secondary-color);
    background-image: url(../images/shapes/title-shape-for-white.png); }

/*******************************************************/
/******************* ## Iconic Box *******************/
/*******************************************************/
.iconic-box {
  z-index: 1;
  overflow: hidden;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  background: white;
  position: relative;
  margin-bottom: 30px;
  border-radius: 15px;
  padding: 40px 50px 35px; }
  @media only screen and (max-width: 375px) {
    .iconic-box {
      padding-left: 25px;
      padding-right: 25px; } }
  .iconic-box .icon {
    z-index: 1;
    line-height: 1;
    font-size: 65px;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    position: relative;
    margin-bottom: 45px;
    color: var(--primary-color); }
    .iconic-box .icon:after {
      content: '';
      width: 80px;
      height: 80px;
      z-index: -1;
      position: absolute;
      left: 5px;
      top: 10px;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      border-radius: 50%;
      -webkit-transition-delay: 0.2s;
      -o-transition-delay: 0.2s;
      transition-delay: 0.2s;
      background: var(--lighter-color); }
  .iconic-box .content h4 {
    margin-bottom: 15px; }
  .iconic-box .content p {
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    font-size: 18px;
    font-weight: 500; }
  .iconic-box .content hr {
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    margin-top: 30px;
    margin-bottom: 20px; }
  .iconic-box .content .read-more {
    margin-top: 8px; }
  .iconic-box .bg {
    z-index: -1;
    position: absolute;
    right: -20px;
    top: -20px;
    max-width: 60%;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s; }
  .iconic-box:hover {
    background: var(--primary-color); }
    .iconic-box:hover .icon,
    .iconic-box:hover .read-more,
    .iconic-box:hover .content p,
    .iconic-box:hover .content h4 a {
      color: white; }
    .iconic-box:hover .icon:after {
      background: rgba(255, 255, 255, 0.1); }
    .iconic-box:hover hr {
      opacity: 0.15;
      background: white; }
    .iconic-box:hover .bg {
      top: 0;
      right: 0; }
  .iconic-box.style-two {
    background: rgba(217, 217, 217, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1); }
    .iconic-box.style-two .icon {
      color: white; }
      .iconic-box.style-two .icon:after {
        background: rgba(255, 255, 255, 0.05); }
    .iconic-box.style-two h5 {
      margin-bottom: 15px; }
      .iconic-box.style-two h5 a {
        color: white; }
    .iconic-box.style-two p {
      font-size: 16px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.55); }
    .iconic-box.style-two .read-more {
      color: rgba(255, 255, 255, 0.55); }
    .iconic-box.style-two:hover .icon {
      color: var(--primary-color); }
    .iconic-box.style-two:hover h5 a {
      color: var(--primary-color);
      text-decoration: underline; }
    .iconic-box.style-two:hover p {
      color: rgba(255, 255, 255, 0.55); }
    .iconic-box.style-two:hover .read-more {
      color: white; }
  .iconic-box.style-three {
    padding: 30px 40px 18px;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1); }
    @media only screen and (max-width: 375px) {
      .iconic-box.style-three {
        padding-left: 33px;
        padding-right: 33px; } }
    .iconic-box.style-three .icon {
      margin-bottom: 20px; }
      .iconic-box.style-three .icon:after {
        display: none; }
    .iconic-box.style-three h5 {
      margin-bottom: 18px;
      letter-spacing: -0.66px; }
      .iconic-box.style-three h5 a {
        color: white; }
    .iconic-box.style-three p {
      font-size: 16px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.55); }
    .iconic-box.style-three .bg {
      top: 25px;
      right: 30px; }
    .iconic-box.style-three:hover p {
      color: rgba(255, 255, 255, 0.55); }
  .iconic-box.style-four {
    background: white;
    text-align: center;
    border-radius: 20px;
    padding: 38px 28px 15px;
    border: 3px solid white;
    -webkit-box-shadow: 0px 10px 60px rgba(155, 155, 155, 0.1);
    box-shadow: 0px 10px 60px rgba(155, 155, 155, 0.1); }
    .iconic-box.style-four .icon {
      width: 70px;
      height: 70px;
      font-size: 27px;
      line-height: 70px;
      text-align: center;
      margin: 0 auto 30px;
      color: var(--primary-color); }
      .iconic-box.style-four .icon:after {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.1;
        background: var(--primary-color); }
    .iconic-box.style-four h5 {
      font-size: 20px;
      margin-bottom: 12px; }
    .iconic-box.style-four p {
      font-size: 16px;
      font-weight: 400; }
    .iconic-box.style-four:hover {
      border-color: var(--primary-color); }
      .iconic-box.style-four:hover .icon {
        color: white; }
        .iconic-box.style-four:hover .icon:after {
          opacity: 1; }
      .iconic-box.style-four:hover p {
        color: var(--base-color); }
  .iconic-box.style-five {
    background: transparent;
    padding: 30px 50px 15px;
    border: 1px solid var(--border-color); }
    @media only screen and (max-width: 375px) {
      .iconic-box.style-five {
        padding-left: 40px;
        padding-right: 40px; } }
    .iconic-box.style-five .icon {
      font-size: 55px;
      margin-bottom: 10px;
      color: var(--primary-color); }
      .iconic-box.style-five .icon:after {
        display: none; }
    .iconic-box.style-five p {
      font-size: 16px;
      font-weight: 400; }
    .iconic-box.style-five:hover {
      background: white;
      border-color: white;
      -webkit-box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1); }
      .iconic-box.style-five:hover p {
        color: var(--base-color); }
  .iconic-box.style-six {
    background: #F9F9FF;
    padding: 40px 35px 20px;
    border: 1px solid var(--border-color); }
    @media only screen and (max-width: 375px) {
      .iconic-box.style-six {
        padding-left: 30px;
        padding-right: 30px; } }
    .iconic-box.style-six .icon {
      font-size: 55px;
      margin-bottom: 25px;
      color: var(--secondary-color); }
      .iconic-box.style-six .icon:after {
        display: none; }
    .iconic-box.style-six h5 {
      font-size: 20px;
      margin-bottom: 15px;
      letter-spacing: -0.4px; }
    .iconic-box.style-six p {
      font-size: 16px;
      font-weight: 400; }
    .iconic-box.style-six:hover {
      background: white;
      border-color: white;
      -webkit-box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1); }
      .iconic-box.style-six:hover h5 a {
        text-decoration: underline; }
      .iconic-box.style-six:hover p {
        color: var(--base-color); }
  .iconic-box.style-seven {
    padding: 35px 40px 15px;
    background: transparent; }
    @media only screen and (max-width: 375px) {
      .iconic-box.style-seven {
        padding-left: 30px;
        padding-right: 30px; } }
    .iconic-box.style-seven:after {
      position: absolute;
      left: 0;
      top: 0;
      content: '';
      width: 100%;
      height: 100%;
      z-index: -1;
      opacity: 0.05;
      background: var(--primary-color); }
    .iconic-box.style-seven .icon {
      width: 60px;
      height: 60px;
      color: white;
      font-size: 30px;
      line-height: 60px;
      text-align: center;
      border-radius: 12px;
      margin-bottom: 20px;
      background: var(--primary-color);
      -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
      box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2); }
      .iconic-box.style-seven .icon:after {
        display: none; }
    .iconic-box.style-seven .icon-title {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-bottom: 15px; }
      .iconic-box.style-seven .icon-title i {
        -webkit-box-flex: 0;
        -ms-flex: none;
        flex: none;
        font-size: 20px;
        margin-right: 15px;
        width: 50px;
        height: 50px;
        background: white;
        line-height: 50px;
        border-radius: 50%;
        text-align: center;
        color: var(--primary-color); }
      .iconic-box.style-seven .icon-title h5 {
        margin-bottom: 0; }
    .iconic-box.style-seven h5 {
      font-size: 20px;
      margin-bottom: 10px;
      letter-spacing: -0.4px; }
    .iconic-box.style-seven p {
      font-size: 16px;
      font-weight: 400; }
    .iconic-box.style-seven .bg {
      top: auto;
      right: -20px;
      bottom: -20px; }
    .iconic-box.style-seven:hover {
      background: var(--primary-color); }
      .iconic-box.style-seven:hover .icon {
        background: white;
        color: var(--primary-color); }
      .iconic-box.style-seven:hover h5 a {
        color: white; }
      .iconic-box.style-seven:hover .bg {
        right: 0;
        bottom: 0; }
    .iconic-box.style-seven.border {
      padding-top: 30px;
      padding-bottom: 10px; }
  .iconic-box.style-eight {
    background: #EFEEE8;
    padding: 35px 40px 15px; }
    @media only screen and (max-width: 375px) {
      .iconic-box.style-eight {
        padding-left: 30px;
        padding-right: 30px; } }
    .iconic-box.style-eight .icon {
      font-size: 50px;
      margin-bottom: 25px;
      color: var(--heading-color); }
      .iconic-box.style-eight .icon:after {
        display: none; }
    .iconic-box.style-eight hr {
      margin-bottom: 22px; }
    .iconic-box.style-eight h5 {
      font-size: 20px;
      margin-bottom: 10px;
      letter-spacing: -0.4px; }
    .iconic-box.style-eight p {
      font-size: 16px;
      font-weight: 400; }
    .iconic-box.style-eight .bg {
      top: 20px;
      right: 40px; }
    .iconic-box.style-eight:hover {
      background: var(--primary-color); }
      .iconic-box.style-eight:hover .icon {
        color: white; }
      .iconic-box.style-eight:hover h5 a {
        color: white; }
  .iconic-box.style-nine {
    padding: 0;
    border-radius: 0;
    background: transparent; }
    .iconic-box.style-nine .icon {
      width: 45px;
      height: 45px;
      font-size: 20px;
      line-height: 45px;
      text-align: center;
      margin-bottom: 22px;
      color: var(--primary-color); }
      .iconic-box.style-nine .icon:after {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.1;
        background: var(--primary-color); }
    .iconic-box.style-nine h5 {
      font-size: 20px;
      margin-bottom: 10px; }
    .iconic-box.style-nine p {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 0; }
    .iconic-box.style-nine:hover p {
      color: var(--base-color); }
    .iconic-box.style-nine.text-white .icon {
      width: auto;
      height: auto;
      color: white;
      font-size: 30px;
      text-align: left;
      margin-bottom: 16px; }
      .iconic-box.style-nine.text-white .icon:after {
        display: none; }
    .iconic-box.style-nine.text-white p {
      color: rgba(255, 255, 255, 0.65); }
  .iconic-box.style-ten {
    padding-top: 0;
    overflow: initial;
    text-align: center;
    margin-bottom: 75px;
    padding-bottom: 15px;
    border: 1px solid var(--border-color);
    -webkit-box-shadow: 0px 10px 60px rgba(141, 141, 141, 0.1);
    box-shadow: 0px 10px 60px rgba(141, 141, 141, 0.1); }
    .iconic-box.style-ten .icon {
      font-size: 45px;
      margin-top: -45px;
      margin-bottom: 20px;
      width: 92px;
      height: 92px;
      background: white;
      line-height: 92px;
      border-radius: 50%;
      text-align: center;
      line-height: 82px;
      display: inline-block;
      border: 5px solid var(--border-color); }
      .iconic-box.style-ten .icon:after {
        display: none; }
    .iconic-box.style-ten p {
      font-size: 16px;
      font-weight: 400; }
    .iconic-box.style-ten:hover {
      background: white;
      -webkit-box-shadow: none;
      box-shadow: none; }
      .iconic-box.style-ten:hover .icon {
        border-color: white;
        background: var(--primary-color); }
      .iconic-box.style-ten:hover h5 a {
        text-decoration: underline; }
      .iconic-box.style-ten:hover p {
        color: var(--base-color); }

.generative-ai-area:after {
  content: '';
  z-index: -1;
  width: 500px;
  height: 500px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0.7;
  border-radius: 50%;
  -webkit-filter: blur(500px);
  filter: blur(500px);
  background: #FEC458; }

/*******************************************************/
/******************** ## Fancy Box ********************/
/*******************************************************/
.fancy-box {
  position: relative;
  margin-bottom: 30px; }
  .fancy-box .image img {
    width: 100%;
    margin-bottom: 43px;
    border-radius: 15px;
    -webkit-box-shadow: 0px 30px 60px rgba(0, 85, 255, 0.15);
    box-shadow: 0px 30px 60px rgba(0, 85, 255, 0.15); }
  .fancy-box .icon-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .fancy-box .icon-title i {
      font-size: 50px;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      margin-right: 18px;
      color: var(--primary-color); }
      @media only screen and (max-width: 991px) {
        .fancy-box .icon-title i {
          font-size: 40px;
          margin-right: 10px; } }
    .fancy-box .icon-title h5 {
      margin-bottom: 0; }
      @media only screen and (max-width: 991px) {
        .fancy-box .icon-title h5 {
          font-size: 18px; } }
  .fancy-box .inner-content {
    display: none;
    padding-top: 12px; }
    @media only screen and (max-width: 375px) {
      .fancy-box .inner-content {
        display: none !important; } }
  .fancy-box .content {
    z-index: 1;
    position: absolute;
    right: -1px;
    bottom: -1px;
    overflow: hidden;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    background: white;
    width: calc(100% - 25px);
    padding: 23px 35px 23px 50px;
    -webkit-box-shadow: 0px 10px 60px rgba(0, 85, 255, 0.15);
    box-shadow: 0px 10px 60px rgba(0, 85, 255, 0.15);
    -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 100%, 20px 50%);
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 100%, 20px 50%); }
    @media only screen and (max-width: 991px) {
      .fancy-box .content {
        width: calc(100% - 15px);
        padding: 10px 25px 10px 40px; } }
    .fancy-box .content p {
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      margin-bottom: 10px; }
    .fancy-box .content .bg {
      z-index: -1;
      position: absolute;
      right: -20px;
      bottom: -20px;
      max-width: 50%;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s; }
  .fancy-box:hover .content {
    background: var(--primary-color);
    -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 100%, 0 50%);
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 100%, 0 50%); }
    @media only screen and (min-width: 376px) {
      .fancy-box:hover .content {
        padding-top: 30px;
        padding-bottom: 30px; } }
    .fancy-box:hover .content p,
    .fancy-box:hover .content h5 a,
    .fancy-box:hover .content .read-more,
    .fancy-box:hover .content .icon-title > i {
      color: white; }
    .fancy-box:hover .content .bg {
      right: 0;
      bottom: 0; }
  .fancy-box.style-two .image img {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 12px; }
  .fancy-box.style-two .content {
    right: 25px;
    -webkit-clip-path: none;
    clip-path: none;
    border-radius: 12px;
    width: calc(100% - 50px);
    padding: 20px 28px 15px 30px; }
    .fancy-box.style-two .content .category {
      font-weight: 500;
      margin-bottom: 4px;
      color: var(--primary-color);
      display: inline-block; }
    .fancy-box.style-two .content h6 {
      letter-spacing: -0.32px; }
    .fancy-box.style-two .content .inner-content {
      padding-top: 0; }
  .fancy-box.style-two:hover .content h6 a,
  .fancy-box.style-two:hover .content .category {
    color: white; }
  .fancy-box.style-two:hover .content h6 a {
    text-decoration: underline; }

/*******************************************************/
/******************** ## Icon List ********************/
/*******************************************************/
.list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 25px;
  color: var(--heading-color);
  font-family: var(--heading-font); }
  @media only screen and (min-width: 376px) {
    .list li {
      font-size: 18px; } }
  .list li:before {
    width: 5px;
    height: 5px;
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    content: '';
    margin-top: 9px;
    margin-right: 12px;
    border-radius: 50%;
    background: var(--primary-color); }

.icon-list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 600;
  line-height: 1.3; }
  .icon-list li:not(:last-child) {
    margin-bottom: 20px; }
  .icon-list li i {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    margin-top: 2px;
    font-weight: 400;
    margin-right: 15px;
    color: var(--primary-color); }

.icon-list.style-two li i {
  color: white;
  font-size: 10px;
  width: 20px;
  height: 20px;
  background: var(--primary-color);
  line-height: 20px;
  border-radius: 50%;
  text-align: center; }

.icon-list.style-three li {
  padding: 5px;
  font-weight: 400;
  line-height: 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #F1EFE1;
  border-radius: 10px;
  border: 1px solid var(--border-color); }
  .icon-list.style-three li:not(:last-child) {
    margin-bottom: 15px; }
  .icon-list.style-three li i {
    width: 30px;
    height: 30px;
    margin-top: 0;
    line-height: 30px;
    text-align: center;
    border-radius: 10px;
    color: var(--heading-color);
    background: var(--secondary-color); }

/*******************************************************/
/********************  ## Counter  *******************/
/*******************************************************/
.counter-item {
  margin-bottom: 40px; }
  .counter-item .count-text {
    display: block;
    line-height: 1;
    font-weight: 600;
    font-size: 48px;
    margin-bottom: 15px;
    color: var(--primary-color); }
  .counter-item.style-two {
    padding: 60px;
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.05); }
    @media only screen and (max-width: 375px) {
      .counter-item.style-two {
        padding: 40px; } }
    .counter-item.style-two .icon {
      line-height: 1;
      font-size: 65px;
      margin-bottom: 35px;
      color: var(--primary-color); }
    .counter-item.style-two .count-text {
      color: white; }
    .counter-item.style-two hr {
      opacity: 1;
      height: 2px;
      margin-top: 20px;
      margin-bottom: 20px;
      background: rgba(255, 255, 255, 0.1); }
    .counter-item.style-two .counter-title {
      font-size: 18px;
      color: rgba(255, 255, 255, 0.55); }
  .counter-item.style-three {
    z-index: 1;
    position: relative;
    padding: 40px 50px;
    border-radius: 12px; }
    .counter-item.style-three .bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background: white;
      border-radius: 12px; }
    .counter-item.style-three:after, .counter-item.style-three:before {
      position: absolute;
      left: 0;
      top: 0;
      content: '';
      z-index: -2;
      width: 100%;
      height: 100%;
      opacity: 0.2;
      -webkit-transition: 0.4s;
      -o-transition: 0.4s;
      transition: 0.4s;
      background: white;
      border-radius: 12px; }
    .counter-item.style-three .count-text {
      color: var(--heading-color); }
    .counter-item.style-three hr {
      height: 2px;
      margin-top: 25px;
      margin-bottom: 25px; }
    .counter-item.style-three:hover:before {
      top: 10px;
      left: 10px;
      -webkit-transition-delay: 0.25s;
      -o-transition-delay: 0.25s;
      transition-delay: 0.25s; }
    .counter-item.style-three:hover:after {
      top: 20px;
      left: 20px;
      z-index: -3; }
  .counter-item.style-four img {
    opacity: 0.1;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    margin-bottom: 35px; }
  .counter-item.style-four .count-text {
    margin-bottom: 25px; }
    @media only screen and (min-width: 576px) {
      .counter-item.style-four .count-text {
        font-size: 75px; } }
  .counter-item.style-four .counter-title {
    color: white;
    font-size: 18px;
    font-family: var(--heading-font); }
  .counter-item.style-four:hover img {
    opacity: 1; }
  .counter-item.style-five {
    text-align: center;
    margin-bottom: 30px; }
    .counter-item.style-five .count-text {
      font-size: 35px;
      margin-bottom: 10px;
      color: var(--heading-color); }
    .counter-item.style-five .counter-title {
      font-size: 18px;
      font-weight: 400;
      color: var(--base-color); }
  .counter-item.style-six {
    text-align: center;
    padding: 35px 40px;
    margin-bottom: 30px;
    border-radius: 12px;
    background: var(--lighter-color);
    border: 1px solid var(--border-color); }
    .counter-item.style-six .count-text {
      font-size: 35px;
      color: var(--heading-color); }

.count-text.plus:after {
  content: '+'; }

.count-text.k-plus:after {
  content: 'k+'; }

.count-text.m-plus:after {
  content: 'm+'; }

.count-text.m:after {
  content: 'm'; }

.count-text.percent:after {
  content: '%'; }

/* Counter Style Two */
.counter-item-two {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: white;
  padding: 25px 30px;
  border-radius: 15px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 15px;
  -webkit-box-shadow: 0px 10px 60px rgba(185, 102, 231, 0.2);
  box-shadow: 0px 10px 60px rgba(185, 102, 231, 0.2); }
  @media only screen and (max-width: 479px) {
    .counter-item-two {
      padding-left: 20px;
      padding-right: 20px; } }
  .counter-item-two .icon {
    z-index: 1;
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    font-size: 45px;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    position: relative;
    margin-right: 30px;
    color: var(--primary-color);
    width: 85px;
    height: 85px;
    background: var(--lighter-color);
    line-height: 85px;
    border-radius: 50%;
    text-align: center; }
    @media only screen and (max-width: 479px) {
      .counter-item-two .icon {
        width: 55px;
        height: 55px;
        font-size: 30px;
        line-height: 55px;
        margin-right: 15px; } }
    .counter-item-two .icon:after {
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      content: '';
      width: 100%;
      height: 100%;
      opacity: 0;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      border-radius: 50%;
      background: -webkit-linear-gradient(356deg, #B065E7 13.66%, #3157EF 81.89%);
      background: -o-linear-gradient(356deg, #B065E7 13.66%, #3157EF 81.89%);
      background: linear-gradient(94deg, #B065E7 13.66%, #3157EF 81.89%); }
  .counter-item-two .count-text {
    line-height: 1;
    display: block;
    margin-bottom: 8px; }
    @media only screen and (max-width: 479px) {
      .counter-item-two .count-text {
        font-size: 35px;
        margin-bottom: 3px; } }
  .counter-item-two .counter-title {
    display: block;
    font-weight: 500;
    line-height: 25px; }
  .counter-item-two:hover .icon {
    color: white; }
    .counter-item-two:hover .icon:after {
      opacity: 1; }

/* Counter Timeline */
.counter-timeline-wrap {
  position: relative; }
  .counter-timeline-wrap:after {
    content: '';
    width: 80%;
    height: 1px;
    position: absolute;
    left: 10%;
    top: 50%;
    background: rgba(255, 255, 255, 0.1); }
    @media only screen and (max-width: 991px) {
      .counter-timeline-wrap:after {
        display: none; } }

.counter-timeline-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  text-align: center;
  margin-bottom: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }
  @media only screen and (min-width: 992px) {
    .counter-timeline-item {
      min-height: 240px;
      height: calc(100% - 30px); } }
  .counter-timeline-item .icon {
    color: white;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 65px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    min-height: 80px; }
    @media only screen and (max-width: 991px) {
      .counter-timeline-item .icon {
        margin-bottom: 20px; } }
    @media only screen and (max-width: 575px) {
      .counter-timeline-item .icon {
        min-height: auto;
        font-size: 45px; } }
  .counter-timeline-item .dots {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
    @media only screen and (max-width: 991px) {
      .counter-timeline-item .dots {
        display: none; } }
  @media only screen and (max-width: 991px) {
    .counter-timeline-item .content {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2; } }
  .counter-timeline-item .count-text {
    color: white;
    display: block;
    font-size: 48px;
    font-weight: 600;
    line-height: 38px;
    margin-bottom: 12px; }
    @media only screen and (max-width: 575px) {
      .counter-timeline-item .count-text {
        font-size: 35px; } }
  .counter-timeline-item .counter-title {
    color: white;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 18px;
    font-weight: 500;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }

/*******************************************************/
/********************  ## Courses  *******************/
/*******************************************************/
.course-nav li {
  z-index: 1;
  cursor: pointer;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  padding: 11px 25px;
  background: white;
  margin-bottom: 10px;
  border-radius: 12px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(185, 102, 231, 0.2);
  box-shadow: 0px 10px 60px 0px rgba(185, 102, 231, 0.2); }
  .course-nav li i {
    margin-left: 10px;
    -webkit-transform: rotate(-40deg);
    -ms-transform: rotate(-40deg);
    transform: rotate(-40deg); }
  .course-nav li:before {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    border-radius: 12px;
    background: -webkit-linear-gradient(356deg, #B065E7 13.66%, #3157EF 81.89%);
    background: -o-linear-gradient(356deg, #B065E7 13.66%, #3157EF 81.89%);
    background: linear-gradient(94deg, #B065E7 13.66%, #3157EF 81.89%); }
  .course-nav li.active {
    color: white; }
    .course-nav li.active:before {
      opacity: 1; }

.course-item {
  padding: 5px;
  background: white;
  margin-bottom: 30px;
  border-radius: 20px;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(185, 102, 231, 0.2);
  box-shadow: 0px 10px 60px 0px rgba(185, 102, 231, 0.2); }
  .course-item .image {
    position: relative; }
    .course-item .image:before {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      opacity: 0;
      content: '';
      width: 100%;
      height: 100%;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      border-radius: 12px;
      background: -webkit-linear-gradient(356deg, rgba(176, 101, 231, 0.75) 13.66%, rgba(49, 87, 239, 0.75) 81.89%);
      background: -o-linear-gradient(356deg, rgba(176, 101, 231, 0.75) 13.66%, rgba(49, 87, 239, 0.75) 81.89%);
      background: linear-gradient(94deg, rgba(176, 101, 231, 0.75) 13.66%, rgba(49, 87, 239, 0.75) 81.89%); }
    .course-item .image img {
      width: 100%;
      border-radius: 12px; }
    .course-item .image .details-btn {
      z-index: 2;
      width: 50px;
      height: 50px;
      opacity: 0;
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      font-size: 18px;
      background: white;
      line-height: 50px;
      color: var(--primary-color); }
      .course-item .image .details-btn i {
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg); }
  .course-item .content {
    padding: 20px 30px 30px; }
    .course-item .content .category {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 5px;
      display: inline-block;
      color: var(--primary-color); }
    .course-item .content h6 {
      margin-bottom: 15px; }
      @media only screen and (min-width: 376px) {
        .course-item .content h6 {
          font-size: 18px; } }
      .course-item .content h6 a:hover {
        text-decoration: underline; }
    .course-item .content .author {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      font-size: 14px;
      font-weight: 600;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
      .course-item .content .author img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 10px; }
  .course-item .duration-ratting {
    padding: 5px 30px;
    border-radius: 18px;
    background: #F9F9FF;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border: 1px solid var(--border-color); }
    .course-item .duration-ratting .ratting i {
      color: #FEA858;
      font-size: 14px; }
  .course-item:hover .image:before {
    opacity: 1; }
  .course-item:hover .image .details-btn {
    opacity: 1; }

/*******************************************************/
/******************** ## Client Logo ********************/
/*******************************************************/
.client-logo-item {
  text-align: center;
  margin: 0 10px 35px; }
  .client-logo-item.style-two a {
    opacity: 0.3;
    -webkit-filter: grayscale(1);
    filter: grayscale(1); }
    .client-logo-item.style-two a:hover {
      opacity: 1;
      -webkit-filter: grayscale(0);
      filter: grayscale(0); }
  .client-logo-item.style-three {
    padding: 25px;
    background: white;
    margin-left: 0;
    margin-right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 20px;
    height: calc(100% - 35px);
    border: 1px solid var(--border-color);
    -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1); }
  .client-logo-item.style-four {
    margin-left: 0;
    margin-right: 0;
    border-radius: 20px;
    padding-left: 15px;
    padding-right: 15px;
    background: rgba(0, 0, 0, 0.01);
    border: 1px solid var(--border-color); }

.logo-white .client-logo-item img {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1); }

.logo-white .client-logo-item.style-three {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  background: rgba(255, 255, 255, 0.05);
  border: 1.2px solid rgba(255, 255, 255, 0.1);
  -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1); }
  .logo-white .client-logo-item.style-three:hover {
    background: var(--primary-color); }

.client-logo-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around; }
  .client-logo-wrap .client-logo-item {
    margin-left: 25px;
    margin-right: 25px;
    max-width: calc(50% - 50px); }

/*******************************************************/
/*******************  ## Testimonials ******************/
/*******************************************************/
.testimonial-item {
  padding: 40px 50px;
  border-radius: 15px;
  margin-bottom: 50px;
  font-family: var(--heading-font);
  background: var(--lighter-color);
  border: 1px solid var(--border-color); }
  @media only screen and (max-width: 479px) {
    .testimonial-item {
      padding: 25px; } }
  .testimonial-item .author {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .testimonial-item .author .image {
      -webkit-box-flex: 0;
      -ms-flex: none;
      flex: none;
      margin-right: 20px; }
      .testimonial-item .author .image img {
        width: 50px;
        height: 50px;
        border-radius: 50%; }
    .testimonial-item .author .icon {
      -webkit-box-flex: 0;
      -ms-flex: none;
      flex: none;
      color: white;
      font-size: 20px;
      margin-right: 20px;
      width: 50px;
      height: 50px;
      background: var(--heading-color);
      line-height: 50px;
      border-radius: 50%;
      text-align: center; }
    .testimonial-item .author .title {
      line-height: 1.3;
      letter-spacing: -0.5px; }
      .testimonial-item .author .title b {
        font-size: 24px;
        font-weight: 600;
        color: var(--heading-color); }
        @media only screen and (max-width: 575px) {
          .testimonial-item .author .title b {
            font-size: 20px; } }
        @media only screen and (max-width: 375px) {
          .testimonial-item .author .title b {
            font-size: 18px; } }
  .testimonial-item .author-text {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.6;
    margin-bottom: 20px;
    color: var(--heading-color);
    letter-spacing: -0.72px; }
    @media only screen and (max-width: 575px) {
      .testimonial-item .author-text {
        font-size: 20px; } }
    @media only screen and (max-width: 375px) {
      .testimonial-item .author-text {
        font-size: 18px; } }
  .testimonial-item .testi-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }
    .testimonial-item .testi-footer .ratting i {
      width: 26px;
      height: 26px;
      color: white;
      font-size: 13px;
      margin: 0 5px 0 0;
      line-height: 26px;
      text-align: center;
      background: var(--primary-color); }
    .testimonial-item .testi-footer .text span {
      color: var(--primary-color); }
  .testimonial-item.style-two {
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    background: white;
    border: 3px solid white; }
    .testimonial-item.style-two:hover {
      border-color: var(--primary-color); }
  .testimonial-item.style-three {
    padding: 0;
    border: none;
    border-radius: 0;
    background: transparent; }
    .testimonial-item.style-three .author-text {
      font-weight: 500; }
      @media only screen and (min-width: 768px) {
        .testimonial-item.style-three .author-text {
          font-size: 30px; } }

.testi-slider-five .slick-dots {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start; }
  .testi-slider-five .slick-dots li {
    width: 7px;
    height: 7px;
    margin: 0 15px 0 0;
    background: transparent;
    border: 1px solid var(--heading-color); }
    .testi-slider-five .slick-dots li:after {
      display: none; }
    .testi-slider-five .slick-dots li.slick-active {
      background: var(--heading-color); }

/* Testimonials Right to Left Slider */
.testi-slider-right .testimonial-item {
  max-width: 600px;
  margin-right: 50px; }
  @media only screen and (max-width: 991px) {
    .testi-slider-right .testimonial-item {
      max-width: 500px; } }
  @media only screen and (max-width: 575px) {
    .testi-slider-right .testimonial-item {
      max-width: 400px;
      margin-right: 20px; } }
  @media only screen and (max-width: 375px) {
    .testi-slider-right .testimonial-item {
      max-width: 300px; } }

/* Testimonials Left To Right Slider */
.testi-slider-left .testimonial-item {
  max-width: 600px;
  margin-left: 50px;
  direction: ltr; }
  @media only screen and (max-width: 991px) {
    .testi-slider-left .testimonial-item {
      max-width: 500px; } }
  @media only screen and (max-width: 575px) {
    .testi-slider-left .testimonial-item {
      max-width: 400px;
      margin-left: 20px; } }
  @media only screen and (max-width: 375px) {
    .testi-slider-left .testimonial-item {
      max-width: 300px; } }

/* Testimonials Three Slider */
.testi-slider-three {
  margin-left: -15px;
  margin-right: -15px; }
  .testi-slider-three .testimonial-item-two {
    margin-left: 15px;
    margin-right: 15px; }
    @media only screen and (min-width: 480px) {
      .testi-slider-three .testimonial-item-two {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; } }
  .testi-slider-three .slick-dots {
    margin-top: 45px; }

.testimonial-item-two {
  padding: 40px;
  background: white;
  margin-bottom: 30px;
  border-radius: 12px; }
  @media only screen and (min-width: 480px) {
    .testimonial-item-two {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between; } }
  @media only screen and (max-width: 767px) {
    .testimonial-item-two {
      padding-left: 25px;
      padding-right: 25px; } }
  .testimonial-item-two .author {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none; }
    @media only screen and (max-width: 479px) {
      .testimonial-item-two .author {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; } }
    .testimonial-item-two .author img {
      max-width: 100px;
      border-radius: 50%;
      margin-bottom: 18px; }
      @media only screen and (max-width: 479px) {
        .testimonial-item-two .author img {
          max-width: 85px;
          margin-right: 15px; } }
    .testimonial-item-two .author h6 {
      margin-bottom: 0; }
      @media only screen and (min-width: 576px) {
        .testimonial-item-two .author h6 {
          font-size: 20px; } }
    .testimonial-item-two .author span {
      display: block;
      margin-bottom: 10px; }
      @media only screen and (max-width: 479px) {
        .testimonial-item-two .author span {
          margin-bottom: 0; } }
    .testimonial-item-two .author .ratting i {
      width: 20px;
      height: 20px;
      color: white;
      font-size: 10px;
      margin: 0 8px 0 0;
      line-height: 20px;
      text-align: center;
      background: coral; }
  .testimonial-item-two .line {
    width: 2px;
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    margin-left: 45px;
    margin-right: 45px;
    background: rgba(0, 0, 0, 0.1); }
    @media only screen and (max-width: 767px) {
      .testimonial-item-two .line {
        margin-left: 25px;
        margin-right: 25px; } }
    @media only screen and (max-width: 479px) {
      .testimonial-item-two .line {
        height: 2px;
        width: 100%;
        margin: 10px 0 20px; } }
  .testimonial-item-two .author-text:after, .testimonial-item-two .author-text:before {
    content: "";
    display: block;
    font-size: 45px;
    text-align: right;
    color: var(--primary-color);
    font-family: flaticon_akpager !important; }
  .testimonial-item-two .author-text:before {
    margin-bottom: 15px;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg); }
  .testimonial-item-two .author-text:after {
    margin-top: 5px;
    -webkit-transform: scale(-1, 1) rotate(180deg);
    -ms-transform: scale(-1, 1) rotate(180deg);
    transform: scale(-1, 1) rotate(180deg); }

/* Testimonials Four Slider */
.testi-slider-four {
  margin-left: -15px;
  margin-right: -15px; }
  .testi-slider-four .testimonial-item-three {
    margin-left: 15px;
    margin-right: 15px; }
    @media only screen and (min-width: 576px) {
      .testi-slider-four .testimonial-item-three {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; } }
  .testi-slider-four .slick-dots {
    margin-top: 45px; }

.testimonial-item-three {
  margin-bottom: 30px; }
  @media only screen and (min-width: 576px) {
    .testimonial-item-three {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between; } }
  .testimonial-item-three .image {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    max-width: 190px;
    margin-right: 30px; }
    @media only screen and (max-width: 575px) {
      .testimonial-item-three .image {
        margin-bottom: 30px; } }
    .testimonial-item-three .image img {
      width: 100%;
      border-radius: 20px; }
  .testimonial-item-three .ratting {
    margin-bottom: 20px; }
    .testimonial-item-three .ratting i {
      width: 26px;
      height: 26px;
      color: white;
      font-size: 13px;
      margin: 0 5px 0 0;
      line-height: 26px;
      text-align: center;
      background: var(--primary-color); }
  .testimonial-item-three .author-text {
    font-size: 20px;
    line-height: 1.75;
    margin-bottom: 20px;
    letter-spacing: -0.6px;
    font-family: var(--heading-font); }
    @media only screen and (max-width: 375px) {
      .testimonial-item-three .author-text {
        font-size: 18px; } }
  .testimonial-item-three .name-designation {
    position: relative;
    padding-left: 65px; }
    .testimonial-item-three .name-designation h6 {
      margin-bottom: 0; }
      @media only screen and (min-width: 376px) {
        .testimonial-item-three .name-designation h6 {
          font-size: 18px; } }
    .testimonial-item-three .name-designation span {
      opacity: 0.55;
      font-size: 14px; }
    .testimonial-item-three .name-designation:before {
      content: "";
      line-height: 1;
      font-size: 65px;
      position: absolute;
      left: -6px;
      top: -6px;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: flaticon_akpager !important;
      background-image: -webkit-linear-gradient(356deg, #B065E7 13.66%, #3157EF 81.89%);
      background-image: -o-linear-gradient(356deg, #B065E7 13.66%, #3157EF 81.89%);
      background-image: linear-gradient(94deg, #B065E7 13.66%, #3157EF 81.89%); }

/* Testimonials Six Slider */
@media only screen and (min-width: 480px) {
  .testi-slider-six {
    padding-left: 55px;
    padding-right: 55px; } }

.testi-slider-six .slick-list {
  max-width: 690px;
  margin-left: auto;
  margin-right: auto; }

.testi-slider-six .slick-arrow {
  top: 50%;
  z-index: 1;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  position: absolute;
  width: 50px;
  height: 50px;
  background: white;
  line-height: 50px;
  border-radius: 50%;
  text-align: center;
  color: var(--heading-color);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); }
  @media only screen and (max-width: 479px) {
    .testi-slider-six .slick-arrow {
      top: 40px; } }
  .testi-slider-six .slick-arrow.prev-arrow {
    left: 0; }
  .testi-slider-six .slick-arrow.next-arrow {
    right: 0; }
  .testi-slider-six .slick-arrow:hover {
    color: white;
    background: var(--primary-color); }

.testimonial-item-four {
  text-align: center; }
  .testimonial-item-four .image {
    margin-bottom: 26px; }
    .testimonial-item-four .image img {
      border-radius: 50%;
      display: inline-block; }
  .testimonial-item-four .author-text {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.66;
    margin-bottom: 45px;
    letter-spacing: -0.7px;
    font-family: var(--heading-font); }
    @media only screen and (max-width: 767px) {
      .testimonial-item-four .author-text {
        font-size: 20px; } }
  .testimonial-item-four .quote {
    line-height: 1;
    font-size: 55px;
    color: var(--primary-color); }
  @media only screen and (min-width: 768px) {
    .testimonial-item-four .name {
      font-size: 24px; } }
  .testimonial-item-four .ratting i {
    font-size: 16px; }

/* Testimonials Seven Slider */
.testi-slider-seven {
  z-index: 1;
  position: relative; }
  @media only screen and (min-width: 376px) {
    .testi-slider-seven {
      margin-right: 50px; } }
  .testi-slider-seven:after, .testi-slider-seven:before {
    z-index: -1;
    content: '';
    width: 100%;
    height: 100%;
    opacity: 0.25;
    border-radius: 15px;
    background: white; }
  .testi-slider-seven:before {
    position: absolute;
    right: -25px;
    bottom: -35px; }
  .testi-slider-seven:after {
    position: absolute;
    right: -50px;
    bottom: -70px; }
  .testi-slider-seven .testimonial-item {
    margin-bottom: 0; }

.testimonials-arrows .slick-arrow {
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  color: var(--heading-color);
  width: 50px;
  height: 50px;
  background: var(--secondary-color);
  line-height: 50px;
  border-radius: 50%;
  text-align: center; }
  .testimonials-arrows .slick-arrow.testi-prev {
    margin-right: 15px;
    -webkit-transform: rotate(40deg);
    -ms-transform: rotate(40deg);
    transform: rotate(40deg); }
  .testimonials-arrows .slick-arrow.testi-next {
    -webkit-transform: rotate(-40deg);
    -ms-transform: rotate(-40deg);
    transform: rotate(-40deg); }
  .testimonials-arrows .slick-arrow:hover {
    color: white;
    background: var(--primary-color); }

.testimonials-bg {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: 180%;
  -webkit-clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%);
  clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%); }

/* Testimonials Eight Slider */
@media only screen and (min-width: 576px) {
  .slick-initialized .testimonial-item-five {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; } }

@media only screen and (min-width: 576px) {
  .testimonial-item-five {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; } }

.testimonial-item-five .image {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  max-width: 33%;
  margin-right: 6%; }
  @media only screen and (max-width: 575px) {
    .testimonial-item-five .image {
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 25px; } }
  .testimonial-item-five .image img {
    border-radius: 20px; }

.testimonial-item-five .author-text {
  font-size: 24px;
  line-height: 1.66;
  margin-bottom: 10%;
  letter-spacing: -0.5px; }
  @media only screen and (max-width: 1199px) {
    .testimonial-item-five .author-text {
      font-size: 20px;
      margin-bottom: 35px; } }
  @media only screen and (max-width: 375px) {
    .testimonial-item-five .author-text {
      font-size: 18px;
      margin-bottom: 25px; } }

.testimonial-item-five .author-description {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .testimonial-item-five .author-description .icon {
    width: 100px;
    height: 100px;
    font-size: 65px;
    text-align: center;
    line-height: 100px;
    margin-right: 30px;
    border-radius: 50%;
    color: var(--primary-color);
    border: 1px solid rgba(255, 255, 255, 0.1); }
    @media only screen and (max-width: 767px) {
      .testimonial-item-five .author-description .icon {
        width: 60px;
        height: 60px;
        font-size: 40px;
        padding-top: 5px;
        line-height: 60px; } }
  .testimonial-item-five .author-description .author h4 {
    margin-bottom: 0; }
    @media only screen and (max-width: 767px) {
      .testimonial-item-five .author-description .author h4 {
        font-size: 20px; } }
  .testimonial-item-five .author-description .author span {
    opacity: 0.55; }

/* Home Seven */
.testimonials-seven-content-part {
  max-width: 560px; }
  .testimonials-seven-content-part .section-title {
    max-width: 500px; }

.testimonials-seven-iamge-part {
  position: relative; }
  .testimonials-seven-iamge-part .man {
    z-index: 1;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    position: relative; }
    .testimonials-seven-iamge-part .man:after {
      position: absolute;
      left: 0;
      bottom: 0;
      content: '';
      z-index: -1;
      width: 100%;
      height: 90%;
      background: #C4F3E3; }
    .testimonials-seven-iamge-part .man img {
      max-width: 88%; }
  .testimonials-seven-iamge-part .users {
    z-index: 2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    left: -10%;
    top: 22%;
    padding: 10px 20px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: var(--primary-color); }
    @media only screen and (max-width: 1199px) {
      .testimonials-seven-iamge-part .users {
        left: -5%;
        top: 35%; } }
    @media only screen and (max-width: 767px) {
      .testimonials-seven-iamge-part .users {
        left: -0; } }
    .testimonials-seven-iamge-part .users .text {
      margin-right: 15px;
      color: var(--heading-color); }
      @media only screen and (max-width: 375px) {
        .testimonials-seven-iamge-part .users .text {
          font-size: 14px; } }
    @media only screen and (max-width: 375px) {
      .testimonials-seven-iamge-part .users img {
        max-width: 80px; } }
  .testimonials-seven-iamge-part .google-ratting {
    z-index: 2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    right: -5%;
    bottom: 20px;
    max-width: 90%;
    background: white;
    border-radius: 40px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 20px 45px 10px 35px;
    -webkit-box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1); }
    @media only screen and (max-width: 767px) {
      .testimonials-seven-iamge-part .google-ratting {
        right: 0;
        padding: 12px 25px 2px 15px; } }
    .testimonials-seven-iamge-part .google-ratting > * {
      margin-bottom: 10px; }
    .testimonials-seven-iamge-part .google-ratting img {
      max-width: 40px;
      margin-right: 18px; }
      @media only screen and (max-width: 479px) {
        .testimonials-seven-iamge-part .google-ratting img {
          max-width: 30px;
          margin-right: 10px; } }
    .testimonials-seven-iamge-part .google-ratting .ratting {
      margin-right: 18px; }
      @media only screen and (max-width: 479px) {
        .testimonials-seven-iamge-part .google-ratting .ratting {
          display: none; } }
      .testimonials-seven-iamge-part .google-ratting .ratting i {
        margin: 4px;
        color: #FF9900;
        font-size: 15px; }
    @media only screen and (max-width: 479px) {
      .testimonials-seven-iamge-part .google-ratting .text {
        font-size: 14px; } }

/* Testimonials Nine */
.testimonials-nine-area {
  background: -webkit-linear-gradient(207.99deg, #8F00FF 2.4%, rgba(252, 120, 11, 0.2) 66.08%, rgba(59, 11, 252, 0.8) 109.5%);
  background: -o-linear-gradient(207.99deg, #8F00FF 2.4%, rgba(252, 120, 11, 0.2) 66.08%, rgba(59, 11, 252, 0.8) 109.5%);
  background: linear-gradient(242.01deg, #8F00FF 2.4%, rgba(252, 120, 11, 0.2) 66.08%, rgba(59, 11, 252, 0.8) 109.5%); }

.testimonial-nine-shapes .shape {
  z-index: -1;
  max-width: 20%;
  position: absolute; }
  .testimonial-nine-shapes .shape.one {
    top: 40%;
    left: 5%; }
  .testimonial-nine-shapes .shape.two {
    top: 45%;
    right: 5%; }

/* About Page */
.testi-slider-nine {
  margin-left: -15px;
  margin-right: -15px; }
  .testi-slider-nine .testimonial-item {
    margin-left: 15px;
    margin-right: 15px; }
  .testi-slider-nine .slick-dots li {
    background: var(--primary-color); }
    .testi-slider-nine .slick-dots li.slick-active:after {
      border-color: var(--primary-color); }

/*******************************************************/
/********************** ## Blog **********************/
/*******************************************************/
.blog-meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-top: 12px;
  padding-bottom: 12px;
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color); }
  .blog-meta li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .blog-meta li i {
      margin-top: 2px;
      margin-right: 7px; }
      @media only screen and (max-width: 375px) {
        .blog-meta li i {
          display: none; } }
    .blog-meta li img {
      width: 25px;
      height: 25px;
      margin-right: 7px;
      border-radius: 50%; }
    .blog-meta li:not(:last-child):after {
      content: '';
      width: 5px;
      height: 5px;
      border-radius: 50%;
      margin-left: 30px;
      margin-right: 30px;
      background: rgba(0, 23, 38, 0.1); }
      @media only screen and (max-width: 479px) {
        .blog-meta li:not(:last-child):after {
          margin-left: 15px;
          margin-right: 15px; } }

.blog-item {
  margin-bottom: 30px; }
  .blog-item .image {
    position: relative;
    margin-bottom: 30px; }
    .blog-item .image img {
      width: 100%;
      border-radius: 20px; }
    .blog-item .image .date {
      width: 70px;
      height: 70px;
      line-height: 1.2;
      text-align: center;
      position: absolute;
      right: 15px;
      bottom: -22px;
      border-radius: 6px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      color: var(--heading-color);
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      background: var(--secondary-color); }
      .blog-item .image .date b {
        font-size: 27px;
        font-weight: 700;
        letter-spacing: -0.8px; }
      .blog-item .image .date span {
        font-size: 18px;
        font-weight: 600; }
  .blog-item .content .category {
    font-weight: 600;
    margin-bottom: 5px;
    color: var(--primary-color);
    display: inline-block; }
  .blog-item .content h5 {
    max-width: 350px;
    line-height: 1.36;
    margin-bottom: 25px;
    letter-spacing: -0.66px; }
    @media only screen and (max-width: 375px) {
      .blog-item .content h5 {
        font-size: 20px; } }
  .blog-item.style-two {
    overflow: hidden;
    border-radius: 16px;
    -webkit-box-shadow: 0px 10px 60px 0px rgba(185, 102, 231, 0.2);
    box-shadow: 0px 10px 60px 0px rgba(185, 102, 231, 0.2); }
    .blog-item.style-two .image img {
      border-radius: 16px 16px 0 0; }
    .blog-item.style-two .image .date {
      color: white;
      background: var(--primary-color); }
    .blog-item.style-two .content {
      padding-left: 30px;
      padding-right: 30px; }
      .blog-item.style-two .content .category {
        color: var(--base-color); }
        .blog-item.style-two .content .category:hover {
          color: var(--primary-color); }
      .blog-item.style-two .content h5 a:hover {
        text-decoration: underline; }
      .blog-item.style-two .content .blog-meta {
        margin-left: -30px;
        margin-right: -30px;
        padding-left: 30px;
        padding-right: 30px;
        border-bottom: none; }
        @media only screen and (max-width: 1299px) {
          .blog-item.style-two .content .blog-meta li:not(:last-child) {
            margin-right: 25px; } }
        .blog-item.style-two .content .blog-meta li:not(:last-child):after {
          margin-left: 25px;
          margin-right: 25px; }
          @media only screen and (max-width: 1299px) {
            .blog-item.style-two .content .blog-meta li:not(:last-child):after {
              display: none; } }

/* Blog Standard Area */
.blog-standard-item {
  margin-bottom: 50px; }
  .blog-standard-item .image img {
    width: 100%;
    border-radius: 12px; }
  .blog-standard-item .blog-meta {
    border-top: none;
    margin-bottom: 22px;
    padding-top: 22px;
    padding-bottom: 22px; }
    .blog-standard-item .blog-meta li:not(:last-child) {
      margin-right: 30px; }
      .blog-standard-item .blog-meta li:not(:last-child):after {
        display: none; }
    .blog-standard-item .blog-meta li .category {
      font-weight: 600;
      color: var(--primary-color);
      font-family: var(--heading-font); }
  .blog-standard-item .content h3 {
    margin-bottom: 15px;
    letter-spacing: -0.9px; }
    @media only screen and (max-width: 575px) {
      .blog-standard-item .content h3 {
        font-size: 25px;
        line-height: 1.3; } }
    @media only screen and (max-width: 375px) {
      .blog-standard-item .content h3 {
        font-size: 22px; } }
  .blog-standard-item .content p {
    margin-bottom: 30px; }

.blog-details-wrap,
.blog-standard-wrap {
  max-width: 770px; }

/* Blog Details Area */
.blog-details-content .image img {
  width: 100%;
  border-radius: 12px; }

.blog-details-content h3 {
  margin-bottom: 20px;
  letter-spacing: -0.9px; }
  @media only screen and (max-width: 575px) {
    .blog-details-content h3 {
      font-size: 25px;
      line-height: 1.3; } }
  @media only screen and (max-width: 375px) {
    .blog-details-content h3 {
      font-size: 22px; } }

.tag-share {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }
  .tag-share .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 5px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .tag-share .item > b {
      font-size: 18px;
      margin-top: 10px;
      margin-right: 20px;
      color: var(--heading-color); }
    .tag-share .item .social-style-one a {
      width: 35px;
      height: 35px;
      line-height: 33px;
      border: 1px solid var(--border-color); }
      .tag-share .item .social-style-one a:not(:hover) {
        color: var(--base-color);
        background: transparent; }

/* Next Prev Blog */
.next-prev-blog {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }
  .next-prev-blog .item {
    max-width: 365px;
    margin-bottom: 30px; }

/* Comments */
@media only screen and (min-width: 376px) {
  .comment-title {
    font-size: 27px; } }

.comment-body {
  margin-bottom: 33px; }
  @media only screen and (min-width: 480px) {
    .comment-body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; } }
  .comment-body .author-thumb {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    margin-top: 5px;
    max-width: 100px;
    margin-right: 35px;
    margin-bottom: 20px; }
    @media only screen and (max-width: 575px) {
      .comment-body .author-thumb {
        margin-right: 25px; } }
    .comment-body .author-thumb img {
      border-radius: 50%; }
  .comment-body .content .comment-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .comment-body .content .comment-header li {
      margin-bottom: 5px;
      text-transform: capitalize; }
  .comment-body .content h6 {
    margin-bottom: 0;
    margin-right: 20px; }
    @media only screen and (min-width: 376px) {
      .comment-body .content h6 {
        font-size: 18px; } }
  .comment-body .content p {
    margin-bottom: 8px; }
  .comment-body.comment-child {
    margin-left: 70px; }
    @media only screen and (max-width: 575px) {
      .comment-body.comment-child {
        margin-left: 30px; } }

.admin-comment {
  border-radius: 12px;
  border: 1px solid var(--border-color); }
  .admin-comment .comment-body {
    margin-bottom: 0;
    padding: 30px 40px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    @media only screen and (max-width: 767px) {
      .admin-comment .comment-body {
        padding-left: 25px;
        padding-right: 25px; } }
    .admin-comment .comment-body .author-thumb {
      max-width: 130px; }
      @media only screen and (min-width: 576px) {
        .admin-comment .comment-body .author-thumb {
          margin-bottom: 0;
          margin-right: 40px; } }
    @media only screen and (min-width: 376px) {
      .admin-comment .comment-body .content h5 {
        font-size: 22px; } }
    .admin-comment .comment-body .content p {
      font-size: 16px; }
    .admin-comment .comment-body .content .social-icons a {
      margin-right: 25px; }
      .admin-comment .comment-body .content .social-icons a:hover {
        color: var(--primary-color); }

/* Comment Form */
.comment-form {
  padding: 40px 60px 50px; }
  @media only screen and (max-width: 479px) {
    .comment-form {
      padding-left: 25px;
      padding-right: 25px; } }
  .comment-form h4 {
    margin-bottom: 5px; }
    @media only screen and (min-width: 376px) {
      .comment-form h4 {
        font-size: 27px; } }
  .comment-form .form-group {
    margin-bottom: 30px; }
  .comment-form .form-control {
    border: none;
    background: white;
    padding: 18px 25px; }

/*******************************************************/
/********************** ## Tabs **********************/
/*******************************************************/
/** Tab Style One **/
.advanced-tab {
  margin-left: -15px;
  margin-right: -15px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }
  .advanced-tab li {
    margin: 0 35px 15px; }
    @media only screen and (max-width: 1299px) {
      .advanced-tab li {
        margin-left: 15px;
        margin-right: 15px; } }
    @media only screen and (max-width: 991px) {
      .advanced-tab li {
        margin-bottom: 10px; } }
    .advanced-tab li a,
    .advanced-tab li button {
      font-size: 22px;
      padding: 0 0 15px;
      display: inline-block;
      background: transparent;
      border-bottom: 2px solid transparent; }
      @media only screen and (max-width: 1199px) {
        .advanced-tab li a,
        .advanced-tab li button {
          font-size: 20px;
          padding-bottom: 5px; } }
      @media only screen and (max-width: 575px) {
        .advanced-tab li a,
        .advanced-tab li button {
          font-size: 18px; } }
      .advanced-tab li a i,
      .advanced-tab li button i {
        margin-right: 15px;
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg); }
        @media only screen and (max-width: 1299px) {
          .advanced-tab li a i,
          .advanced-tab li button i {
            margin-right: 5px; } }
      .advanced-tab li a.active,
      .advanced-tab li button.active {
        border-color: var(--primary-color); }
  .advanced-tab.style-two {
    border-bottom: 1.2px solid var(--border-color); }
    .advanced-tab.style-two li {
      margin: 0 20px -1px; }
      .advanced-tab.style-two li button {
        padding: 20px; }
        @media only screen and (max-width: 991px) {
          .advanced-tab.style-two li button {
            padding: 10px; } }
        @media only screen and (max-width: 479px) {
          .advanced-tab.style-two li button {
            padding: 5px; } }
        .advanced-tab.style-two li button.active {
          color: var(--primary-color); }
  .advanced-tab.style-three li {
    -webkit-box-flex: 1;
    -ms-flex: 1 auto;
    flex: 1 auto;
    margin: 0 15px 15px; }
  .advanced-tab.style-three button {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background: white;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 18px 30px;
    border-radius: 16px;
    border: 2px solid transparent; }
    @media only screen and (max-width: 375px) {
      .advanced-tab.style-three button {
        padding: 10px 15px; } }
    .advanced-tab.style-three button .icon {
      width: 40px;
      height: 40px;
      font-size: 18px;
      line-height: 40px;
      margin-right: 12px;
      position: relative;
      text-align: center;
      color: var(--primary-color); }
      .advanced-tab.style-three button .icon:after {
        position: absolute;
        left: 0;
        top: 0;
        content: '';
        width: 100%;
        height: 100%;
        opacity: 0.2;
        border-radius: 50%;
        background: var(--primary-color); }
      .advanced-tab.style-three button .icon i {
        margin-right: 0;
        -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
        transform: rotate(0); }
    .advanced-tab.style-three button:hover, .advanced-tab.style-three button.active {
      color: var(--heading-color); }
  .advanced-tab.style-four {
    background: #1C2433;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    border-radius: 0 0 12px 12px; }
    .advanced-tab.style-four li {
      margin: 0; }
      .advanced-tab.style-four li button {
        color: #657085;
        font-size: 20px;
        border-style: solid;
        border-width: 3px 0 0;
        letter-spacing: -0.6px;
        padding: 12px 35px 15px;
        border-color: transparent; }
        .advanced-tab.style-four li button.active {
          color: white;
          text-decoration: underline;
          border-color: var(--primary-color);
          -webkit-text-decoration-color: #9fa4ad;
          text-decoration-color: #9fa4ad; }

.tab-image {
  margin: -5%; }
  @media only screen and (max-width: 991px) {
    .tab-image {
      margin: -8%; } }

@media only screen and (min-width: 1200px) {
  .tab-area-two h3 {
    font-size: 35px; } }

.tab-area-five .feature-icon-box {
  margin-bottom: 10px; }

.tab-area-five .management-images {
  padding-left: 13%; }

/* Home Nine */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .advanced-tab-area .content .section-title h2 {
    font-size: 40px; } }

/*******************************************************/
/******************* ## Forms Styles *******************/
/*******************************************************/
.newsletter-form {
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: white;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 15px; }
  .newsletter-form label {
    margin: 0 0 0 20px; }
    @media only screen and (max-width: 375px) {
      .newsletter-form label {
        display: none; } }
  .newsletter-form input {
    border: none;
    padding: 0 5px 0 20px;
    background: transparent; }
    .newsletter-form input::-webkit-input-placeholder {
      color: var(--base-color); }
    .newsletter-form input:-ms-input-placeholder {
      color: var(--base-color); }
    .newsletter-form input::-ms-input-placeholder {
      color: var(--base-color); }
    .newsletter-form input::placeholder {
      color: var(--base-color); }
  .newsletter-form button {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    color: white;
    font-weight: 600;
    padding: 9px 24px;
    border-radius: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: var(--heading-color); }
    .newsletter-form button i {
      margin-top: 2px;
      font-size: 0.9em;
      margin-left: 10px;
      -webkit-transform: rotate(-30deg);
      -ms-transform: rotate(-30deg);
      transform: rotate(-30deg); }
      @media only screen and (max-width: 375px) {
        .newsletter-form button i {
          display: none; } }
    @media only screen and (min-width: 480px) {
      .newsletter-form button b {
        font-size: 18px;
        padding: 4px 18px; } }
  .newsletter-form.style-two {
    padding: 5px; }
    .newsletter-form.style-two button {
      width: 46px;
      height: 46px;
      padding: 5px;
      text-align: center;
      line-height: 46px;
      background: var(--primary-color); }
      .newsletter-form.style-two button i {
        margin-left: 0;
        display: block; }
  .newsletter-form.style-three {
    padding: 0;
    border-radius: 0;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    background: transparent; }
    .newsletter-form.style-three input {
      background: white;
      padding: 13px 25px;
      margin-right: 10px;
      border-radius: 15px; }
    .newsletter-form.style-three button {
      background: var(--primary-color); }
      @media only screen and (min-width: 576px) {
        .newsletter-form.style-three button {
          font-size: 18px;
          padding-left: 45px;
          padding-right: 45px; } }

.nice-select {
  font-size: 16px;
  border-radius: 7px;
  font-weight: 400;
  padding: 4px 40px 4px 20px;
  background: var(--lighter-color);
  border: 1px solid var(--border-color); }
  .nice-select:after {
    right: 20px;
    top: 24px;
    width: 7px;
    height: 7px; }
  .nice-select .list {
    width: 100%; }
    .nice-select .list li {
      font-size: 16px;
      margin-bottom: 0; }
      .nice-select .list li:before {
        content: none; }

.help-block.with-errors {
  color: red; }

/*******************************************************/
/********************** ## Team **********************/
/*******************************************************/
.team-member {
  text-align: center;
  margin-bottom: 40px; }
  .team-member .image {
    position: relative;
    margin-bottom: 20px; }
    .team-member .image img {
      border-radius: 12px; }
    .team-member .image .social-media {
      opacity: 0;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      position: absolute;
      left: 50%;
      bottom: 50px;
      padding: 10px 25px;
      border-radius: 12px;
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content;
      background: var(--primary-color);
      -webkit-transform: translate(-50%);
      -ms-transform: translate(-50%);
      transform: translate(-50%); }
      .team-member .image .social-media a {
        color: white;
        margin: 0 8px;
        font-size: 14px; }
  .team-member .description h5 {
    margin-bottom: 0; }
  .team-member .description .designation {
    color: var(--primary-color); }
  .team-member:hover .social-media {
    opacity: 1;
    bottom: 20px; }

/*******************************************************/
/*********************  ## Pricing  ********************/
/*******************************************************/
.pricing-item {
  z-index: 1;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  position: relative;
  padding: 50px 45px;
  border-radius: 20px;
  margin-bottom: 30px;
  background: var(--lighter-color); }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .pricing-item {
      padding-left: 35px;
      padding-right: 35px; } }
  @media only screen and (max-width: 375px) {
    .pricing-item {
      padding-left: 30px;
      padding-right: 30px; } }
  .pricing-item .circle {
    z-index: -1;
    position: absolute;
    right: 40px;
    top: 40px; }
  .pricing-item .price {
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    margin-bottom: 10px;
    letter-spacing: -1.44px; }
    @media only screen and (max-width: 375px) {
      .pricing-item .price {
        font-size: 40px; } }
    .pricing-item .price .next {
      font-size: 16px;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      font-weight: 400;
      color: var(--base-color);
      letter-spacing: -0.48px; }
  .pricing-item .text {
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    font-weight: 500;
    margin-bottom: 35px;
    color: var(--heading-color); }
  .pricing-item .title {
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    margin-bottom: 25px;
    letter-spacing: -0.66px; }
  .pricing-item .list {
    padding-bottom: 8px; }
    .pricing-item .list li,
    .pricing-item .list li:before {
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s; }
  @media only screen and (max-width: 375px) {
    .pricing-item .theme-btn {
      padding-left: 25px;
      padding-right: 25px; } }
  .pricing-item.style-two {
    padding-top: 40px;
    padding-bottom: 40px; }
    @media only screen and (min-width: 992px) {
      .pricing-item.style-two {
        padding-left: 50px;
        padding-right: 50px; } }
    .pricing-item.style-two:before {
      position: absolute;
      left: 0;
      top: 0;
      content: '';
      width: 100%;
      height: 100%;
      z-index: -1;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      background: white;
      border-radius: 20px;
      border: 1px solid var(--border-color); }
    .pricing-item.style-two .popular {
      z-index: -2;
      color: white;
      position: absolute;
      left: 0;
      top: 2px;
      padding: 10px;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      width: 100%;
      height: 60px;
      text-align: center;
      text-transform: uppercase;
      background: var(--heading-color);
      border-radius: 20px 20px 0 0; }
    .pricing-item.style-two .title {
      z-index: 1;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      overflow: hidden;
      margin-bottom: 0;
      padding: 2px 12px;
      border-radius: 7px;
      position: relative;
      display: inline-block;
      color: var(--primary-color); }
      @media only screen and (max-width: 375px) {
        .pricing-item.style-two .title {
          font-size: 20px; } }
      .pricing-item.style-two .title:after {
        position: absolute;
        left: 0;
        top: 0;
        content: '';
        width: 100%;
        height: 100%;
        z-index: -1;
        opacity: 0.2;
        background: var(--primary-color); }
    .pricing-item.style-two .text {
      color: var(--base-color); }
    .pricing-item.style-two .list {
      padding-bottom: 3px; }
      .pricing-item.style-two .list li.hide {
        opacity: 0.15; }
    .pricing-item.style-two hr {
      margin-bottom: 50px; }
    .pricing-item.style-two .theme-btn {
      color: var(--heading-color);
      border: 2px solid #e4e4e4;
      background-image: -webkit-gradient(linear, left bottom, left top, color-stop(50%, white), color-stop(50%, var(--heading-color)));
      background-image: -webkit-linear-gradient(bottom, white 50%, var(--heading-color) 50%);
      background-image: -o-linear-gradient(bottom, white 50%, var(--heading-color) 50%);
      background-image: linear-gradient(to top, white 50%, var(--heading-color) 50%); }
    .pricing-item.style-two:hover {
      -webkit-transform: translateY(20px);
      -ms-transform: translateY(20px);
      transform: translateY(20px); }
      .pricing-item.style-two:hover:before {
        border-color: white;
        -webkit-box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1); }
      .pricing-item.style-two:hover .popular {
        top: -40px; }
      .pricing-item.style-two:hover .title {
        color: white;
        background: var(--primary-color); }
      .pricing-item.style-two:hover .price {
        color: var(--primary-color); }
      .pricing-item.style-two:hover .theme-btn {
        color: white;
        background-position: 0 0;
        border-color: var(--heading-color); }
  .pricing-item.style-three {
    background: transparent;
    border: 1.2px solid rgba(0, 0, 0, 0.1); }
    .pricing-item.style-three .theme-btn {
      background: #000;
      margin-bottom: 42px;
      border: 2px solid transparent; }
    .pricing-item.style-three .list {
      padding-bottom: 0; }
      .pricing-item.style-three .list li.hide {
        opacity: 0.15; }
      .pricing-item.style-three .list li:last-child {
        margin-bottom: 0; }
    .pricing-item.style-three:hover {
      background: #000; }
      .pricing-item.style-three:hover .text,
      .pricing-item.style-three:hover .price,
      .pricing-item.style-three:hover .title,
      .pricing-item.style-three:hover .list li,
      .pricing-item.style-three:hover .price .next {
        color: white; }
      .pricing-item.style-three:hover .list li.hide {
        opacity: 0.35; }
      .pricing-item.style-three:hover .list li:before {
        background: white; }
      .pricing-item.style-three:hover .theme-btn {
        border-color: white; }
  .pricing-item.style-four {
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    padding-top: 40px;
    padding-bottom: 40px; }
    @media only screen and (min-width: 992px) {
      .pricing-item.style-four {
        padding-left: 50px;
        padding-right: 50px; } }
    .pricing-item.style-four:before {
      content: '';
      z-index: -2;
      position: absolute;
      left: 0;
      top: -7px;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      width: 100%;
      height: 30px;
      background: var(--heading-color);
      border-radius: 20px 20px 0 0; }
    .pricing-item.style-four:after {
      position: absolute;
      left: 0;
      top: 0;
      content: '';
      width: 100%;
      height: 100%;
      z-index: -1;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      background: white;
      border-radius: 20px;
      border: 1px solid var(--border-color); }
    .pricing-item.style-four .icon {
      line-height: 1;
      font-size: 65px;
      margin-bottom: 12px;
      color: var(--primary-color); }
    .pricing-item.style-four .title {
      margin-bottom: 40px; }
    .pricing-item.style-four .icon,
    .pricing-item.style-four .text,
    .pricing-item.style-four .title,
    .pricing-item.style-four .price {
      text-align: center; }
    .pricing-item.style-four hr {
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      margin-bottom: 40px; }
    .pricing-item.style-four .icon-list {
      margin-bottom: 40px; }
      .pricing-item.style-four .icon-list li {
        -webkit-transition: 0.5s;
        -o-transition: 0.5s;
        transition: 0.5s; }
    .pricing-item.style-four .theme-btn {
      color: var(--base-color);
      border: 2px solid #e4e4e4;
      background-image: -webkit-gradient(linear, left bottom, left top, color-stop(50%, transparent), color-stop(50%, var(--primary-color)));
      background-image: -webkit-linear-gradient(bottom, transparent 50%, var(--primary-color) 50%);
      background-image: -o-linear-gradient(bottom, transparent 50%, var(--primary-color) 50%);
      background-image: linear-gradient(to top, transparent 50%, var(--primary-color) 50%); }
    .pricing-item.style-four:hover:before {
      background: var(--primary-color); }
    .pricing-item.style-four:hover:after {
      background: var(--heading-color); }
    .pricing-item.style-four:hover .text,
    .pricing-item.style-four:hover .price,
    .pricing-item.style-four:hover .title,
    .pricing-item.style-four:hover .icon-list li,
    .pricing-item.style-four:hover .price .next {
      color: white; }
    .pricing-item.style-four:hover hr {
      opacity: 1;
      background: #D9D9D9; }
    .pricing-item.style-four:hover .theme-btn {
      color: white;
      background-position: 0 0;
      border-color: var(--heading-color); }
  .pricing-item.style-five {
    background: white;
    border: 1px solid var(--border-color);
    padding-top: 40px;
    padding-bottom: 40px; }
    @media only screen and (min-width: 992px) {
      .pricing-item.style-five {
        padding-left: 50px;
        padding-right: 50px; } }
    .pricing-item.style-five .title-price {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between; }
      .pricing-item.style-five .title-price .title {
        color: white;
        padding: 3px 10px;
        border-radius: 5px;
        margin-bottom: 22px;
        letter-spacing: -0.66px;
        background: var(--primary-color); }
      .pricing-item.style-five .title-price .price {
        margin-bottom: 15px; }
    .pricing-item.style-five .text {
      margin-top: 25px;
      margin-bottom: 30px; }
    .pricing-item.style-five .icon-list {
      margin-bottom: 45px; }
      .pricing-item.style-five .icon-list li.hide {
        opacity: 0.1; }
    .pricing-item.style-five .footer-text {
      margin: 10px 0 -5px 25px; }
    .pricing-item.style-five:hover {
      border-color: white;
      -webkit-box-shadow: 0px 10px 60px rgba(141, 141, 141, 0.1);
      box-shadow: 0px 10px 60px rgba(141, 141, 141, 0.1); }
      .pricing-item.style-five:hover .theme-btn {
        background-position: 0 0; }
  .pricing-item.style-six {
    background: #0E1916; }
    .pricing-item.style-six .icon {
      width: 90px;
      height: 90px;
      font-size: 55px;
      line-height: 90px;
      border-radius: 50%;
      text-align: center;
      margin-bottom: 35px;
      color: var(--primary-color);
      background: rgba(255, 255, 255, 0.05); }
    .pricing-item.style-six h5 {
      color: white;
      margin-bottom: 10px; }
    .pricing-item.style-six p {
      margin-bottom: 25px;
      color: rgba(255, 255, 255, 0.65); }
    .pricing-item.style-six .icon-list {
      margin-bottom: 40px; }
      .pricing-item.style-six .icon-list li {
        color: white;
        font-weight: 400; }
    .pricing-item.style-six:hover .theme-btn {
      color: var(--heading-color);
      background-position: 0 0; }
  .pricing-item.bgc-primary .text,
  .pricing-item.bgc-primary .next,
  .pricing-item.bgc-primary .price,
  .pricing-item.bgc-primary .list li, .pricing-item.bgc-navyblue .text,
  .pricing-item.bgc-navyblue .next,
  .pricing-item.bgc-navyblue .price,
  .pricing-item.bgc-navyblue .list li {
    color: white; }
  .pricing-item.bgc-primary .list li:before, .pricing-item.bgc-navyblue .list li:before {
    background: white; }
  .pricing-item.bgc-primary .theme-btn, .pricing-item.bgc-navyblue .theme-btn {
    outline: 2px solid white; }
  .pricing-item.bgc-primary {
    background: var(--primary-color); }
  .pricing-item.bgc-navyblue {
    background: var(--navyblue-color); }
  .pricing-item.bgc-yellow {
    background: var(--secondary-color); }
    .pricing-item.bgc-yellow .list li:before {
      background: var(--heading-color); }

.save-percent {
  font-weight: 500;
  background-image: url(../images/shapes/title-shape-two.png);
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 100%;
  padding-bottom: 15px;
  padding-left: 10px;
  margin-left: -10px;
  margin-right: 22px; }
  @media only screen and (min-width: 376px) {
    .save-percent {
      font-size: 20px; } }

.pricing-tab {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex; }
  .pricing-tab li:first-child button:after,
  .pricing-tab li:last-child button:before {
    content: '';
    width: 20px;
    height: 20px;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    border-radius: 50%;
    background: var(--primary-color); }
  .pricing-tab li:first-child button:after {
    margin-left: 10px; }
  .pricing-tab li:last-child button:before {
    margin-right: 10px; }
  .pricing-tab li:first-child button:before {
    position: absolute;
    width: 46px;
    height: 24px;
    content: '';
    top: 50%;
    right: 0;
    z-index: -1;
    border-radius: 12px;
    background: var(--primary-color);
    -webkit-transform: translate(50%, -50%);
    -ms-transform: translate(50%, -50%);
    transform: translate(50%, -50%); }
  .pricing-tab li button {
    padding: 0;
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-weight: 600;
    color: var(--base-color);
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    background: transparent;
    text-transform: capitalize;
    font-family: var(--heading-font); }
    @media only screen and (min-width: 376px) {
      .pricing-tab li button {
        font-size: 20px; } }
    .pricing-tab li button:focus, .pricing-tab li button:hover {
      color: var(--heading-color); }
    .pricing-tab li button.active {
      color: var(--heading-color); }
  .pricing-tab li:first-child button.active:after,
  .pricing-tab li:last-child button.active:before {
    background: white; }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .trustpilot-review {
    padding: 25px; } }

.trustpilot-review h6 {
  margin-bottom: 15px; }

.trustpilot-review hr {
  margin-top: 45px;
  margin-bottom: 45px; }

.trustpilot-review .rattings {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .trustpilot-review .rattings i {
    width: 26px;
    height: 26px;
    font-size: 14px;
    background: white;
    text-align: center;
    line-height: 26px;
    margin-right: 5px;
    color: var(--primary-color); }

.trustpilot-review .authors {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .trustpilot-review .authors img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1.5px solid white; }
    .trustpilot-review .authors img:not(:first-child) {
      margin-left: -10px; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .trustpilot-review .authors img:not(:first-child) {
          margin-left: -15px; } }
  .trustpilot-review .authors .plus {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    margin-left: -10px;
    width: 40px;
    height: 40px;
    background: white;
    line-height: 40px;
    border-radius: 50%;
    text-align: center;
    color: var(--primary-color); }

/* BG Circle shapes */
.bg-circle-shapes {
  z-index: -1;
  left: 50%;
  bottom: 0;
  width: 1090px;
  height: 1090px;
  position: absolute;
  -webkit-transform: translate(-50%, 50%);
  -ms-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%); }
  @media only screen and (max-width: 1199px) {
    .bg-circle-shapes {
      width: 700px;
      height: 700px; } }
  @media only screen and (max-width: 767px) {
    .bg-circle-shapes {
      width: 500px;
      height: 500px; } }
  .bg-circle-shapes span {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background: rgba(21, 31, 57, 0.02);
    border: 1px solid rgba(21, 31, 57, 0.07); }
    .bg-circle-shapes span:nth-child(1) {
      width: 100%;
      height: 100%; }
    .bg-circle-shapes span:nth-child(2) {
      width: 80%;
      height: 80%; }
    .bg-circle-shapes span:nth-child(3) {
      width: 60%;
      height: 60%; }
    .bg-circle-shapes span:nth-child(4) {
      width: 40%;
      height: 40%; }

/* Home Nine */
@media only screen and (min-width: 1400px) {
  .price-left-tab {
    max-width: 355px; } }

@media only screen and (max-width: 1199px) {
  .price-left-tab {
    margin-top: 0; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .price-left-tab {
    margin-top: 40px; } }

/* Pricing Page */
.pricing-three-area .pricing-item.style-three {
  border: none;
  background: white;
  -webkit-box-shadow: 0px 10px 60px rgba(130, 130, 130, 0.1);
  box-shadow: 0px 10px 60px rgba(130, 130, 130, 0.1); }
  .pricing-three-area .pricing-item.style-three .theme-btn {
    background: var(--primary-color); }
  .pricing-three-area .pricing-item.style-three:hover {
    background: var(--navyblue-color); }
    .pricing-three-area .pricing-item.style-three:hover .theme-btn {
      background: var(--navyblue-color); }

.pricing-three-shapes .shape {
  z-index: -1;
  max-width: 10%;
  position: absolute; }
  .pricing-three-shapes .shape.one {
    left: 5%;
    bottom: 15%;
    -webkit-animation: upDownLeft 20s infinite;
    animation: upDownLeft 20s infinite; }
  .pricing-three-shapes .shape.two {
    right: 10%;
    top: 10%;
    -webkit-animation: upDownLeft 20s infinite;
    animation: upDownLeft 20s infinite; }

/*******************************************************/
/******************** ## Projects ********************/
/*******************************************************/
.project-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }
  .project-nav li {
    cursor: pointer;
    font-size: 18px;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    font-weight: 600;
    padding: 2px 22px;
    border-radius: 7px;
    margin-bottom: 10px;
    letter-spacing: -0.36px; }
    .project-nav li.active {
      color: white;
      background: var(--primary-color); }

.project-item {
  margin-bottom: 40px; }
  .project-item .image {
    margin-bottom: 25px; }
    .project-item .image img {
      width: 100%; }
  .project-item .content h5 {
    margin-bottom: 0; }
  .project-item.style-two {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 70px; }
    @media only screen and (min-width: 992px) {
      .project-item.style-two {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between; }
        .project-item.style-two .image {
          -webkit-box-flex: 0;
          -ms-flex: none;
          flex: none;
          width: 50%;
          margin-bottom: 0; }
        .project-item.style-two .content {
          max-width: 550px;
          padding-left: 30px;
          padding-right: 30px; } }
    @media only screen and (max-width: 991px) {
      .project-item.style-two .content {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2; } }
    .project-item.style-two .content .category {
      font-weight: 600;
      margin-bottom: 10px;
      display: inline-block;
      color: var(--primary-color); }
    .project-item.style-two .content h2 {
      margin-bottom: 50px; }
      @media only screen and (max-width: 1199px) {
        .project-item.style-two .content h2 {
          font-size: 40px; } }
      @media only screen and (max-width: 991px) {
        .project-item.style-two .content h2 {
          font-size: 35px;
          margin-bottom: 20px; } }
      @media only screen and (max-width: 575px) {
        .project-item.style-two .content h2 {
          font-size: 30px; } }
    .project-item.style-two .content .details-btn {
      margin-top: 30px; }
      @media only screen and (max-width: 991px) {
        .project-item.style-two .content .details-btn {
          margin-top: 10px; } }
    .project-item.style-two:hover .details-btn {
      color: white;
      background: var(--heading-color); }

/* Project Details */
.project-details-content .icon-list li {
  font-weight: 400;
  color: var(--base-color);
  font-family: var(--base-font); }

.project-details-info {
  padding: 40px 50px;
  border-radius: 12px;
  background: var(--lighter-color);
  border: 1px solid var(--border-color); }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .project-details-info {
      padding-left: 40px;
      padding-right: 40px; } }
  @media only screen and (max-width: 479px) {
    .project-details-info {
      padding-left: 40px;
      padding-right: 40px; } }
  @media only screen and (max-width: 375px) {
    .project-details-info {
      padding-left: 25px;
      padding-right: 25px; } }
  .project-details-info table {
    line-height: 1.5; }
    @media only screen and (min-width: 1400px) {
      .project-details-info table {
        font-size: 22px; } }
    .project-details-info table td {
      width: 50%;
      padding: 8px 0; }
      @media only screen and (max-width: 479px) {
        .project-details-info table td {
          width: auto; }
          .project-details-info table td:first-child {
            padding-right: 20px; } }
      .project-details-info table td b {
        color: var(--heading-color); }

.project-share {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .project-share h5 {
    margin: 10px 30px 0 0; }

/*******************************************************/
/********************  ## Tooltips  *******************/
/*******************************************************/
.tooltip-item {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content; }
  .tooltip-item .tooltip-btn {
    z-index: 1;
    cursor: pointer;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    position: absolute;
    width: 90px;
    height: 90px;
    background: white;
    line-height: 90px;
    border-radius: 50%;
    text-align: center;
    -webkit-box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1); }
    @media only screen and (max-width: 991px) {
      .tooltip-item .tooltip-btn {
        width: 50px;
        height: 50px;
        line-height: 50px; } }
    @media only screen and (max-width: 479px) {
      .tooltip-item .tooltip-btn {
        width: 40px;
        height: 40px;
        line-height: 40px; } }
    .tooltip-item .tooltip-btn img {
      max-width: 50%; }
  .tooltip-item.active .tooltip-btn {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1); }
    .tooltip-item.active .tooltip-btn + .tooltip-content {
      opacity: 1;
      z-index: 3; }
  .tooltip-item .tooltip-content {
    opacity: 0;
    z-index: -1;
    max-width: 33%;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    position: absolute;
    left: 50%;
    bottom: -90px;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%); }
    @media only screen and (max-width: 767px) {
      .tooltip-item .tooltip-content {
        bottom: -50px; } }
    @media only screen and (max-width: 375px) {
      .tooltip-item .tooltip-content {
        bottom: -20px; } }
    .tooltip-item .tooltip-content .image {
      overflow: hidden;
      border-radius: 20px;
      -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1); }

.tooltips-wrap {
  height: 500px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  position: relative; }
  @media only screen and (max-width: 991px) {
    .tooltips-wrap {
      height: 300px; } }
  @media only screen and (max-width: 479px) {
    .tooltips-wrap {
      height: 200px; } }
  .tooltips-wrap:after, .tooltips-wrap:before {
    content: '';
    position: absolute;
    border-radius: 50%;
    border: 1px solid var(--border-color); }
  .tooltips-wrap:before {
    top: 0;
    left: 0;
    width: 100%;
    height: 200%; }
  .tooltips-wrap:after {
    top: 30%;
    left: 15%;
    width: 70%;
    height: 140%; }
  .tooltips-wrap .tooltip-item:nth-child(1) .tooltip-btn {
    top: -8%;
    left: 45%; }
  .tooltips-wrap .tooltip-item:nth-child(2) .tooltip-btn {
    top: 10%;
    left: calc(22% - 45px); }
  .tooltips-wrap .tooltip-item:nth-child(3) .tooltip-btn {
    top: 10%;
    right: calc(22% - 45px); }
  .tooltips-wrap .tooltip-item:nth-child(4) .tooltip-btn {
    left: 0;
    bottom: calc(40% - 45px); }
  .tooltips-wrap .tooltip-item:nth-child(5) .tooltip-btn {
    right: 0;
    bottom: calc(40% - 45px); }
  .tooltips-wrap .tooltip-item:nth-child(6) .tooltip-btn {
    left: 17%;
    bottom: 35%; }
  .tooltips-wrap .tooltip-item:nth-child(7) .tooltip-btn {
    right: 17%;
    bottom: 35%; }

.tooltip-style-two {
  height: 500px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  position: relative; }
  @media only screen and (max-width: 991px) {
    .tooltip-style-two {
      height: 300px; } }
  @media only screen and (max-width: 479px) {
    .tooltip-style-two {
      height: 200px; } }
  .tooltip-style-two:after, .tooltip-style-two:before {
    content: '';
    position: absolute;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.02);
    border: 1px solid rgba(255, 255, 255, 0.05); }
  .tooltip-style-two:before {
    top: 0;
    left: 0;
    width: 100%;
    height: 200%; }
  .tooltip-style-two:after {
    top: 30%;
    left: 15%;
    width: 70%;
    height: 140%; }
  .tooltip-style-two .tooltip-item {
    position: absolute; }
    .tooltip-style-two .tooltip-item .tooltip-btn {
      position: relative; }
    .tooltip-style-two .tooltip-item .tooltip-content {
      left: 115%;
      bottom: 70%;
      max-width: none;
      -webkit-transform: translate(0);
      -ms-transform: translate(0);
      transform: translate(0); }
      @media only screen and (max-width: 1199px) {
        .tooltip-style-two .tooltip-item .tooltip-content {
          left: 15%;
          bottom: 110%; } }
      .tooltip-style-two .tooltip-item .tooltip-content .tooltip-text {
        font-weight: 500;
        padding: 0 10px;
        color: var(--heading-color);
        background: var(--secondary-color);
        font-family: var(--heading-font); }
        @media only screen and (min-width: 992px) {
          .tooltip-style-two .tooltip-item .tooltip-content .tooltip-text {
            font-size: 18px; } }
        @media only screen and (max-width: 479px) {
          .tooltip-style-two .tooltip-item .tooltip-content .tooltip-text {
            font-size: 14px; } }
        .tooltip-style-two .tooltip-item .tooltip-content .tooltip-text:before {
          content: '';
          position: absolute;
          left: 0;
          top: 100%;
          border-left: 7px solid var(--secondary-color);
          border-bottom: 7px solid transparent; }
    .tooltip-style-two .tooltip-item:hover .tooltip-btn {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1); }
    .tooltip-style-two .tooltip-item:hover .tooltip-content {
      opacity: 1; }
    .tooltip-style-two .tooltip-item:nth-child(1) {
      top: -8%;
      left: 45%; }
    .tooltip-style-two .tooltip-item:nth-child(2) {
      top: 10%;
      left: calc(22% - 45px); }
    .tooltip-style-two .tooltip-item:nth-child(3) {
      top: 10%;
      right: calc(22% - 45px); }
    .tooltip-style-two .tooltip-item:nth-child(4) {
      left: 0;
      bottom: calc(33% - 45px); }
    .tooltip-style-two .tooltip-item:nth-child(5) {
      right: 0;
      bottom: calc(33% - 45px); }
    .tooltip-style-two .tooltip-item:nth-child(6) {
      left: 19%;
      bottom: 35%; }
    .tooltip-style-two .tooltip-item:nth-child(7) {
      right: 19%;
      bottom: 35%; }

/* Tooltip Section One */
.tooltip-shapes .shape {
  z-index: -1;
  max-width: 10%;
  position: absolute; }
  .tooltip-shapes .shape.one {
    left: 5%;
    top: 40%;
    -webkit-animation: down-up-one 10s infinite;
    animation: down-up-one 10s infinite; }
  .tooltip-shapes .shape.two {
    left: 15%;
    top: 45%;
    max-width: 3%;
    -webkit-animation: shapeAnimationOne 30s infinite;
    animation: shapeAnimationOne 30s infinite; }
  .tooltip-shapes .shape.three {
    top: 20%;
    right: 10%;
    -webkit-animation: down-up-two 5s infinite;
    animation: down-up-two 5s infinite; }
  .tooltip-shapes .shape.four {
    top: 30%;
    right: 15%;
    -webkit-animation: leftRightOne 10s infinite;
    animation: leftRightOne 10s infinite; }

/*******************************************************/
/****************** ## Work Process ******************/
/*******************************************************/
.work-process-item {
  text-align: center;
  margin-bottom: 45px; }
  .work-process-item .number {
    width: 65px;
    height: 65px;
    font-size: 27px;
    font-weight: 600;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    border: 2px solid;
    line-height: 60px;
    border-radius: 50%;
    margin-bottom: 33px;
    display: inline-block; }
  .work-process-item h5 {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto; }
  .work-process-item:hover .number {
    background: white;
    color: var(--primary-color); }
  @media only screen and (min-width: 992px) {
    .work-process-item.with-arrow {
      position: relative; }
      .work-process-item.with-arrow:after, .work-process-item.with-arrow:before {
        top: 24px;
        content: '';
        width: 70%;
        height: 18px;
        position: absolute;
        -webkit-transform: translate(-50%);
        -ms-transform: translate(-50%);
        transform: translate(-50%);
        background-image: url(../images/shapes/work-process-arrow.png);
        background-repeat: no-repeat;
        background-size: 100%; }
      .work-process-item.with-arrow:before {
        left: 0; }
      .work-process-item.with-arrow:after {
        left: 100%; } }

.work-process-shapes .shape {
  z-index: -1;
  max-width: 20%;
  position: absolute; }
  .work-process-shapes .shape.one {
    left: 5%;
    top: 30%;
    -webkit-animation: down-up-one 10s infinite;
    animation: down-up-one 10s infinite; }
  .work-process-shapes .shape.two {
    top: 10%;
    right: 6%;
    -webkit-animation: down-up-two 5s infinite;
    animation: down-up-two 5s infinite; }

/*******************************************************/
/****************** ## Numbered Box ******************/
/*******************************************************/
.numbered-box {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  margin-bottom: 30px;
  background: white;
  border-radius: 12px;
  padding: 35px 50px 15px;
  border: 1px solid white;
  -webkit-box-shadow: 0px 10px 60px rgba(123, 123, 123, 0.1);
  box-shadow: 0px 10px 60px rgba(123, 123, 123, 0.1); }
  @media only screen and (max-width: 375px) {
    .numbered-box {
      padding-left: 25px;
      padding-right: 25px; } }
  .numbered-box .number {
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    margin-bottom: 25px;
    width: 50px;
    height: 50px;
    background: var(--border-color);
    line-height: 50px;
    border-radius: 50%;
    text-align: center; }
  .numbered-box:hover {
    border-color: var(--border-color); }
    .numbered-box:hover .number {
      background: var(--primary-color); }
  .numbered-box.style-two {
    padding-top: 28px;
    padding-bottom: 12px; }
    @media only screen and (min-width: 480px) {
      .numbered-box.style-two {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; } }
    .numbered-box.style-two .number {
      -webkit-box-flex: 0;
      -ms-flex: none;
      flex: none;
      margin-top: 7px;
      margin-right: 40px; }
    .numbered-box.style-two:hover {
      border-color: var(--primary-color); }
      .numbered-box.style-two:hover .number {
        color: white; }
  .numbered-box.style-three {
    border: none;
    padding-bottom: 0;
    padding-top: 40px;
    background: #E8EAF6; }
    .numbered-box.style-three .number {
      font-size: 16px;
      background: transparent;
      border: 1px solid var(--border-color); }
    .numbered-box.style-three h4 {
      letter-spacing: -0.8px; }
      @media only screen and (min-width: 376px) {
        .numbered-box.style-three h4 {
          font-size: 27px; } }
    .numbered-box.style-three .read-more {
      color: var(--base-color);
      margin-bottom: 30px; }
    .numbered-box.style-three.bg-two {
      background: #E8EAF6; }
    .numbered-box.style-three.bg-three {
      background: #F9F2E8; }

/*******************************************************/
/***************** ## Feature Icon Box *****************/
/*******************************************************/
.feature-icon-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 515px;
  margin-bottom: 18px; }
  .feature-icon-box .icon {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    color: white;
    font-size: 12px;
    margin-top: 7px;
    margin-right: 25px;
    width: 25px;
    height: 25px;
    background: var(--primary-color);
    line-height: 25px;
    border-radius: 50%;
    text-align: center; }
  .feature-icon-box .content h5 {
    font-size: 20px; }
  .feature-icon-box.text-white p {
    color: rgba(255, 255, 255, 0.55); }
  .feature-icon-box.style-two {
    display: block;
    padding: 24px 30px;
    border-radius: 15px;
    margin-bottom: 30px;
    -webkit-box-shadow: 0px 10px 60px rgba(185, 102, 231, 0.2);
    box-shadow: 0px 10px 60px rgba(185, 102, 231, 0.2); }
    .feature-icon-box.style-two .icon {
      margin-bottom: 20px;
      background: var(--secondary-color); }
    .feature-icon-box.style-two p {
      margin-bottom: 0; }
  .feature-icon-box.style-three {
    z-index: 1;
    display: block;
    max-width: none;
    position: relative;
    padding: 24px 40px;
    margin-bottom: 20px; }
    @media only screen and (max-width: 479px) {
      .feature-icon-box.style-three {
        padding-left: 25px;
        padding-right: 25px; } }
    .feature-icon-box.style-three .icon-title {
      padding-bottom: 4px; }
      @media only screen and (min-width: 376px) {
        .feature-icon-box.style-three .icon-title {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex; } }
      .feature-icon-box.style-three .icon-title .icon {
        margin-top: 3px;
        -webkit-transition: 0.5s;
        -o-transition: 0.5s;
        transition: 0.5s;
        margin-right: 15px;
        background: var(--heading-color); }
        @media only screen and (max-width: 375px) {
          .feature-icon-box.style-three .icon-title .icon {
            margin-bottom: 15px; } }
      .feature-icon-box.style-three .icon-title h5 {
        font-size: 20px; }
    .feature-icon-box.style-three p {
      margin-bottom: 0; }
    .feature-icon-box.style-three:before {
      position: absolute;
      left: 0;
      top: 0;
      content: '';
      z-index: -2;
      -webkit-transition: 0.25s;
      -o-transition: 0.25s;
      transition: 0.25s;
      width: 50px;
      height: 100%;
      border-radius: 12px;
      background: var(--primary-color); }
    .feature-icon-box.style-three:after {
      position: absolute;
      left: 0;
      top: 0;
      content: '';
      width: 100%;
      height: 100%;
      z-index: -1;
      background: white;
      border-radius: 12px; }
    .feature-icon-box.style-three:hover .icon {
      background: var(--primary-color); }
    .feature-icon-box.style-three:hover:before {
      left: -4px; }

/*******************************************************/
/***************** ## Feature Image Box *****************/
/*******************************************************/
.feature-image-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
  margin-bottom: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: calc(100% - 50px); }
  .feature-image-box .image {
    padding: 30px;
    height: 100%;
    margin-bottom: 35px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: #E7F9F1;
    border-radius: 15px; }
  .feature-image-box .content {
    max-width: 350px;
    margin-left: auto;
    margin-right: auto; }
  .feature-image-box.style-two {
    height: auto;
    margin-bottom: 30px;
    border-radius: 20px;
    padding: 30px 35px 40px;
    background: -webkit-linear-gradient(205.22deg, #8F00FF 2.5%, rgba(252, 120, 11, 0.2) 68.8%, rgba(59, 11, 252, 0.8) 114%);
    background: -o-linear-gradient(205.22deg, #8F00FF 2.5%, rgba(252, 120, 11, 0.2) 68.8%, rgba(59, 11, 252, 0.8) 114%);
    background: linear-gradient(244.78deg, #8F00FF 2.5%, rgba(252, 120, 11, 0.2) 68.8%, rgba(59, 11, 252, 0.8) 114%); }
    @media only screen and (max-width: 375px) {
      .feature-image-box.style-two {
        padding-left: 20px;
        padding-right: 20px; } }
    .feature-image-box.style-two img {
      margin-bottom: 30px;
      border-radius: 15px; }
    .feature-image-box.style-two h4 {
      text-align: left;
      margin-bottom: 30px; }
      @media only screen and (min-width: 1400px) {
        .feature-image-box.style-two h4 {
          font-size: 26px; } }
      @media only screen and (max-width: 479px) {
        .feature-image-box.style-two h4 {
          font-size: 20px; } }
    .feature-image-box.style-two .icon-list li:not(:last-child) {
      margin-bottom: 10px; }
    .feature-image-box.style-two .icon-list li i {
      color: var(--heading-color); }

/*******************************************************/
/*************** ## Marquee Iconic Box ***************/
/*******************************************************/
.iconic-slider-right .slick-list {
  padding-top: 30px !important; }

.iconic-slider-right .marquee-iconic-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: 60px;
  margin-bottom: 40px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  @media only screen and (max-width: 991px) {
    .iconic-slider-right .marquee-iconic-box {
      margin-right: 20px;
      margin-bottom: 20px; } }

.iconic-slider-right.style-two .marquee-iconic-box:nth-child(even) {
  margin-top: 40px; }

.iconic-slider-right.text-white .marquee-iconic-box {
  margin-right: 20px;
  background: #2B2B2B;
  margin-bottom: 20px;
  border-color: rgba(255, 255, 255, 0.1); }
  .iconic-slider-right.text-white .marquee-iconic-box .subtitle {
    opacity: 0.35; }

.iconic-slider-right.style-three .marquee-iconic-box {
  border-radius: 12px;
  padding: 10px 20px 10px 15px; }
  @media only screen and (min-width: 992px) {
    .iconic-slider-right.style-three .marquee-iconic-box {
      margin-right: 50px;
      margin-bottom: 30px; } }
  .iconic-slider-right.style-three .marquee-iconic-box .image {
    max-width: 35px;
    margin-right: 15px; }
    .iconic-slider-right.style-three .marquee-iconic-box .image img {
      -webkit-box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.5);
      box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.5); }
  .iconic-slider-right.style-three .marquee-iconic-box .title {
    font-size: 16px; }

.iconic-slider-left .slick-list {
  padding-bottom: 30px !important; }

.iconic-slider-left .marquee-iconic-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 60px;
  margin-bottom: 40px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  @media only screen and (max-width: 991px) {
    .iconic-slider-left .marquee-iconic-box {
      margin-left: 20px;
      margin-bottom: 20px; } }

.iconic-slider-left.text-white .marquee-iconic-box {
  margin-left: 20px;
  background: #2B2B2B;
  margin-bottom: 20px;
  border-color: rgba(255, 255, 255, 0.1); }
  .iconic-slider-left.text-white .marquee-iconic-box .subtitle {
    opacity: 0.35; }

.iconic-slider-left.style-three .marquee-iconic-box {
  border-radius: 12px;
  padding: 10px 20px 10px 15px; }
  @media only screen and (min-width: 992px) {
    .iconic-slider-left.style-three .marquee-iconic-box {
      margin-left: 50px;
      margin-bottom: 30px; } }
  .iconic-slider-left.style-three .marquee-iconic-box .image {
    max-width: 35px;
    margin-right: 15px; }
    .iconic-slider-left.style-three .marquee-iconic-box .image img {
      -webkit-box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.5);
      box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.5); }
  .iconic-slider-left.style-three .marquee-iconic-box .title {
    font-size: 16px; }

.marquee-iconic-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: white;
  border-radius: 50px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 35px 10px 10px;
  border: 1px solid var(--border-color);
  -webkit-box-shadow: 0px 10px 60px rgba(185, 102, 231, 0.2);
  box-shadow: 0px 10px 60px rgba(185, 102, 231, 0.2); }
  .marquee-iconic-box .icon {
    color: white;
    font-size: 35px;
    margin-right: 20px;
    width: 60px;
    height: 60px;
    background: var(--primary-color);
    line-height: 60px;
    border-radius: 50%;
    text-align: center; }
    @media only screen and (max-width: 991px) {
      .marquee-iconic-box .icon {
        width: 45px;
        height: 45px;
        font-size: 25px;
        line-height: 45px;
        margin-right: 10px; } }
  .marquee-iconic-box .image {
    max-width: 60px;
    margin-right: 20px; }
    @media only screen and (max-width: 991px) {
      .marquee-iconic-box .image {
        max-width: 45px;
        margin-right: 10px; } }
    .marquee-iconic-box .image img {
      border-radius: 50%; }
  .marquee-iconic-box .title {
    margin-bottom: 0;
    letter-spacing: -0.54px; }
    @media only screen and (min-width: 992px) {
      .marquee-iconic-box .title {
        font-size: 18px; } }
  @media only screen and (max-width: 991px) {
    .marquee-iconic-box .subtitle {
      display: block;
      font-size: 14px;
      margin-top: -5px; } }

/*******************************************************/
/********************  ## Accordion *******************/
/*******************************************************/
.accordion-item {
  z-index: 1;
  border: none;
  position: relative;
  margin-bottom: 15px;
  background-color: transparent; }
  .accordion-item:first-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
    .accordion-item:first-of-type .accordion-button {
      border-top-left-radius: 14px;
      border-top-right-radius: 14px; }
  .accordion-item:last-of-type {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
    .accordion-item:last-of-type .accordion-button {
      border-bottom-left-radius: 14px;
      border-bottom-right-radius: 14px; }
      .accordion-item:last-of-type .accordion-button.collapsed {
        border-bottom-left-radius: 14px;
        border-bottom-right-radius: 14px; }
  .accordion-item .accordion-button {
    font-size: 20px;
    line-height: 1.3;
    -webkit-box-shadow: none;
    box-shadow: none;
    background: white;
    position: initial;
    padding: 20px 30px;
    border-radius: 14px;
    letter-spacing: -0.4px;
    text-decoration: underline;
    border: 1.25px solid white; }
    @media only screen and (max-width: 479px) {
      .accordion-item .accordion-button {
        font-size: 16px;
        padding: 15px 20px; } }
    .accordion-item .accordion-button:after {
      -webkit-box-flex: 0;
      -ms-flex: none;
      flex: none;
      width: auto;
      height: auto;
      float: right;
      font-size: 18px;
      font-weight: 400;
      content: "\f056";
      color: var(--primary-color);
      background: transparent;
      font-family: "Font Awesome 5 Pro"; }
    .accordion-item .accordion-button:before {
      position: absolute;
      left: 0;
      bottom: 0;
      content: '';
      width: 100%;
      height: 100%;
      z-index: -1;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      background: white;
      border-radius: 14px;
      -webkit-box-shadow: 0px 10px 60px rgba(185, 102, 231, 0.2);
      box-shadow: 0px 10px 60px rgba(185, 102, 231, 0.2); }
    .accordion-item .accordion-button.collapsed {
      background: white;
      border-radius: 15px;
      text-decoration: none;
      border-color: var(--border-color); }
      .accordion-item .accordion-button.collapsed:after {
        content: "\f055";
        color: var(--heading-color); }
      .accordion-item .accordion-button.collapsed:before {
        opacity: 0; }
  .accordion-item .accordion-collapse .accordion-body {
    margin-top: -5px;
    padding: 0 30px 25px; }
    @media only screen and (max-width: 479px) {
      .accordion-item .accordion-collapse .accordion-body {
        padding: 0 20px 20px; } }
    .accordion-item .accordion-collapse .accordion-body p:last-child {
      margin-bottom: 0; }
  .accordion-item .accordion-collapse:before {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    content: '';
    z-index: -2;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
    width: 100%;
    height: 50px;
    border-radius: 14px;
    background: var(--primary-color); }
  .accordion-item .accordion-collapse.show:before {
    top: -4px;
    opacity: 1; }

.style-two .accordion-item {
  margin-bottom: 0;
  padding: 15px 0 15px 40px;
  border-left: 3px solid var(--border-color); }
  @media only screen and (max-width: 479px) {
    .style-two .accordion-item {
      padding-left: 20px; } }
  .style-two .accordion-item .accordion-button {
    padding: 0;
    border: none;
    text-decoration: none;
    background: transparent; }
    .style-two .accordion-item .accordion-button:after {
      content: "\f054";
      margin-left: 40px;
      color: var(--heading-color);
      -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg); }
    @media only screen and (min-width: 480px) {
      .style-two .accordion-item .accordion-button {
        font-size: 18px; } }
    .style-two .accordion-item .accordion-button:before {
      display: none; }
    .style-two .accordion-item .accordion-button.collapsed:after {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg); }
  .style-two .accordion-item .accordion-collapse .accordion-body {
    padding: 25px 0 0; }
  .style-two .accordion-item .accordion-collapse:before {
    top: 0;
    left: -3px;
    width: 3px;
    height: 100%;
    border-radius: 0; }

.faq-left-content .theme-btn.style-three:not(:hover) {
  color: var(--heading-color);
  border-color: var(--border-color); }

.faq-content-four .accordion-item .accordion-collapse:before {
  background: var(--secondary-color); }

.faq-images-part {
  max-width: 520px; }
  .faq-images-part .image {
    margin-bottom: 20px; }
    .faq-images-part .image img {
      border-radius: 12px; }

/* FAQ Page */
.faq-page .accordion-item .accordion-button {
  background: var(--lighter-color); }
  .faq-page .accordion-item .accordion-button:before {
    background: var(--lighter-color);
    border: 1px solid var(--border-color); }
  .faq-page .accordion-item .accordion-button:not(.collapsed) {
    border-color: var(--lighter-color);
    border-radius: 14px 14px 0 0;
    border-left-color: var(--border-color);
    border-right-color: var(--border-color); }

/* Home Sevent */
.faqs-area-seven .accordion-item .accordion-button {
  background: var(--lighter-color); }
  .faqs-area-seven .accordion-item .accordion-button:before {
    background: var(--lighter-color); }
  .faqs-area-seven .accordion-item .accordion-button:after {
    color: var(--heading-color); }

.faqs-area-seven-shapes .shape {
  position: absolute;
  z-index: -1;
  max-width: 10%; }
  .faqs-area-seven-shapes .shape.one {
    position: absolute;
    left: 13%;
    top: 32%; }
  .faqs-area-seven-shapes .shape.two {
    position: absolute;
    right: 17%;
    top: 31%; }
  .faqs-area-seven-shapes .shape.three {
    position: absolute;
    left: 6%;
    top: 52%; }
  .faqs-area-seven-shapes .shape.four {
    position: absolute;
    right: 6%;
    top: 51%; }
  .faqs-area-seven-shapes .shape.five {
    position: absolute;
    left: 12%;
    bottom: 15%; }
  .faqs-area-seven-shapes .shape.six {
    position: absolute;
    right: 13%;
    bottom: 13%; }

/*******************************************************/
/******************* ## Blockquote *******************/
/*******************************************************/
.blockquote-one {
  text-align: center; }
  .blockquote-one .text {
    font-size: 22px;
    font-weight: 500;
    line-height: 1.6;
    margin-bottom: 15px;
    letter-spacing: -0.66px; }
    @media only screen and (max-width: 767px) {
      .blockquote-one .text {
        font-size: 18px; } }
    .blockquote-one .text:after, .blockquote-one .text:before {
      content: "";
      line-height: 0;
      font-size: 28px;
      display: inline-block;
      font-family: "flaticon_akpager"; }
    .blockquote-one .text:before {
      -webkit-transform: rotate(180deg) translate(10px, 10px);
      -ms-transform: rotate(180deg) translate(10px, 10px);
      transform: rotate(180deg) translate(10px, 10px); }
    .blockquote-one .text:after {
      -webkit-transform: translate(10px, 20px);
      -ms-transform: translate(10px, 20px);
      transform: translate(10px, 20px); }
  .blockquote-one .author {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
    .blockquote-one .author img {
      width: 55px;
      height: 55px;
      margin: 10px 20px;
      border-radius: 50%; }
    .blockquote-one .author .name {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center; }
      .blockquote-one .author .name h5 {
        margin: 0 10px 0 0; }

.blockquote-two {
  position: relative;
  border-radius: 12px;
  padding: 28px 50px 30px 130px; }
  @media only screen and (max-width: 575px) {
    .blockquote-two {
      padding-left: 80px;
      padding-right: 30px; } }
  @media only screen and (max-width: 375px) {
    .blockquote-two {
      padding-left: 30px; } }
  .blockquote-two .text {
    font-size: 27px;
    line-height: 1.2;
    letter-spacing: -0.81px; }
    @media only screen and (max-width: 575px) {
      .blockquote-two .text {
        font-size: 22px;
        letter-spacing: -0.4px; } }
    @media only screen and (max-width: 479px) {
      .blockquote-two .text {
        font-size: 18px;
        line-height: 1.4; } }
  .blockquote-two .blockquote-footer {
    margin-top: 25px;
    margin-bottom: 0;
    display: inline-block; }
    .blockquote-two .blockquote-footer:before {
      content: '——— ';
      margin-right: 25px;
      letter-spacing: -3px; }
  .blockquote-two:before {
    line-height: 1;
    font-size: 45px;
    content: "\f10e";
    font-weight: 300;
    position: absolute;
    left: 50px;
    top: 33px;
    color: var(--heading-color);
    font-family: "Font Awesome 5 Pro"; }
    @media only screen and (max-width: 575px) {
      .blockquote-two:before {
        left: 25px;
        font-size: 35px; } }
    @media only screen and (max-width: 375px) {
      .blockquote-two:before {
        display: none; } }

.blockquote-three {
  border-radius: 12px;
  padding: 40px 60px 45px; }
  @media only screen and (max-width: 767px) {
    .blockquote-three {
      padding-left: 35px;
      padding-right: 35px; } }
  .blockquote-three .text {
    font-size: 30px;
    line-height: 1.33;
    margin-bottom: 50px; }
    @media only screen and (max-width: 767px) {
      .blockquote-three .text {
        font-size: 25px; } }
    @media only screen and (max-width: 479px) {
      .blockquote-three .text {
        font-size: 20px;
        line-height: 1.5; } }
    .blockquote-three .text:after {
      content: "";
      line-height: 0;
      font-size: 55px;
      display: inline-block;
      font-family: flaticon_akpager;
      -webkit-transform: translate(10px, 30px);
      -ms-transform: translate(10px, 30px);
      transform: translate(10px, 30px); }
      @media only screen and (max-width: 479px) {
        .blockquote-three .text:after {
          font-size: 35px; } }
  .blockquote-three .name {
    font-size: 20px;
    margin-bottom: 0; }
  .blockquote-three.text-white .text {
    color: white; }

/*******************************************************/
/******************** ## Coming Soon ********************/
/*******************************************************/
.coming-soon-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.coming-soon-content h1 {
  line-height: 1.1;
  margin-bottom: 25px; }
  @media only screen and (max-width: 1199px) {
    .coming-soon-content h1 {
      font-size: 60px; } }
  @media only screen and (max-width: 575px) {
    .coming-soon-content h1 {
      font-size: 50px; } }
  @media only screen and (max-width: 479px) {
    .coming-soon-content h1 {
      font-size: 45px; } }
  @media only screen and (max-width: 375px) {
    .coming-soon-content h1 {
      font-size: 38px; } }

.coming-soon-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px; }
  .coming-soon-wrap li {
    margin: 10px;
    line-height: 1;
    background: white;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 15px;
    padding: 10px 15px 25px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: calc(25% - 20px); }
    @media only screen and (max-width: 575px) {
      .coming-soon-wrap li {
        width: calc(50% - 20px); } }
    .coming-soon-wrap li span {
      font-size: 65px;
      color: var(--primary-color); }
      @media only screen and (max-width: 1199px) {
        .coming-soon-wrap li span {
          font-size: 55px; } }

/*******************************************************/
/********************** ## Error **********************/
/*******************************************************/
.error-content {
  text-align: center; }
  .error-content .image {
    margin-bottom: 55px; }
  .error-content h1 {
    margin-bottom: 25px; }
    @media only screen and (max-width: 767px) {
      .error-content h1 {
        font-size: 65px; } }
    @media only screen and (max-width: 575px) {
      .error-content h1 {
        font-size: 55px; } }
    @media only screen and (max-width: 479px) {
      .error-content h1 {
        font-size: 45px; } }
    @media only screen and (max-width: 375px) {
      .error-content h1 {
        font-size: 40px; } }
  .error-content .newsletter-form {
    margin-top: 45px;
    position: relative; }
    .error-content .newsletter-form label {
      margin-left: 0;
      position: absolute;
      left: 25px;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%); }
    .error-content .newsletter-form input {
      background: var(--lighter-color);
      border: 1px solid var(--border-color); }
      @media only screen and (min-width: 376px) {
        .error-content .newsletter-form input {
          padding-left: 55px; } }

/*******************************************************/
/******************* ## Header style *******************/
/*******************************************************/
.main-header {
  position: relative;
  left: 0px;
  top: 0px;
  z-index: 999;
  width: 100%;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease; }
  .main-header .header-upper {
    z-index: 5;
    width: 100%;
    position: relative;
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease; }
  .main-header .header-inner {
    border-bottom: 1px solid var(--border-color); }
  .main-header .logo-outer {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none; }
    @media only screen and (max-width: 991px) {
      .main-header .logo-outer {
        display: none; } }
  .main-header .logo {
    z-index: 9;
    padding: 2px 0;
    position: relative; }
  .main-header.menu-absolute .header-upper {
    position: absolute; }
  .main-header.fixed-header .header-upper {
    top: 0;
    left: 0;
    position: fixed;
    background: white;
    -webkit-animation: sticky  1s;
    animation: sticky  1s;
    -webkit-box-shadow: 0px 0px 30px 0px rgba(87, 95, 245, 0.1);
    box-shadow: 0px 0px 30px 0px rgba(87, 95, 245, 0.1); }
  .main-header.fixed-header .header-inner {
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: none; }
  @media only screen and (min-width: 992px) {
    .main-header.fixed-header .main-menu .navbar-collapse > ul > li {
      padding-top: 25px;
      padding-bottom: 25px; } }
  .main-header.no-border:not(.fixed-header) .header-inner {
    border-bottom: none; }

@media only screen and (max-width: 991px) {
  .nav-outer {
    width: 100%; } }

/** Header Main Menu **/
@media only screen and (max-width: 991px) {
  .main-menu {
    width: 100%; } }

.main-menu .mobile-logo {
  margin-right: auto; }
  @media only screen and (max-width: 575px) {
    .main-menu .mobile-logo {
      max-width: 150px; } }

@media only screen and (max-width: 991px) {
  .main-menu .collapse {
    overflow: auto; } }

.main-menu .navbar-collapse {
  padding: 0px; }
  .main-menu .navbar-collapse > ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    @media only screen and (max-width: 991px) {
      .main-menu .navbar-collapse > ul {
        display: block;
        padding: 25px 0;
        background: white;
        -webkit-box-shadow: inset 0px 0px 30px 0px rgba(87, 95, 245, 0.1);
        box-shadow: inset 0px 0px 30px 0px rgba(87, 95, 245, 0.1); }
        .main-menu .navbar-collapse > ul > li:last-child {
          border-bottom: 1px solid var(--border-color); } }
  @media only screen and (max-width: 991px) {
    .main-menu .navbar-collapse {
      left: 0;
      width: 100%;
      position: absolute;
      max-height: calc(100vh - 80px); } }
  .main-menu .navbar-collapse li {
    padding: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }
    @media only screen and (max-width: 1399px) {
      .main-menu .navbar-collapse li {
        padding-left: 10px;
        padding-right: 10px; } }
    @media only screen and (max-width: 991px) {
      .main-menu .navbar-collapse li {
        display: block;
        padding: 0 15px;
        border-top: 1px solid var(--border-color); } }
    .main-menu .navbar-collapse li.dropdown .dropdown-btn {
      cursor: pointer;
      margin-left: 5px;
      font-weight: 600;
      margin-bottom: -2px;
      color: var(--heading-color); }
      @media only screen and (max-width: 991px) {
        .main-menu .navbar-collapse li.dropdown .dropdown-btn {
          position: absolute;
          right: 10px;
          top: 0;
          width: 50px;
          height: 43px;
          border-left: 1px solid var(--border-color);
          text-align: center;
          line-height: 43px; } }
    .main-menu .navbar-collapse li a {
      opacity: 1;
      display: block;
      font-size: 18px;
      position: relative;
      text-transform: capitalize;
      -webkit-transition: all 500ms ease;
      -o-transition: all 500ms ease;
      transition: all 500ms ease; }
      @media only screen and (max-width: 991px) {
        .main-menu .navbar-collapse li a {
          padding: 10px 10px;
          line-height: 22px; } }
      .main-menu .navbar-collapse li a:hover {
        color: var(--primary-color); }
    .main-menu .navbar-collapse li.current > a, .main-menu .navbar-collapse li.current-menu-item > a {
      font-weight: 600; }
    .main-menu .navbar-collapse li li {
      border-top: 1px solid var(--border-color); }
      .main-menu .navbar-collapse li li a {
        text-transform: capitalize; }
        .main-menu .navbar-collapse li li a:before {
          display: none; }
    .main-menu .navbar-collapse li .megamenu {
      position: absolute;
      left: 0px;
      top: 100%;
      width: 100%;
      z-index: 100;
      display: none;
      padding: 20px 0;
      background: #ffffff;
      -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
      box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05); }
      .main-menu .navbar-collapse li .megamenu:after {
        display: block;
        clear: both;
        content: ""; }
      @media only screen and (max-width: 991px) {
        .main-menu .navbar-collapse li .megamenu {
          position: relative;
          -webkit-box-shadow: none;
          box-shadow: none;
          width: 100%; }
          .main-menu .navbar-collapse li .megamenu .container {
            max-width: 100%; }
          .main-menu .navbar-collapse li .megamenu .row {
            margin: 0px; } }
      .main-menu .navbar-collapse li .megamenu ul {
        display: block;
        position: relative;
        top: 0;
        width: 100%;
        -webkit-box-shadow: none;
        box-shadow: none; }
    .main-menu .navbar-collapse li ul {
      position: absolute;
      left: inherit;
      top: 100%;
      min-width: 250px;
      z-index: 100;
      display: none;
      background: white;
      -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
      box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05); }
      @media only screen and (max-width: 991px) {
        .main-menu .navbar-collapse li ul {
          position: relative;
          display: none;
          width: 100%;
          -webkit-box-shadow: none;
          box-shadow: none;
          background: transparent; }
          .main-menu .navbar-collapse li ul:after {
            display: block;
            clear: both;
            content: ""; } }
  @media only screen and (max-width: 991px) and (max-width: 375px) {
    .main-menu .navbar-collapse li ul {
      min-width: auto; } }
      .main-menu .navbar-collapse li ul li {
        width: 100%;
        padding: 7px 20px; }
        @media only screen and (max-width: 991px) {
          .main-menu .navbar-collapse li ul li {
            padding: 0 15px; } }
        .main-menu .navbar-collapse li ul li ul {
          left: 100%;
          top: 0%; }
          @media only screen and (max-width: 991px) {
            .main-menu .navbar-collapse li ul li ul {
              left: auto; } }

.main-menu .navbar-header {
  display: none; }
  @media only screen and (max-width: 991px) {
    .main-menu .navbar-header {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: start; } }
  .main-menu .navbar-header .navbar-toggle {
    float: right;
    padding: 4px 0;
    cursor: pointer;
    background: transparent; }
    .main-menu .navbar-header .navbar-toggle .icon-bar {
      background: var(--base-color);
      height: 2px;
      width: 30px;
      display: block;
      margin: 7px 0; }

/* Menu Btns */
.menu-btns {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .menu-btns button {
    color: white;
    cursor: pointer;
    margin-left: 33px;
    background-color: transparent; }
    @media only screen and (max-width: 375px) {
      .menu-btns button.cart {
        display: none; } }
  .menu-btns .light-btn {
    font-size: 18px; }
    .menu-btns .light-btn i {
      font-size: 0.8em;
      margin-left: 9px;
      -webkit-transform: rotate(-30deg);
      -ms-transform: rotate(-30deg);
      transform: rotate(-30deg); }
    @media only screen and (max-width: 991px) {
      .menu-btns .light-btn {
        display: none; } }
  .menu-btns .theme-btn {
    margin-left: 33px; }
    @media only screen and (max-width: 1199px) {
      .menu-btns .theme-btn {
        display: none; } }

/* White Menu */
@media only screen and (min-width: 992px) {
  .main-header.header-white .navbar-collapse > ul > li > a,
  .main-header.header-white .navbar-collapse > ul > li > .dropdown-btn {
    color: white; } }

.main-header.header-white .nav-search > button {
  color: white; }

.main-header.header-white .navbar-toggle .icon-bar {
  background: white; }

.main-header.header-white .light-btn {
  color: white;
  text-decoration: underline; }

.main-header.header-white.fixed-header .header-upper {
  background: var(--navyblue-color); }

/* Header Search */
.nav-search {
  font-size: 16px;
  position: relative; }
  .nav-search > button {
    background: transparent; }
  .nav-search form {
    position: absolute;
    width: 320px;
    top: 100%;
    right: 0;
    z-index: 777;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #fff;
    -webkit-animation: fadeIn 0.5s;
    animation: fadeIn 0.5s;
    -webkit-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.05); }
    @media only screen and (max-width: 375px) {
      .nav-search form {
        width: 275px; } }
    .nav-search form.hide {
      display: none; }
    .nav-search form input {
      border: none;
      background: transparent;
      padding: 15px 5px 15px 25px; }
      .nav-search form input::-webkit-input-placeholder {
        color: var(--base-color); }
      .nav-search form input:-ms-input-placeholder {
        color: var(--base-color); }
      .nav-search form input::-ms-input-placeholder {
        color: var(--base-color); }
      .nav-search form input::placeholder {
        color: var(--base-color); }
    .nav-search form button {
      background: transparent;
      padding: 15px 20px 15px 0; }

/** hidden-sidebar * */
.menu-sidebar > button {
  background: transparent; }

.hidden-bar {
  position: fixed;
  left: -350px;
  top: 0px;
  opacity: 0;
  width: 350px;
  height: 100%;
  z-index: 99999;
  overflow-y: auto;
  visibility: hidden;
  background-color: #222222;
  border-left: 1px solid #231b26;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease; }
  @media only screen and (max-width: 375px) {
    .hidden-bar {
      width: 300px; } }
  .hidden-bar .social-style-one a {
    width: 40px;
    height: 40px;
    line-height: 40px; }

.side-content-visible .hidden-bar {
  left: 0px;
  opacity: 1;
  visibility: visible; }

.hidden-bar .inner-box {
  position: relative;
  padding: 100px 40px 50px; }
  @media only screen and (max-width: 375px) {
    .hidden-bar .inner-box {
      padding-left: 25px;
      padding-right: 25px; } }

.hidden-bar .cross-icon {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 1;
  cursor: pointer;
  color: #ffffff;
  font-size: 20px;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease; }

.hidden-bar h4 {
  position: relative;
  color: #ffffff;
  margin-bottom: 35px; }

/*Appointment Form */
.hidden-bar .appointment-form {
  position: relative; }
  .hidden-bar .appointment-form .form-group {
    position: relative;
    margin-bottom: 20px; }
  .hidden-bar .appointment-form input[type="text"], .hidden-bar .appointment-form input[type="email"] {
    position: relative;
    display: block;
    width: 100%;
    line-height: 23px;
    padding: 10px 25px;
    color: #ffffff;
    font-size: 16px;
    background: none;
    -webkit-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
    border: 1px solid rgba(255, 255, 255, 0.1); }
  .hidden-bar .appointment-form textarea {
    position: relative;
    display: block;
    width: 100%;
    line-height: 23px;
    padding: 10px 25px;
    color: #ffffff;
    font-size: 16px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: none;
    -webkit-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
    resize: none; }
  .hidden-bar .appointment-form input::-webkit-input-placeholder {
    font-size: 14px;
    color: #bdbdbd; }
  .hidden-bar .appointment-form input:-ms-input-placeholder {
    font-size: 14px;
    color: #bdbdbd; }
  .hidden-bar .appointment-form input::-ms-input-placeholder {
    font-size: 14px;
    color: #bdbdbd; }
  .hidden-bar .appointment-form input::placeholder {
    font-size: 14px;
    color: #bdbdbd; }
  .hidden-bar .appointment-form textarea::-webkit-input-placeholder {
    font-size: 14px;
    color: #bdbdbd; }
  .hidden-bar .appointment-form textarea:-ms-input-placeholder {
    font-size: 14px;
    color: #bdbdbd; }
  .hidden-bar .appointment-form textarea::-ms-input-placeholder {
    font-size: 14px;
    color: #bdbdbd; }
  .hidden-bar .appointment-form textarea::placeholder {
    font-size: 14px;
    color: #bdbdbd; }
  .hidden-bar .appointment-form .form-group button {
    width: 100%;
    font-size: 16px;
    padding: 10px 15px;
    margin-bottom: 20px;
    border-color: white; }

.form-back-drop {
  position: fixed;
  right: 100%;
  top: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: rgba(0, 0, 0, 0.7);
  visibility: hidden;
  z-index: 9990;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease; }

.side-content-visible .form-back-drop {
  opacity: 1;
  right: 0;
  visibility: visible; }

.header-top {
  padding-top: 7px;
  padding-bottom: 7px; }
  .header-top .top-left > ul,
  .header-top .top-right > ul {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-left: -20px;
    margin-right: -20px; }
    .header-top .top-left > ul > li,
    .header-top .top-right > ul > li {
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      margin-left: 20px;
      margin-right: 20px;
      color: rgba(255, 255, 255, 0.65); }
      .header-top .top-left > ul > li i,
      .header-top .top-right > ul > li i {
        margin-right: 10px;
        color: var(--primary-color); }
      .header-top .top-left > ul > li a,
      .header-top .top-right > ul > li a {
        color: rgba(255, 255, 255, 0.65); }
        .header-top .top-left > ul > li a:hover,
        .header-top .top-right > ul > li a:hover {
          color: white; }
    .header-top .top-left > ul .nice-select,
    .header-top .top-right > ul .nice-select {
      border: none;
      font-size: 16px;
      line-height: 1.5;
      padding: 0 25px 0 0;
      background: transparent; }
      .header-top .top-left > ul .nice-select .current,
      .header-top .top-right > ul .nice-select .current {
        color: white; }
      .header-top .top-left > ul .nice-select:after,
      .header-top .top-right > ul .nice-select:after {
        right: 8px;
        width: 8px;
        height: 8px;
        top: 50%;
        margin-top: -6px;
        border-color: white; }
      .header-top .top-left > ul .nice-select .list,
      .header-top .top-right > ul .nice-select .list {
        width: auto; }
        .header-top .top-left > ul .nice-select .list > li,
        .header-top .top-right > ul .nice-select .list > li {
          margin-bottom: 0; }
          .header-top .top-left > ul .nice-select .list > li:before,
          .header-top .top-right > ul .nice-select .list > li:before {
            display: none; }
  .header-top .top-left > ul > li a {
    color: white;
    margin-left: 5px;
    text-decoration: underline; }
  @media only screen and (max-width: 1199px) {
    .header-top .top-left > ul > li span {
      display: none; } }
  .header-top .top-middle {
    color: white;
    font-weight: 600; }
  @media only screen and (max-width: 575px) {
    .header-top .top-right {
      display: none; } }
  .header-top .top-right .social-icons a i {
    color: white; }
  .header-top .top-right .social-icons a:not(:last-child) {
    margin-right: 5px; }

.bgc-primary .top-left ul li i {
  color: white;
  margin-right: 0;
  margin-left: 10px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg); }

/* Header Style Two */
.main-header.style-two .main-logo {
  max-width: 40%; }

/* Sidebar Menu */
.sidebar-menu li {
  color: white;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
  .sidebar-menu li:first-child {
    border-top: 1px solid rgba(255, 255, 255, 0.1); }
  .sidebar-menu li a {
    color: white;
    font-size: 18px;
    font-weight: 500;
    padding: 6px 20px;
    display: inline-block;
    text-transform: capitalize; }
  .sidebar-menu li.dropdown > ul {
    display: none; }
  .sidebar-menu li.dropdown .dropdown-btn {
    position: absolute;
    right: 10px;
    top: 0;
    width: 45px;
    height: 43px;
    cursor: pointer;
    line-height: 43px;
    text-align: center;
    border-left: 1px solid rgba(255, 255, 255, 0.1); }
  .sidebar-menu li ul {
    margin-left: 10px; }

/*******************************************************/
/******************** ## Hero Area *********************/
/*******************************************************/
.hero-content h1 {
  margin-bottom: 32px;
  letter-spacing: -2.2px; }
  @media only screen and (max-width: 991px) {
    .hero-content h1 {
      font-size: 60px; } }
  @media only screen and (max-width: 767px) {
    .hero-content h1 {
      font-size: 50px; } }
  @media only screen and (max-width: 575px) {
    .hero-content h1 {
      font-size: 40px;
      line-height: 1.2;
      margin-bottom: 15px;
      letter-spacing: -1px; } }
  @media only screen and (max-width: 375px) {
    .hero-content h1 {
      font-size: 35px; } }

.hero-content .hero-btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: 10px;
  margin-right: 10px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }
  .hero-content .hero-btns .theme-btn {
    margin: 10px 10px 0; }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content.style-two h1 {
    font-size: 60px; } }

.hero-content.style-two h1 span {
  line-height: 1;
  display: inline-block;
  color: var(--heading-color);
  padding: 0 8px 10px 5px;
  background: var(--secondary-color); }

.hero-content.style-two p {
  line-height: 1.95;
  margin-bottom: 42px; }
  @media only screen and (min-width: 480px) {
    .hero-content.style-two p {
      font-size: 18px; } }

@media only screen and (min-width: 992px) {
  .hero-content.style-three h1 {
    font-size: 70px; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content.style-three h1 {
    font-size: 55px; } }

.hero-content.style-three h1 span {
  color: var(--primary-color); }

.hero-content.style-three .icon-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .hero-content.style-three .icon-list li {
    font-weight: 500;
    margin: 15px 0 0; }
    .hero-content.style-three .icon-list li i {
      width: 25px;
      height: 25px;
      font-size: 12px;
      margin-top: -2px;
      line-height: 25px;
      background: var(--navyblue-color); }
    .hero-content.style-three .icon-list li:not(:last-child) {
      margin-right: 25px; }

@media only screen and (min-width: 1200px) {
  .hero-content.style-four h1 {
    font-size: 90px; } }

.hero-content.style-four h1 span {
  line-height: 0.95;
  padding: 0 7px 6px;
  border-radius: 10px;
  display: inline-block;
  background: var(--primary-color); }

.hero-content.style-four .icon-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -28px;
  margin-right: -28px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }
  .hero-content.style-four .icon-list li {
    color: white;
    margin-left: 28px;
    margin-right: 28px; }
    .hero-content.style-four .icon-list li i {
      margin-top: 0; }

@media only screen and (min-width: 576px) {
  .hero-content.style-five p {
    font-size: 18px; } }

/* Hero Section Two */
.hero-area-two {
  z-index: 1;
  overflow: hidden;
  position: relative; }
  .hero-area-two:after {
    z-index: -1;
    content: '';
    width: 600px;
    height: 600px;
    position: absolute;
    right: -300px;
    bottom: -300px;
    -webkit-filter: blur(300px);
    filter: blur(300px);
    background: var(--primary-color);
    -webkit-animation: 10s zoomInOut infinite;
    animation: 10s zoomInOut infinite; }

.clients-reviews {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .clients-reviews h6 {
    margin-bottom: 15px; }
  .clients-reviews .clients {
    margin-top: 30px;
    margin-right: auto; }
    .clients-reviews .clients .images {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
      .clients-reviews .clients .images img {
        width: 46px;
        height: 46px;
        border-radius: 50%; }
        .clients-reviews .clients .images img:not(:first-child) {
          margin-left: -10px; }
  .clients-reviews .reviews {
    margin-top: 30px;
    margin-right: auto; }
    .clients-reviews .reviews .ratting {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
      .clients-reviews .reviews .ratting i {
        width: 26px;
        height: 26px;
        color: white;
        font-size: 12px;
        margin: 0 5px 0 0;
        line-height: 26px;
        text-align: center;
        background: #03B67C; }

.hero-form {
  padding: 60px;
  background: white;
  border-radius: 20px;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(155, 155, 155, 0.2);
  box-shadow: 0px 10px 60px 0px rgba(155, 155, 155, 0.2); }
  @media only screen and (max-width: 479px) {
    .hero-form {
      padding-left: 25px;
      padding-right: 25px; } }
  .hero-form h4 {
    margin-bottom: 0;
    margin-top: -8px; }
  .hero-form p {
    margin-bottom: 26px; }
  .hero-form .input-radio {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start; }
    .hero-form .input-radio input {
      margin-top: 10px;
      margin-right: 10px; }
    .hero-form .input-radio label a {
      color: var(--primary-color);
      text-decoration: underline; }

.hero-form-bg {
  width: 90%;
  z-index: -1;
  position: absolute;
  left: 18%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); }

/* Hero Section Three */
.hero-three-image {
  border-radius: 12px; }
  @media only screen and (min-width: 1600px) {
    .hero-three-image {
      margin-right: -25%; } }

/* Hero Shapes */
.hero-shapes .shape {
  z-index: -1;
  position: absolute;
  -webkit-animation: 10s zoomInOut infinite;
  animation: 10s zoomInOut infinite; }
  .hero-shapes .shape.one {
    left: 2%;
    bottom: 20%; }
  .hero-shapes .shape.two {
    right: 2%;
    top: 20%;
    -webkit-animation-delay: 5s;
    animation-delay: 5s; }

/* Hero Section Five */
.hero-bg-wave-shapes {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 170%;
  background: var(--lighter-color);
  -webkit-clip-path: polygon(100% 0, 100% 85%, 30% 100%, 0 85%, 0 0);
  clip-path: polygon(100% 0, 100% 85%, 30% 100%, 0 85%, 0 0); }
  @media only screen and (max-width: 1199px) {
    .hero-bg-wave-shapes {
      height: 140%; } }
  @media only screen and (max-width: 767px) {
    .hero-bg-wave-shapes {
      height: 120%; } }
  @media only screen and (max-width: 375px) {
    .hero-bg-wave-shapes {
      height: 115%; } }
  .hero-bg-wave-shapes:after, .hero-bg-wave-shapes:before,
  .hero-bg-wave-shapes .circle:after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    opacity: 0.05;
    border-radius: 50%;
    background: var(--primary-color);
    -webkit-transform: translate(-50%, 50%);
    -ms-transform: translate(-50%, 50%);
    transform: translate(-50%, 50%); }
  .hero-bg-wave-shapes:before {
    width: 90%;
    padding-bottom: 90%; }
  .hero-bg-wave-shapes:after {
    width: 75%;
    padding-bottom: 75%; }
  .hero-bg-wave-shapes .circle:after {
    width: 60%;
    padding-bottom: 60%; }

.hero-image-area img {
  border-radius: 10px;
  -webkit-box-shadow: 0px 7px 60px 0px rgba(8, 193, 106, 0.1);
  box-shadow: 0px 7px 60px 0px rgba(8, 193, 106, 0.1); }

.hero-image-area.style-two img {
  border-radius: 30px 30px 0 0; }

/* Hero Section Six */
.hero-area-six .hero-bg-shapes {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: white;
  border-radius: 60px 60px 0 0; }
  @media only screen and (max-width: 1599px) {
    .hero-area-six .hero-bg-shapes {
      border-radius: 30px 30px 0 0; } }
  .hero-area-six .hero-bg-shapes .shape {
    z-index: -1;
    max-width: 20%;
    position: absolute; }
    .hero-area-six .hero-bg-shapes .shape.one {
      left: 5%;
      top: 40%;
      -webkit-animation: down-up-one 10s infinite;
      animation: down-up-one 10s infinite; }
    .hero-area-six .hero-bg-shapes .shape.two {
      top: 35%;
      right: 6%;
      -webkit-animation: down-up-two 5s infinite;
      animation: down-up-two 5s infinite; }

.hero-area-six .hero-content.style-five h1 span {
  color: var(--primary-color); }

/* Hero Seven */
@media only screen and (min-width: 1300px) and (max-width: 1599px) {
  .hero-content.style-seven h1 {
    font-size: 64px; } }

@media only screen and (min-width: 992px) and (max-width: 1299px) {
  .hero-content.style-seven h1 {
    font-size: 48px; } }

@media only screen and (min-width: 576px) {
  .hero-content.style-seven p {
    font-size: 18px; } }

.hero-seven-image {
  text-align: right;
  margin-bottom: -30px; }
  @media only screen and (min-width: 992px) {
    .hero-seven-image {
      margin-right: -30px; } }

/* Hero Nine * */
.hero-nine-shapes {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  overflow: hidden;
  width: 100%;
  height: calc(100% + 200px);
  background: -webkit-linear-gradient(209deg, #8F00FF 2.36%, rgba(255, 255, 255, 0.3) 40.01%, rgba(252, 120, 11, 0.2) 65.07%, rgba(59, 11, 252, 0.8) 107.82%);
  background: -o-linear-gradient(209deg, #8F00FF 2.36%, rgba(255, 255, 255, 0.3) 40.01%, rgba(252, 120, 11, 0.2) 65.07%, rgba(59, 11, 252, 0.8) 107.82%);
  background: linear-gradient(241deg, #8F00FF 2.36%, rgba(255, 255, 255, 0.3) 40.01%, rgba(252, 120, 11, 0.2) 65.07%, rgba(59, 11, 252, 0.8) 107.82%); }
  .hero-nine-shapes:after {
    content: '';
    z-index: -2;
    width: 115%;
    height: 100%;
    background: white;
    border-radius: 50%;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
    position: absolute;
    left: 50%;
    top: calc(100% - 350px); }
    @media only screen and (max-width: 991px) {
      .hero-nine-shapes:after {
        height: 66%; } }
    @media only screen and (max-width: 767px) {
      .hero-nine-shapes:after {
        height: 40%; } }
  .hero-nine-shapes .shape {
    z-index: -1;
    max-width: 20%;
    position: absolute; }
    .hero-nine-shapes .shape.one {
      left: 6%;
      top: 25%; }
    .hero-nine-shapes .shape.two {
      left: -3%;
      bottom: 22%; }

@media only screen and (min-width: 1400px) {
  .hero-content.style-nine h1 {
    font-size: 70px; } }

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero-content.style-nine h1 {
    font-size: 60px; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content.style-nine h1 {
    font-size: 55px; } }

.hero-content.style-nine p {
  font-size: 18px;
  font-weight: 500; }

.hero-content.style-nine .newsletter-form {
  max-width: 575px; }
  .hero-content.style-nine .newsletter-form button {
    background: var(--primary-color); }

.hero-content.style-nine .icon-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -25px; }
  .hero-content.style-nine .icon-list li {
    margin-bottom: 0;
    margin-top: 15px;
    margin-right: 25px; }
    .hero-content.style-nine .icon-list li i {
      color: var(--heading-color); }

.hero-nine-image {
  z-index: 1;
  padding-top: 15%;
  position: relative;
  padding-left: 20%;
  padding-right: 20%; }
  @media only screen and (min-width: 1600px) {
    .hero-nine-image {
      margin-right: -10%; } }
  .hero-nine-image > img {
    border-radius: 42px;
    margin-bottom: -100px; }
    @media only screen and (max-width: 479px) {
      .hero-nine-image > img {
        border-radius: 15px; } }
  .hero-nine-image:after, .hero-nine-image:before {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    content: '';
    width: 100%;
    height: 87.6%;
    border-radius: 50%;
    border: 1px solid var(--border-color); }
  .hero-nine-image:after {
    -webkit-transform: scale(0.75);
    -ms-transform: scale(0.75);
    transform: scale(0.75); }
  .hero-nine-image .shapes .shape {
    z-index: -1;
    max-width: 15%;
    position: absolute; }
    .hero-nine-image .shapes .shape.one {
      top: 6%;
      left: 6%; }
    .hero-nine-image .shapes .shape.two {
      top: 3%;
      right: 11%; }
    .hero-nine-image .shapes .shape.three {
      top: 35%;
      left: -4%; }
    .hero-nine-image .shapes .shape.four {
      top: 30%;
      right: -3%; }
    .hero-nine-image .shapes .shape.five {
      top: 58%;
      left: -2%; }
    .hero-nine-image .shapes .shape.six {
      top: 52%;
      right: -3%; }

/*******************************************************/
/******************* ## Page Banner *******************/
/*******************************************************/
.banner-inner h1 {
  margin-bottom: 30px; }
  @media only screen and (max-width: 1199px) {
    .banner-inner h1 {
      font-size: 55px;
      margin-bottom: 20px; } }
  @media only screen and (max-width: 767px) {
    .banner-inner h1 {
      font-size: 40px;
      margin-bottom: 15px; } }
  @media only screen and (max-width: 575px) {
    .banner-inner h1 {
      font-size: 36px; } }

.banner-inner h2 {
  margin-bottom: 35px; }
  @media only screen and (max-width: 1199px) {
    .banner-inner h2 {
      font-size: 40px;
      margin-bottom: 20px; } }
  @media only screen and (max-width: 767px) {
    .banner-inner h2 {
      font-size: 35px; } }
  @media only screen and (max-width: 575px) {
    .banner-inner h2 {
      font-size: 30px; } }

.banner-inner .blog-meta {
  padding-top: 0;
  padding-bottom: 0;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }
  .banner-inner .blog-meta li a {
    color: white; }

.breadcrumb {
  padding: 0;
  margin: 0;
  font-size: 24px;
  background: transparent;
  text-transform: capitalize; }
  @media only screen and (max-width: 1199px) {
    .breadcrumb {
      font-size: 20px; } }
  @media only screen and (max-width: 575px) {
    .breadcrumb {
      font-size: 18px; } }
  @media only screen and (max-width: 375px) {
    .breadcrumb {
      font-size: 16px; } }
  .breadcrumb .breadcrumb-item {
    padding: 0;
    color: white;
    font-weight: 400;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
    .breadcrumb .breadcrumb-item a {
      color: white; }
    .breadcrumb .breadcrumb-item.active {
      text-decoration: underline;
      text-underline-offset: 3px;
      text-decoration-thickness: 1px; }
    .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
      margin-left: 15px;
      margin-right: 15px;
      -webkit-box-flex: 0;
      -ms-flex: none;
      flex: none;
      color: white;
      content: "\f105";
      font-family: "Font Awesome 5 Pro"; }

/*******************************************************/
/******************* ## About Area ********************/
/*******************************************************/
.about-images {
  position: relative;
  padding-right: 50px; }
  .about-images > img {
    border-radius: 15px; }
  .about-images .about-over {
    position: absolute;
    right: 0;
    bottom: 30px;
    max-width: 55%; }
    .about-images .about-over img {
      border-radius: 10px;
      -webkit-box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1); }

.about-content {
  max-width: 585px; }

.management-images {
  position: relative;
  padding-left: 25%; }
  .management-images > img {
    border-radius: 15px; }
  .management-images .management-over {
    position: absolute;
    left: 0;
    top: 50%;
    max-width: 40%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); }
    .management-images .management-over img {
      border-radius: 10px;
      -webkit-box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1); }

.management-bottom {
  position: relative; }
  .management-bottom .image img {
    max-width: 75%; }
  .management-bottom .author {
    z-index: 1; }
    .management-bottom .author img {
      border-radius: 50%; }
    .management-bottom .author.one {
      position: absolute;
      left: -1%;
      top: 12%;
      max-width: 65px; }
      .management-bottom .author.one:after {
        position: absolute;
        left: 82%;
        top: 82%;
        z-index: -1;
        content: url(../images/shapes/line1.png); }
    .management-bottom .author.two {
      position: absolute;
      right: -1%;
      top: 18%;
      max-width: 75px; }
      .management-bottom .author.two:before {
        z-index: -1;
        position: absolute;
        right: 70%;
        top: 64%;
        content: url(../images/shapes/line2.png); }
  .management-bottom .check-list-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    border-radius: 22px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 5px 20px 5px 5px; }
    @media only screen and (max-width: 767px) {
      .management-bottom .check-list-item {
        display: none; } }
    .management-bottom .check-list-item i {
      width: 35px;
      height: 35px;
      line-height: 35px;
      text-align: center;
      margin-right: 15px;
      border-radius: 50%; }
    .management-bottom .check-list-item h6 {
      margin-bottom: 0; }
    .management-bottom .check-list-item.one {
      top: 40%;
      left: -8%;
      background: #1BFFF1;
      -webkit-transform: rotate(-35deg);
      -ms-transform: rotate(-35deg);
      transform: rotate(-35deg); }
      .management-bottom .check-list-item.one i {
        color: white;
        background: var(--heading-color); }
      .management-bottom .check-list-item.one:after {
        z-index: -1;
        position: absolute;
        left: -25px;
        top: 110px;
        -webkit-transform: rotate(35deg);
        -ms-transform: rotate(35deg);
        transform: rotate(35deg);
        content: url(../images/shapes/line3.png); }
    .management-bottom .check-list-item.two {
      top: 62%;
      left: -4%;
      background: #F6E65C;
      -webkit-transform: rotate(-42deg);
      -ms-transform: rotate(-42deg);
      transform: rotate(-42deg); }
      .management-bottom .check-list-item.two i {
        background: white;
        color: var(--heading-color); }
    .management-bottom .check-list-item.three {
      top: 44%;
      right: -7%;
      background: white;
      -webkit-transform: rotate(-4.5deg);
      -ms-transform: rotate(-4.5deg);
      transform: rotate(-4.5deg); }
      .management-bottom .check-list-item.three i {
        color: white;
        background: #0095FF; }
      .management-bottom .check-list-item.three:before {
        z-index: -1;
        position: absolute;
        right: 50%;
        top: 70%;
        -webkit-transform: rotate(4.5deg);
        -ms-transform: rotate(4.5deg);
        transform: rotate(4.5deg);
        content: url(../images/shapes/line4.png); }
    .management-bottom .check-list-item.four {
      top: 68%;
      right: -7%;
      background: #FEC458;
      -webkit-transform: rotate(14deg);
      -ms-transform: rotate(14deg);
      transform: rotate(14deg); }
      .management-bottom .check-list-item.four i {
        background: white;
        color: var(--heading-color); }

.lead-monitoring-images {
  margin: 0 -2% 0 -10%; }

.customer-data-images {
  margin: -3% -5% -5% -15%; }
  @media only screen and (max-width: 1199px) {
    .customer-data-images {
      margin-left: -6.5%;
      margin-top: 50px; } }

@media only screen and (min-width: 1600px) {
  .how-to-manage-image {
    margin-left: -100px; } }

/* About Four */
.about-four-images {
  max-width: 515px; }
  .about-four-images .row.gap-20 {
    margin-left: -10px;
    margin-right: -10px; }
    .about-four-images .row.gap-20 > div {
      padding-left: 10px;
      padding-right: 10px; }
  .about-four-images img {
    width: 100%;
    margin-bottom: 20px; }

.about-four-content .about-four-btns > .theme-btn {
  margin-top: 10px;
  margin-right: 15px; }

.about-four-content .clients-reviews .clients {
  margin-top: 15px;
  margin-right: 15px; }
  .about-four-content .clients-reviews .clients img:not(:first-child) {
    margin-left: -15px; }
  .about-four-content .clients-reviews .clients .text {
    color: white;
    font-size: 12px;
    font-weight: 600;
    margin-left: -15px;
    width: 46px;
    height: 46px;
    background: var(--secondary-color);
    line-height: 46px;
    border-radius: 50%;
    text-align: center; }

.about-four-content .clients-reviews h6 {
  font-size: 18px;
  margin-bottom: 0;
  margin-top: 10px;
  color: var(--base-color); }

.become-a-member-images {
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -10%;
  position: relative;
  margin-bottom: -10%; }
  .become-a-member-images img {
    width: 23.33%;
    border-radius: 50%;
    margin: 0 10% 10% 0; }
  .become-a-member-images:after {
    content: '';
    width: 70%;
    height: 70%;
    position: absolute;
    left: 10%;
    top: 10%;
    z-index: -1;
    border-radius: 70px;
    background: -webkit-linear-gradient(356deg, rgba(176, 101, 231, 0.1) 13.66%, rgba(49, 87, 239, 0.1) 81.89%);
    background: -o-linear-gradient(356deg, rgba(176, 101, 231, 0.1) 13.66%, rgba(49, 87, 239, 0.1) 81.89%);
    background: linear-gradient(94deg, rgba(176, 101, 231, 0.1) 13.66%, rgba(49, 87, 239, 0.1) 81.89%); }

/* About Five */
.about-images-five img {
  max-width: 75%;
  border-radius: 15px;
  -webkit-box-shadow: 0px 0px 60px 0px rgba(8, 193, 106, 0.2);
  box-shadow: 0px 0px 60px 0px rgba(8, 193, 106, 0.2); }
  .about-images-five img:first-child {
    margin-right: 20%; }
  .about-images-five img:last-child {
    margin-top: -15%;
    margin-left: 25%; }

.numbered-boxes .numbered-box.style-two {
  margin-bottom: 20px; }

/* Home Six */
.ai-content .subtitle {
  font-weight: 600;
  margin-bottom: 5px;
  display: inline-block;
  color: var(--primary-color); }

.ai-content h3 {
  margin-bottom: 25px; }

.ai-content .theme-btn.style-three {
  color: var(--base-color);
  border-color: var(--border-color); }
  .ai-content .theme-btn.style-three:hover {
    color: white; }

/* Home Seven */
.about-seven-images {
  padding: 45px;
  text-align: center;
  border-radius: 20px;
  background: #F1EFE1; }
  @media only screen and (max-width: 375px) {
    .about-seven-images {
      padding: 25px; } }

.about-seven-left-images {
  position: relative; }
  .about-seven-left-images > img {
    max-width: 90%;
    border-radius: 20px; }
  .about-seven-left-images .about-over {
    max-width: 50%;
    position: absolute;
    right: -10%;
    top: 50%;
    border-radius: 11px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1); }
    @media only screen and (max-width: 1399px) {
      .about-seven-left-images .about-over {
        right: 0; } }

.about-content-seven {
  max-width: 500px; }
  @media only screen and (min-width: 1400px) {
    .about-content-seven {
      margin-left: auto; } }
  .about-content-seven .counter-item {
    max-width: 150px; }
    .about-content-seven .counter-item .count-text {
      color: var(--heading-color); }
      @media only screen and (max-width: 375px) {
        .about-content-seven .counter-item .count-text {
          font-size: 38px; } }
    @media only screen and (max-width: 375px) {
      .about-content-seven .counter-item .text {
        font-size: 15px; } }

.about-seven-right-images {
  position: relative; }
  @media only screen and (max-width: 767px) {
    .about-seven-right-images > img {
      padding-left: 3%; } }
  .about-seven-right-images .about-over {
    max-width: 38%;
    position: absolute;
    left: -6%;
    bottom: -5%;
    border-radius: 11px;
    -webkit-box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1); }
    @media only screen and (max-width: 991px) {
      .about-seven-right-images .about-over {
        left: -2%; } }
    @media only screen and (max-width: 767px) {
      .about-seven-right-images .about-over {
        left: 0; } }

/* About Page */
.data-analytics-content {
  max-width: 555px; }

/* Services Page */
.about-images-service-page {
  position: relative; }
  .about-images-service-page .for-responsive {
    opacity: 0; }
  .about-images-service-page .last-part,
  .about-images-service-page .first-part {
    top: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    position: absolute;
    border-radius: 20px;
    width: calc(50% - 15px);
    height: 100%; }
    @media only screen and (max-width: 479px) {
      .about-images-service-page .last-part,
      .about-images-service-page .first-part {
        border-radius: 10px;
        width: calc(50% - 6px); } }
    .about-images-service-page .last-part img,
    .about-images-service-page .first-part img {
      max-width: none; }
  .about-images-service-page .first-part {
    left: 0; }
  .about-images-service-page .last-part {
    right: 0;
    text-align: right;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end; }
  .about-images-service-page .logo {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 145px;
    height: 145px;
    background: white;
    line-height: 145px;
    border-radius: 50%;
    text-align: center;
    -webkit-box-shadow: 0px 10px 60px var(--primary-color);
    box-shadow: 0px 10px 60px var(--primary-color); }
    @media only screen and (max-width: 575px) {
      .about-images-service-page .logo {
        width: 100px;
        height: 100px;
        line-height: 100px; } }
    .about-images-service-page .logo img {
      max-width: 55%; }

/* Service Details */
.service-mamagement-content .feature-icon-box.style-two {
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0; }
  .service-mamagement-content .feature-icon-box.style-two .icon {
    background: var(--primary-color); }

.marketing-solutions-content .counter-item .count-text {
  color: var(--heading-color); }
  @media only screen and (max-width: 375px) {
    .marketing-solutions-content .counter-item .count-text {
      font-size: 40px; } }

.marketing-solutions-content .counter-item .text {
  font-size: 18px;
  max-width: 160px; }
  @media only screen and (max-width: 375px) {
    .marketing-solutions-content .counter-item .text {
      font-size: 15px; } }

/* Team Page */
.innovation-dedication-content {
  max-width: 520px; }

.innovation-dedication-images {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .innovation-dedication-images .image-one {
    width: 50%;
    margin-right: 5%; }
    .innovation-dedication-images .image-one img {
      border-radius: 12px; }
  .innovation-dedication-images .image-two {
    width: 45%;
    margin-top: 35px; }
    .innovation-dedication-images .image-two img {
      border-radius: 50%; }
  .innovation-dedication-images .image-three {
    width: 52%;
    position: absolute;
    left: 30%;
    bottom: 35px; }
    .innovation-dedication-images .image-three img {
      border-radius: 15px;
      -webkit-box-shadow: 0px 10px 60px rgba(0, 85, 255, 0.15);
      box-shadow: 0px 10px 60px rgba(0, 85, 255, 0.15); }
      @media only screen and (max-width: 375px) {
        .innovation-dedication-images .image-three img {
          border-radius: 10px; } }

/*******************************************************/
/******************* ## Contact Area ********************/
/*******************************************************/
.our-location iframe {
  height: 780px;
  margin-bottom: -10px; }
  @media only screen and (max-width: 1399px) {
    .our-location iframe {
      height: 600px; } }
  @media only screen and (max-width: 991px) {
    .our-location iframe {
      height: 500px; } }
  @media only screen and (max-width: 575px) {
    .our-location iframe {
      height: 400px; } }

.contact-info-item.style-two {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 30px;
  border-bottom: 1px solid var(--border-color); }
  .contact-info-item.style-two:not(:last-child) {
    margin-bottom: 30px; }
  .contact-info-item.style-two .icon {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    width: 70px; }
    @media only screen and (max-width: 375px) {
      .contact-info-item.style-two .icon {
        width: 55px; } }
    .contact-info-item.style-two .icon i {
      font-size: 40px;
      padding-top: 10px;
      color: var(--primary-color); }
  .contact-info-item.style-two .content {
    max-width: 260px; }
    .contact-info-item.style-two .content .text {
      font-size: 20px;
      display: block; }
      @media only screen and (max-width: 375px) {
        .contact-info-item.style-two .content .text {
          font-size: 17px; } }
      .contact-info-item.style-two .content .text a {
        color: var(--heading-color); }

.contact-form {
  padding: 45px 50px 50px;
  border: 1px solid var(--border-color);
  position: relative;
  z-index: 1; }
  @media only screen and (max-width: 479px) {
    .contact-form {
      padding-left: 35px;
      padding-right: 35px; } }
  @media only screen and (max-width: 375px) {
    .contact-form {
      padding-left: 20px;
      padding-right: 20px; } }
  .contact-form .shape-one {
    position: absolute;
    right: 60px;
    top: 39px;
    max-width: 7%;
    z-index: -1; }
  .contact-form .shape-two {
    position: absolute;
    left: 53%;
    bottom: 50px;
    max-width: 6%;
    z-index: -1; }
  .contact-form .form-group {
    margin-bottom: 30px; }
    .contact-form .form-group label {
      font-size: 18px;
      margin-bottom: 10px; }
    .contact-form .form-group .nice-select {
      background: white;
      padding: 9px 25px; }
      .contact-form .form-group .nice-select:after {
        top: 28px;
        right: 23px; }
  .contact-form .form-control {
    font-weight: 400;
    border-radius: 5px;
    background: white;
    padding: 18px 25px;
    border: 1px solid rgba(15, 17, 15, 0.07); }

/*******************************************************/
/******************* ## Counter Area ********************/
/*******************************************************/
.counter-timeline-area {
  z-index: 1;
  position: relative; }
  .counter-timeline-area:before {
    position: absolute;
    left: 0;
    bottom: 0;
    content: '';
    width: 100%;
    height: 35%;
    background: var(--navyblue-color); }

.counter-timeline-wrap {
  border-radius: 15px;
  padding: 100px 20px 70px;
  background: var(--primary-color); }

.counter-two-wrap {
  padding: 55px 30px 25px;
  background-color: var(--lighter-color); }

.counter-wrap-for-bg {
  padding: 30px 40px 15px; }
  @media only screen and (max-width: 375px) {
    .counter-wrap-for-bg {
      padding-left: 15px;
      padding-right: 15px; } }

.counter-content-four {
  max-width: 550px; }

/*******************************************************/
/******************** ## Shop Area *********************/
/*******************************************************/
/* Shop Shorter */
.shop-shorter {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }
  .shop-shorter .sort-text {
    font-weight: 500;
    margin-right: 25px; }
    @media only screen and (min-width: 376px) {
      .shop-shorter .sort-text {
        font-size: 20px; } }

.shop-wrapper {
  max-width: 900px; }

.product-item {
  margin-bottom: 40px; }
  .product-item .image {
    position: relative;
    margin-bottom: 20px;
    padding-bottom: 97%;
    background: var(--lighter-color);
    border-radius: 10px; }
    .product-item .image img {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      max-width: 70%;
      max-height: 75%; }
  .product-item .content .ratting {
    margin-left: -3px; }
    .product-item .content .ratting i {
      font-size: 10px;
      color: #f90;
      margin-left: 3px;
      margin-right: 3px; }
  .product-item .content h5 {
    font-size: 20px;
    margin-bottom: 0; }
  .product-item .content .price {
    font-weight: 600;
    font-family: var(--heading-font); }

/* Product Details */
.preview-images {
  margin-bottom: 25px;
  border-radius: 12px;
  background: var(--lighter-color); }
  .preview-images .preview-item {
    position: relative;
    padding-bottom: 75%; }
    .preview-images .preview-item img {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      max-width: 60%;
      max-height: 70%; }

.thumb-images {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }
  .thumb-images .thumb-item {
    position: relative;
    padding-bottom: 25%;
    border-radius: 7px;
    width: calc(33% - 20px);
    background: var(--lighter-color); }
    .thumb-images .thumb-item img {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      max-width: 60%;
      max-height: 70%; }

@media only screen and (min-width: 1200px) {
  .product-details-content {
    margin-top: 40px; } }

.product-details-content .ratting i {
  color: var(--secondary-color); }

.product-details-content .section-title h2 {
  margin-bottom: 10px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .product-details-content .section-title h2 {
      font-size: 42px; } }

.product-details-content .price {
  display: block;
  font-size: 22px;
  font-weight: 600; }
  @media only screen and (max-width: 375px) {
    .product-details-content .price {
      font-size: 18px; } }

.product-details-content .add-to-cart {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .product-details-content .add-to-cart .theme-btn {
    margin-top: 15px;
    padding-top: 15px;
    padding-bottom: 15px; }
  .product-details-content .add-to-cart input {
    font-size: 22px;
    font-weight: 600;
    max-width: 130px;
    margin-top: 15px;
    margin-right: 22px;
    padding: 12px 30px;
    background: transparent;
    border: 2px solid var(--border-color); }

.product-details-content .category-tags li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .product-details-content .category-tags li h5 {
    margin-top: 10px;
    margin-bottom: 0;
    margin-right: 20px; }
  .product-details-content .category-tags li:first-child a {
    margin-top: 10px;
    font-weight: 600;
    font-size: 18px;
    display: inline-block; }
    .product-details-content .category-tags li:first-child a:not(:last-child) {
      margin-right: 30px; }
    .product-details-content .category-tags li:first-child a:hover {
      color: var(--heading-color); }

.shop-details-tab-area h4, .shop-details-tab-area h5 {
  margin-bottom: 20px; }

.product-information-tab li {
  margin-bottom: 15px; }
  .product-information-tab li:not(:last-child) {
    margin-right: 55px; }
    @media only screen and (max-width: 991px) {
      .product-information-tab li:not(:last-child) {
        margin-right: 15px; } }
  .product-information-tab li a {
    font-size: 14px;
    font-weight: 500;
    padding: 11px 45px;
    display: inline-block;
    color: var(--heading-color);
    text-transform: uppercase;
    border: 1px solid var(--border-color); }
    @media only screen and (max-width: 991px) {
      .product-information-tab li a {
        padding: 6px 30px; } }
    .product-information-tab li a.active {
      color: black;
      background: var(--primary-color); }

.review-item {
  max-width: 650px; }
  @media only screen and (min-width: 480px) {
    .review-item {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; } }
  .review-item.child-review {
    margin-left: 80px; }
    @media only screen and (max-width: 575px) {
      .review-item.child-review {
        margin-left: 35px; } }
  .review-item .thumb {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    max-width: 100px;
    margin-right: 25px;
    margin-bottom: 15px; }
    .review-item .thumb img {
      border-radius: 50%; }
  .review-item .content .name-ratting {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .review-item .content .name-ratting h6 {
      margin-right: 25px;
      margin-bottom: 5px; }
    .review-item .content .name-ratting .ratting {
      margin-right: 25px;
      margin-bottom: 5px; }
      .review-item .content .name-ratting .ratting i {
        font-size: 14px; }
    .review-item .content .name-ratting .date {
      margin-bottom: 7px; }
  .review-item:not(:last-child) {
    margin-bottom: 30px; }

.product-details .tab-content .tab-pane h5 {
  margin-bottom: 30px; }

.product-details .tab-content .tab-pane .comments .comment-body:last-child {
  margin-bottom: 0; }

.product-details .tab-content .ratting {
  margin-left: -3px; }
  .product-details .tab-content .ratting i {
    font-size: 12px;
    color: #f90;
    margin-left: 3px;
    margin-right: 3px; }

.product-details .tab-content .list li:before {
  content: "\f00c";
  font-weight: 900;
  font-family: "Font Awesome 5 Pro";
  margin-top: 0;
  background: transparent;
  font-size: 20px;
  color: var(--primary-color);
  margin-right: 30px; }

.product-info-tab.advanced-tab {
  margin-left: 0;
  margin-right: 0;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start; }
  .product-info-tab.advanced-tab li {
    margin-left: 0; }
    .product-info-tab.advanced-tab li button i {
      -webkit-transition: .3s;
      -o-transition: .3s;
      transition: .3s; }
    .product-info-tab.advanced-tab li button.active i {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg); }

/*******************************************************/
/******************** ## CTA Area *********************/
/*******************************************************/
.cta-area {
  z-index: 1;
  position: relative; }
  .cta-area:before {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    content: '';
    width: 100%;
    height: 100%;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.9)), to(rgba(0, 0, 0, 0.3)));
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.3) 100%);
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.3) 100%);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.3) 100%); }

/* Footer top CTA */
.footer-top-cta-area {
  position: relative;
  z-index: 1; }
  .footer-top-cta-area:after {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    content: '';
    width: 100%;
    height: 100px;
    background: var(--heading-color); }

.footer-top-cta-wrap {
  padding: 80px 150px;
  border-radius: 20px;
  background-size: cover;
  background-position: center; }
  @media only screen and (max-width: 1399px) {
    .footer-top-cta-wrap {
      padding-left: 50px;
      padding-right: 50px; } }
  @media only screen and (max-width: 375px) {
    .footer-top-cta-wrap {
      padding-left: 25px;
      padding-right: 25px; } }

.footer-top-cta-content .subtitle-one.style-three {
  border: 2px solid white; }

.footer-top-cta-content .theme-btn {
  background: white;
  color: var(--heading-color); }

/* Home Seven */
.cta-left-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 12px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 50px 50px 25px;
  background-repeat: no-repeat;
  background-position: left bottom; }
  @media only screen and (max-width: 1199px) {
    .cta-left-content {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; } }
  @media only screen and (max-width: 375px) {
    .cta-left-content {
      padding-left: 25px;
      padding-right: 25px; } }
  .cta-left-content .subtitle {
    font-weight: 600;
    color: var(--heading-color); }
  .cta-left-content .theme-btn.style-four {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    border: 2px solid var(--heading-color);
    background-image: -webkit-gradient(linear, right top, left top, color-stop(50%, transparent), color-stop(50%, var(--heading-color)));
    background-image: -webkit-linear-gradient(right, transparent 50%, var(--heading-color) 50%);
    background-image: -o-linear-gradient(right, transparent 50%, var(--heading-color) 50%);
    background-image: linear-gradient(to left, transparent 50%, var(--heading-color) 50%); }

.cta-right-image {
  height: 100%;
  min-height: 200px;
  border-radius: 12px;
  background-size: cover;
  background-position: center; }

/*******************************************************/
/****************** ## Videos Area ********************/
/*******************************************************/
/* Video Play */
.video-play {
  position: relative;
  text-align: center;
  width: 80px;
  height: 80px;
  background: white;
  line-height: 80px;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  color: var(--primary-color);
  -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  -webkit-animation: pulse 1.5s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulse 1.5s infinite cubic-bezier(0.66, 0, 0, 1); }
  .video-play:before {
    content: '';
    width: 60%;
    height: 60%;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 50%;
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    -webkit-animation: pulse 1.5s infinite cubic-bezier(0.66, 0, 0, 1);
    animation: pulse 1.5s infinite cubic-bezier(0.66, 0, 0, 1); }

/*******************************************************/
/****************** ## Services Area *******************/
/*******************************************************/
.services-wrap {
  margin-bottom: -35px;
  -webkit-transform: translateY(-80px);
  -ms-transform: translateY(-80px);
  transform: translateY(-80px); }

/*******************************************************/
/***************** ## Sidebar Widgets *****************/
/*******************************************************/
.widget:not(:last-child) {
  margin-bottom: 40px; }

.widget-title {
  margin-bottom: 18px; }

.widget-search form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 16px 25px;
  border-radius: 7px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: var(--lighter-color);
  border: 1px solid var(--border-color); }
  .widget-search form input {
    padding: 0;
    border: none;
    font-size: 16px;
    background: transparent; }
  .widget-search form button {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    background: transparent; }

.widget-category ul li:not(:last-child) {
  margin-bottom: 15px; }

.widget-category ul li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 500;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--heading-color);
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.next-prev-blog .item,
.widget-recent-news ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .next-prev-blog .item:not(:last-child),
  .widget-recent-news ul li:not(:last-child) {
    margin-bottom: 30px; }
  .next-prev-blog .item .image,
  .widget-recent-news ul li .image {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    max-width: 100px;
    margin-right: 30px; }
    @media only screen and (max-width: 375px) {
      .next-prev-blog .item .image,
      .widget-recent-news ul li .image {
        margin-right: 15px; } }
    .next-prev-blog .item .image img,
    .widget-recent-news ul li .image img {
      border-radius: 10px; }
  .next-prev-blog .item .date,
  .widget-recent-news ul li .date {
    display: block;
    margin-bottom: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid var(--border-color); }
  .next-prev-blog .item h6,
  .widget-recent-news ul li h6 {
    margin-bottom: 0; }
    @media only screen and (min-width: 1200px) {
      .next-prev-blog .item h6,
      .widget-recent-news ul li h6 {
        font-size: 18px; } }

.tag-coulds {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -5px 0; }
  .tag-coulds a {
    font-weight: 600;
    padding: 4px 18px;
    margin: 10px 5px 0;
    border-radius: 10px;
    text-transform: capitalize;
    border: 1px solid var(--border-color); }
    @media only screen and (max-width: 375px) {
      .tag-coulds a {
        font-size: 14px; } }
    .tag-coulds a:hover {
      color: white;
      background: var(--primary-color);
      border-color: var(--primary-color); }

.widget-cta {
  z-index: 1;
  overflow: hidden;
  position: relative;
  border-radius: 12px;
  padding: 40px 40px 130px;
  background: var(--primary-color); }
  @media only screen and (max-width: 375px) {
    .widget-cta {
      padding-left: 25px;
      padding-right: 25px; } }
  .widget-cta h3 {
    color: white;
    max-width: 195px;
    margin-bottom: 20px;
    letter-spacing: -0.9px; }
  .widget-cta h5 {
    color: white;
    max-width: 185px;
    margin-bottom: 20px; }
  .widget-cta .bg {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%; }
  .widget-cta .man {
    z-index: -1;
    position: absolute;
    right: 4%;
    bottom: 0;
    max-width: 90%; }
  .widget-cta .theme-btn {
    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(50%, #0095FF), color-stop(50%, var(--heading-color)));
    background-image: -webkit-linear-gradient(bottom, #0095FF 50%, var(--heading-color) 50%);
    background-image: -o-linear-gradient(bottom, #0095FF 50%, var(--heading-color) 50%);
    background-image: linear-gradient(to top, #0095FF 50%, var(--heading-color) 50%); }

.widget-products li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .widget-products li:not(:last-child) {
    margin-bottom: 10px; }
  .widget-products li .image {
    max-width: 80px;
    margin-right: 30px; }
    .widget-products li .image img {
      border-radius: 7px; }
  .widget-products li .content {
    margin-bottom: 5px; }
    .widget-products li .content .ratting i {
      color: #FF9900;
      font-size: 10px;
      margin: 0 5px 0 0; }
    .widget-products li .content h6 {
      font-size: 18px;
      margin-bottom: 0; }

.price-filter-wrap .price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 15px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--heading-color); }
  .price-filter-wrap .price span {
    margin-right: 5px; }
  .price-filter-wrap .price input {
    padding: 0;
    border: none;
    font-size: 16px;
    font-weight: 500;
    background: transparent; }

.ui-widget.ui-widget-content {
  height: 5px;
  border: none;
  cursor: pointer;
  background: rgba(0, 85, 255, 0.2); }

.ui-widget-header {
  background: var(--primary-color); }

.ui-slider .ui-slider-handle {
  width: 15px;
  height: 15px;
  top: -5px;
  border: none;
  cursor: e-resize;
  border-radius: 50%;
  background: var(--primary-color); }

/* Shop Sidebar */
.shop-sidebar .widget-cta {
  padding-bottom: 90px; }

/*******************************************************/
/******************* ## Footer Area  *******************/
/*******************************************************/
.footer-newsletter {
  padding: 40px 60px;
  border-radius: 15px;
  margin-bottom: -15px;
  background: var(--primary-color);
  -webkit-transform: translateY(-100px);
  -ms-transform: translateY(-100px);
  transform: translateY(-100px); }
  @media only screen and (max-width: 575px) {
    .footer-newsletter {
      padding-left: 35px;
      padding-right: 35px; } }
  @media only screen and (max-width: 375px) {
    .footer-newsletter {
      padding-left: 25px;
      padding-right: 25px; } }

.footer-widget {
  margin-bottom: 50px; }

.footer-title {
  color: white;
  font-size: 18px;
  margin-bottom: 22px; }

.widget-links ul li {
  font-weight: 500;
  line-height: 1.5; }
  .widget-links ul li a {
    color: rgba(255, 255, 255, 0.45); }
    .widget-links ul li a:hover {
      color: white; }
  .widget-links ul li:not(:last-child) {
    margin-bottom: 16px; }

.widget-contact ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .widget-contact ul li i {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    width: 20px;
    color: white;
    margin-top: 10px;
    margin-right: 10px; }
  .widget-contact ul li a {
    color: rgba(255, 255, 255, 0.45); }
    .widget-contact ul li a:hover {
      color: white; }
  .widget-contact ul li:not(:last-child) {
    margin-bottom: 14px; }

.widget-gallery ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -10px -10px 0; }
  .widget-gallery ul li {
    width: 80px;
    overflow: hidden;
    position: relative;
    border-radius: 10px;
    margin: 0 10px 10px 0;
    max-width: calc(33% - 10px); }
    .widget-gallery ul li:after {
      position: absolute;
      left: 0;
      top: 0;
      content: '';
      width: 100%;
      height: 100%;
      -webkit-transition: 0.4s;
      -o-transition: 0.4s;
      transition: 0.4s;
      -webkit-transform: scale3d(1, 0, 1);
      transform: scale3d(1, 0, 1);
      background: -webkit-linear-gradient(356deg, rgba(176, 101, 231, 0.75) 13.66%, rgba(49, 87, 239, 0.75) 81.89%);
      background: -o-linear-gradient(356deg, rgba(176, 101, 231, 0.75) 13.66%, rgba(49, 87, 239, 0.75) 81.89%);
      background: linear-gradient(94deg, rgba(176, 101, 231, 0.75) 13.66%, rgba(49, 87, 239, 0.75) 81.89%); }
    .widget-gallery ul li i {
      z-index: 1;
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transition: 0.4s;
      -o-transition: 0.4s;
      transition: 0.4s;
      font-size: 12px;
      width: 30px;
      height: 30px;
      background: white;
      line-height: 30px;
      border-radius: 50%;
      text-align: center;
      color: var(--secondary-color);
      -webkit-transform: translate(-50%, -50%) scale3d(1, 0, 1) rotate(-40deg);
      transform: translate(-50%, -50%) scale3d(1, 0, 1) rotate(-40deg); }
    .widget-gallery ul li:hover:after {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1); }
    .widget-gallery ul li:hover i {
      -webkit-transform: translate(-50%, -50%) scale3d(1, 1, 1) rotate(-40deg);
      transform: translate(-50%, -50%) scale3d(1, 1, 1) rotate(-40deg); }

.footer-bottom {
  position: relative;
  border-top: 1px solid rgba(255, 255, 255, 0.1); }
  .footer-bottom .copyright-text a {
    color: white; }
  .footer-bottom .footer-bottom-menu ul {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-left: -15px;
    margin-right: -15px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
    .footer-bottom .footer-bottom-menu ul li {
      margin-left: 15px;
      margin-right: 15px;
      text-transform: capitalize; }
      .footer-bottom .footer-bottom-menu ul li a {
        color: rgba(255, 255, 255, 0.45); }
        .footer-bottom .footer-bottom-menu ul li a:hover {
          color: white; }
  .footer-bottom .scroll-top {
    width: 55px;
    height: 55px;
    font-size: 20px;
    line-height: 55px;
    border-radius: 14px;
    background: var(--secondary-color);
    position: absolute;
    left: calc(50% - 27px);
    top: -27px; }
    @media only screen and (max-width: 991px) {
      .footer-bottom .scroll-top {
        width: 45px;
        height: 45px;
        font-size: 16px;
        line-height: 45px;
        left: calc(50% - 22px); } }

/* Footer One */
.main-footer.footer-one {
  background: #151F39;
  color: rgba(255, 255, 255, 0.45); }
  @media only screen and (min-width: 1200px) {
    .main-footer.footer-one .widget-links {
      max-width: -webkit-max-content;
      max-width: -moz-max-content;
      max-width: max-content;
      margin-left: auto;
      margin-right: auto; } }

/* Footer Two */
.main-footer.footer-two {
  z-index: 1;
  position: relative;
  background-color: var(--heading-color);
  color: rgba(255, 255, 255, 0.45); }
  @media only screen and (min-width: 1200px) {
    .main-footer.footer-two .widget-links {
      margin-left: auto;
      max-width: -webkit-max-content;
      max-width: -moz-max-content;
      max-width: max-content; } }
  .main-footer.footer-two .footer-bottom {
    color: white;
    border-top: none; }
    .main-footer.footer-two .footer-bottom .copyright-text a {
      color: var(--secondary-color); }
    .main-footer.footer-two .footer-bottom .footer-bottom-menu a {
      color: white; }
      .main-footer.footer-two .footer-bottom .footer-bottom-menu a:hover {
        color: var(--secondary-color); }
  .main-footer.footer-two:after {
    z-index: -1;
    content: '';
    width: 600px;
    height: 600px;
    position: absolute;
    right: -300px;
    bottom: -300px;
    -webkit-filter: blur(300px);
    filter: blur(300px);
    background: var(--primary-color);
    -webkit-animation: 10s zoomInOut infinite;
    animation: 10s zoomInOut infinite; }

.footer-inner {
  border-radius: 20px;
  background: #00131F;
  padding: 80px 80px 30px; }
  @media only screen and (max-width: 479px) {
    .footer-inner {
      padding-left: 25px;
      padding-right: 25px; } }

.footer-top-newsletter {
  z-index: 1;
  position: relative; }
  .footer-top-newsletter:after, .footer-top-newsletter:before {
    content: '';
    z-index: -1;
    bottom: 20px;
    width: 60px;
    height: 85px;
    position: absolute;
    background-size: 100% 100%; }
  .footer-top-newsletter:before {
    left: 12%;
    background-image: url(../images/shapes/leaf1.png); }
  .footer-top-newsletter:after {
    right: 12%;
    background-image: url(../images/shapes/leaf2.png); }

/* Footer Three */
@media only screen and (min-width: 1200px) {
  .main-footer.footer-three .widget-links {
    margin-left: auto;
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content; } }

.main-footer.footer-three .copyright-text {
  color: rgba(255, 255, 255, 0.66); }

@media only screen and (min-width: 992px) {
  .main-footer.footer-three .scroll-top {
    left: calc(62% - 27px); } }

@media only screen and (min-width: 992px) {
  .for-middle-border {
    position: relative; }
    .for-middle-border:after {
      content: '';
      position: absolute;
      left: 62%;
      top: 0;
      width: 1px;
      height: 100%;
      background: rgba(255, 255, 255, 0.1); } }

.footer-btns .theme-btn {
  margin-top: 15px; }
  .footer-btns .theme-btn:not(:last-child) {
    margin-right: 20px; }
  .footer-btns .theme-btn i {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0); }

.footer-cta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .footer-cta .logo {
    margin-right: auto;
    margin-bottom: 25px; }
  .footer-cta h3 {
    margin-bottom: 25px; }
    @media only screen and (min-width: 768px) {
      .footer-cta h3 {
        margin-right: 8%; } }
    @media only screen and (max-width: 575px) {
      .footer-cta h3 {
        font-size: 25px; } }
  .footer-cta .theme-btn {
    margin-bottom: 25px; }

.footer-cta-two {
  overflow: hidden;
  position: relative;
  padding: 60px 80px;
  border-radius: 55px; }
  @media only screen and (max-width: 1199px) {
    .footer-cta-two {
      padding-left: 40px;
      padding-right: 40px; } }
  @media only screen and (max-width: 479px) {
    .footer-cta-two {
      padding-left: 20px;
      padding-right: 20px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .footer-cta-two .section-title h2 {
      font-size: 40px; } }
  .footer-cta-two p {
    margin-bottom: 0; }
  .footer-cta-two:after {
    position: absolute;
    right: 0;
    top: 0;
    content: '';
    z-index: -1;
    width: 33%;
    height: 100%;
    background: url(../images/shapes/cta-btn-line-bg.png); }

.footer-cta-shape {
  position: absolute;
  right: 36%;
  top: 30%;
  z-index: -1;
  max-width: 20%; }
  @media only screen and (max-width: 1199px) {
    .footer-cta-shape {
      opacity: 0.15; } }

/* Footer Four */
.main-footer.footer-four .widget-newsletter h3 {
  color: white; }
  @media only screen and (max-width: 767px) {
    .main-footer.footer-four .widget-newsletter h3 {
      font-size: 25px; } }

.main-footer.footer-four .widget-newsletter .icon-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -25px; }
  .main-footer.footer-four .widget-newsletter .icon-list li {
    margin: 10px 25px 0 0;
    color: rgba(255, 255, 255, 0.65); }
    .main-footer.footer-four .widget-newsletter .icon-list li i {
      margin-top: 0; }

@media only screen and (min-width: 1200px) {
  .main-footer.footer-four .widget-links {
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content;
    margin-left: auto;
    margin-right: auto; } }

.main-footer.footer-four .copyright-text a {
  color: var(--primary-color); }

/* Footer Five */
.main-footer.footer-five .footer-title {
  color: var(--heading-color); }

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .main-footer.footer-five .footer-widget .section-title h2 {
    font-size: 38px; } }

.main-footer.footer-five .footer-widget ul li:not(:last-child) {
  margin-bottom: 20px; }

.main-footer.footer-five .widget-links ul li a {
  color: var(--base-color); }

.main-footer.footer-five .widget-contact-info {
  max-width: 200px; }
  @media only screen and (min-width: 1200px) {
    .main-footer.footer-five .widget-contact-info {
      margin-left: auto;
      margin-right: auto; } }
  .main-footer.footer-five .widget-contact-info ul li a {
    font-weight: 600; }

.main-footer.footer-five .newsletter-form {
  background: #F6F6F6;
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 55px; }
  .main-footer.footer-five .newsletter-form button {
    background: var(--primary-color); }

.main-footer.footer-five .radio-part {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  margin-left: 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .main-footer.footer-five .radio-part input {
    width: 24px;
    height: 24px;
    opacity: 0.25;
    margin-right: 10px; }
    .main-footer.footer-five .radio-part input:checked {
      opacity: 1; }
  .main-footer.footer-five .radio-part label {
    font-weight: 400;
    margin-bottom: 0; }

.main-footer.footer-five .footer-bottom .copyright-text a {
  color: var(--primary-color);
  font-weight: bold; }
