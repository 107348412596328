.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background-color: #f5f5f5;
  }
  
  .pagination li {
    display: inline-block;
    margin: 0 5px;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 5px;
  }
  
  .pagination li.active {
    background-color: #007bff;
    color: #fff;
  }

  